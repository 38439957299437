import { defineStore } from "pinia";
import axios from "axios";
import { ref } from "vue";

import type { RecurringInstructionsPaginationOptionsInterface } from "@/_helpers/interfaces/orders/recurring-instructions-pagination-options.interface";

export const useRecurringInstructionStore = defineStore(
  "recurring_instructions",
  () => {
    const RecurringFilterOptions: any = ref({
      project_id: "",
      customer: "",
      supplier_id: "",
      created_by: "",
      current_balance_max: "",
      current_balance_min: "",
      document_no_internal: "",
      end_after: "",
      frequency: "",
      gross_euro_max: "",
      gross_euro_min: "",
      name: "",
      ordering: "",
      page: "",
      page_size: "",
      recurring_number: "",
      released_by: "",
      search: "",
      created_on_after: "",
      created_on_before: "",
      start_date_after: "",
      start_date_before: "",
      status: "",
      supplier: "",
      clarification_requested_only: false,
    });

    const convertToFormData = (instruction: any): FormData => {
      //   convert instruction from json to formData
      const formData = new FormData();
      for (const key in instruction) {
        // emit null values
        if (instruction[key] === null || instruction[key] === undefined) {
          formData.append(key, "");
        } else if (key === "files") {
          instruction[key].forEach((file: any) => {
            if (file && file?.name) {
              formData.append("file", file);
            }
          });
        } else {
          formData.append(key, instruction[key]);
        }
      }
      return formData;
    };
    // create a recurring instruction
    const createRecurringInstruction = async (
      instruction: any
    ): Promise<any> => {
      try {
        const { data } = await axios.post(
          `/orders/recurring_instructions/`,
          convertToFormData(instruction)
        );
        return data;
      } catch (error: any) {
        console.error(error);
        throw error;
      }
    };

    const getRecurringInstructions = async (
      options: RecurringInstructionsPaginationOptionsInterface
    ): Promise<any> => {
      try {
        const { data } = await axios.get("/orders/recurring_instructions/", {
          params: options,
        });
        return data;
      } catch (error: any) {
        console.error(error);
        throw error;
      }
    };

    const getRecurringInstructionById = async (id: any): Promise<any> => {
      try {
        const { data } = await axios.get(
          `/orders/recurring_instructions/${id}/`,
          {}
        );
        return data;
      } catch (error: any) {
        console.error(error);
        throw error;
      }
    };

    const getRecurringInstructionChildren = async (id: any): Promise<any> => {
      try {
        const { data } = await axios.get(
          `/orders/recurring_instructions/${id}/children/`,
          {}
        );
        return data;
      } catch (error: any) {
        console.error(error);
        throw error;
      }
    };

    const updateRecurringInstruction = async (
      id: any,
      ri: any
    ): Promise<any> => {
      try {
        const { data } = await axios.put(
          `/orders/recurring_instructions/${id}/`,
          convertToFormData(ri)
        );
        return data;
      } catch (error: any) {
        console.error(error);
        throw error;
      }
    };
    const deleteRecurringInstruction = async (id: any): Promise<any> => {
      try {
        const { data } = await axios.delete(
          `/orders/recurring_instructions/${id}/`
        );
        return data;
      } catch (error: any) {
        console.error(error);
        throw error;
      }
    };
    const approveRecurringInstruction = async (id: any): Promise<any> => {
      try {
        const { data } = await axios.post(
          `/orders/recurring_instructions/${id}/approve/`,
          {}
        );
        return data;
      } catch (error: any) {
        console.error(error);
        throw error;
      }
    };
    const rejectRecurringInstruction = async (id: any): Promise<any> => {
      try {
        const { data } = await axios.post(
          `/orders/recurring_instructions/${id}/reject/`,
          {}
        );
        return data;
      } catch (error: any) {
        console.error(error);
        throw error;
      }
    };
    const requestRecurringInstructionClarification = async (
      id: any,
      comment: string
    ): Promise<any> => {
      try {
        const { data } = await axios.post(
          `/orders/recurring_instructions/${id}/request_clarification/`,
          {
            comment: comment,
          }
        );
        return data;
      } catch (error: any) {
        console.error(error);
        throw error;
      }
    };
    const respondToRecurringInstructionClarification = async (
      id: any
    ): Promise<any> => {
      try {
        const { data } = await axios.post(
          `/orders/recurring_instructions/${id}/respond_to_clarification_request/`,
          {}
        );
        return data;
      } catch (error: any) {
        console.error(error);
        throw error;
      }
    };
    const getRecurringInstructionComments = async (id: any): Promise<any> => {
      try {
        const { data } = await axios.get(
          `/orders/recurring_instructions/${id}/comments/`,
          {}
        );
        return data;
      } catch (error: any) {
        console.error(error);
        throw error;
      }
    };
    const getRecurringInstructionSelfNotes = async (id: any): Promise<any> => {
      try {
        const { data } = await axios.get(
          `/orders/recurring_instructions/${id}/self_notes/`,
          {}
        );
        return data;
      } catch (error: any) {
        console.error(error);
        throw error;
      }
    };
    const createRecurringInstructionComment = async (
      id: any,
      comment: string
    ): Promise<any> => {
      try {
        const { data } = await axios.post(
          `/orders/recurring_instructions/${id}/add_comment/`,
          {
            comment: comment,
          }
        );
        return data;
      } catch (error: any) {
        console.error(error);
        throw error;
      }
    };
    const createRecurringInstructionSelfNote = async (
      id: any,
      comment: string
    ): Promise<any> => {
      try {
        const { data } = await axios.post(
          `/orders/recurring_instructions/${id}/add_self_note/`,
          {
            comment: comment,
          }
        );
        return data;
      } catch (error: any) {
        console.error(error);
        throw error;
      }
    };
    const editRecurringInstructionSelfNote = async (
      id: any,
      note_id: any,
      comment: string
    ): Promise<any> => {
      try {
        const { data } = await axios.patch(
          `/orders/recurring_instructions/${id}/edit_self_note/`,
          {
            note_id: note_id,
            text: comment,
          }
        );
        return data;
      } catch (error: any) {
        console.error(error);
        throw error;
      }
    };
    const deleteRecurringInstructionSelfNote = async (
      id: any,
      note_id: any
    ): Promise<any> => {
      try {
        const { data } = await axios.patch(
          `/orders/recurring_instructions/${id}/delete_self_note/`,
          {
            note_id: note_id,
          }
        );
        return data;
      } catch (error: any) {
        console.error(error);
        throw error;
      }
    };
    const bulkRecurringInstructionAction = async (
      action:
        | "approve"
        | "reject"
        | "request_clarification"
        | "respond_to_clarification_request"
        | "delete"
        | "submit",
      ids: any,
      comment?: string
    ): Promise<any> => {
      try {
        await axios.post(`/orders/recurring_instructions/bulk_${action}/`, {
          ids: ids,
          comment: comment,
        });
      } catch (error: any) {
        console.log(error);
        throw error;
      }
    };
    const exportRecurringInstructions = async (
      ids: any,
      exportFormat: string,
      fields: Array<string>
    ): Promise<any> => {
      try {
        const response = await axios.post(
          `/orders/recurring_instructions/bulk_export/`,
          {
            order_ids: ids,
            export_format: exportFormat,
            fields: fields,
          }
        );
        return response.data;
      } catch (error: any) {
        console.log(error);
        throw error;
      }
    };

    // non api related
    const resetOptions = () => {
      RecurringFilterOptions.value = {
        project_id: "",
        customer: "",
        supplier_id: "",
        created_by: "",
        current_balance_max: "",
        current_balance_min: "",
        document_no_internal: "",
        end_after: "",
        frequency: "",
        gross_euro_max: "",
        gross_euro_min: "",
        name: "",
        ordering: "",
        page: "",
        page_size: "",
        recurring_number: "",
        released_by: "",
        search: "",
        created_on_after: "",
        created_on_before: "",
        start_date_after: "",
        start_date_before: "",
        status: "",
        supplier: "",
        clarification_requested_only: false,
      };
    };
    const setOptions = (options: any) => {
      RecurringFilterOptions.value = options;
    };
    const filterCount = (): number => {
      let count = 0;
      for (const key in RecurringFilterOptions.value) {
        // if the key is a date range, count as 1
        if (key.includes("before")) {
          // the after will be counted
          continue;
        }
        if (
          RecurringFilterOptions.value[key] !== "" &&
          RecurringFilterOptions.value[key] !== false
        ) {
          count += 1;
        }
      }
      return count;
    };

    return {
      createRecurringInstruction,
      getRecurringInstructions,
      getRecurringInstructionById,
      updateRecurringInstruction,
      deleteRecurringInstruction,
      getRecurringInstructionChildren,
      approveRecurringInstruction,
      rejectRecurringInstruction,
      requestRecurringInstructionClarification,
      respondToRecurringInstructionClarification,
      getRecurringInstructionComments,
      getRecurringInstructionSelfNotes,
      createRecurringInstructionComment,
      createRecurringInstructionSelfNote,
      editRecurringInstructionSelfNote,
      deleteRecurringInstructionSelfNote,
      bulkRecurringInstructionAction,
      exportRecurringInstructions,
      RecurringFilterOptions,
      resetOptions,
      setOptions,
      filterCount,
    };
  }
);
