<template>
  <div>
    <div class="form-group row">
      <div class="col-sm-10 col-md-6 col-lg-6 my-1">
        <input
          type="text"
          class="form-control"
          id="order_number"
          :placeholder="$t('Order.OrderNo_75')"
          v-model="currentFilter.recurring_number"
        />
      </div>

      <div class="col-sm-10 col-md-6 col-lg-6 my-1">
        <input
          type="text"
          class="form-control"
          id="supplier"
          :placeholder="$t('Order.Supplier_31')"
          v-model="currentFilter.supplier"
        />
      </div>

      <div class="col-sm-10 col-md-6 col-lg-6 my-1">
        <input
          type="text"
          class="form-control"
          id="document_no_internal"
          :placeholder="$t('Order.DocumentNo_112')"
          v-model="currentFilter.document_no_internal"
        />
      </div>

      <div class="col-sm-10 col-md-6 col-lg-6 my-1">
        <input
          type="text"
          class="form-control"
          id="order_name"
          :placeholder="$t('Order.OrderName_13')"
          v-model="currentFilter.name"
        />
      </div>

      <div class="col-sm-10 col-md-6 col-lg-6 my-1">
        <LegacyMultiSelect
          v-model="currentFilter.frequency"
          name="projects"
          :object="false"
          :options="frequencyOptions"
          :resolve-on-load="true"
          :min-chars="0"
          :placeholder="$t('Order.Frequency_17')"
          :closeOnSelect="true"
          :clearOnBlur="false"
          :searchable="true"
          valueProp="value"
          label="name"
        />
      </div>

      <div class="col-sm-10 col-md-6 col-lg-6 my-1">
        <VueDatePicker
          :placeholder="$t('Order.StartDate_15')"
          :enable-time-picker="false"
          range
          :select-text="$t('Order.Apply_50')"
          auto-apply
          :preset-ranges="presetRanges"
          :format="formatDate"
          v-model="startDateRange"
        >
          <template #yearly="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #last_year="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #last_month="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #this_month="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #last_week="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #last_30_days="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
        </VueDatePicker>
      </div>

      <div class="col-sm-10 col-md-6 col-lg-6 my-1">
        <input
          type="number"
          class="form-control"
          id="ends_after"
          :placeholder="$t('Order.EndsAfter_22')"
          v-model="currentFilter.end_after"
        />
      </div>
      <div class="col-sm-10 col-md-6 col-lg-6 my-1">
        <input
          type="text"
          class="form-control"
          id="released_by"
          :placeholder="$t('Order.ReleasedBy_78')"
          v-model="currentFilter.released_by"
        />
      </div>
      <div class="col-sm-10 col-md-6 col-lg-6 my-1">
        <input
          type="text"
          class="form-control"
          id="created_by"
          :placeholder="$t('Order.CreatedBy_140')"
          v-model="currentFilter.created_by"
        />
      </div>

      <div class="col-sm-10 col-md-6 col-lg-6 my-1">
        <VueDatePicker
          :placeholder="$t('Order.CreatedOn_139')"
          :enable-time-picker="false"
          range
          :select-text="$t('Order.Apply_50')"
          auto-apply
          :preset-ranges="presetRanges"
          :format="formatDate"
          v-model="createdOnRange"
        >
          <template #yearly="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #last_year="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #last_month="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #this_month="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #last_week="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #last_30_days="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
        </VueDatePicker>
      </div>

      <div class="col-sm-10 col-md-6 col-lg-6 my-1">
        <div class="input-group mb-3">
          <input
            type="number"
            v-model.number="currentFilter.current_balance_min"
            class="form-control"
            :placeholder="$t('Order.CurrentBalanceMin_157')"
          />
          <span class="input-group-text">:</span>
          <input
            type="number"
            v-model.number="currentFilter.current_balance_max"
            class="form-control"
            :placeholder="$t('Order.CurrentBalanceMax_158')"
          />
        </div>
      </div>

      <div class="col-sm-10 col-md-6 col-lg-6 my-1">
        <div class="input-group mb-3">
          <input
            type="number"
            v-model.number="currentFilter.gross_euro_min"
            class="form-control"
            :placeholder="$t('Order.GrossEurMin_159')"
          />
          <span class="input-group-text">:</span>
          <input
            type="number"
            v-model.number="currentFilter.gross_euro_max"
            class="form-control"
            :placeholder="$t('Order.GrossEurMax_160')"
          />
        </div>
      </div>
      <div class="col-sm-10 col-md-6 col-lg-6 my-1">
        <MazCheckbox
          v-model="currentFilter.clarification_requested_only"
          size="mini"
          color="success"
        >
          {{ $t("Order.ShowClarificationRequestedOnly_144") }}
        </MazCheckbox>
      </div>
    </div>
    <div style="float: right">
      <button class="btn btn-primary d-inline mx-2 my-2" @click="applyFilter()">
        {{ $t("Global.Apply_16") }}
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import { formatDate, presetRanges } from "@/_helpers/utils/date";
import { storeToRefs } from "pinia";
import { useRecurringInstructionStore } from "@/stores/recurring-instructions.store";
import Multiselect from "@vueform/multiselect";
import moment from "moment/moment";

const emit = defineEmits(["apply"]);

const { RecurringFilterOptions } = storeToRefs(useRecurringInstructionStore());
const { setOptions } = useRecurringInstructionStore();
const currentFilter = ref({
  project_id: "",
  customer: "",
  supplier_id: "",
  created_by: "",
  current_balance_max: "",
  current_balance_min: "",
  document_no_internal: "",
  end_after: "",
  frequency: "",
  gross_euro_max: "",
  gross_euro_min: "",
  name: "",
  ordering: "",
  page: "",
  page_size: "",
  recurring_number: "",
  released_by: "",
  search: "",
  created_on_after: "",
  created_on_before: "",
  start_date_after: "",
  start_date_before: "",
  status: "",
  supplier: "",
  clarification_requested_only: false,
});

const applyFilter = () => {
  setOptions(currentFilter.value);
  emit("apply", currentFilter.value);
};

const frequencyOptions = [
  { name: "Daily", value: "DAILY" },
  { name: "Weekly", value: "WEEKLY" },
  { name: "Monthly", value: "MONTHLY" },
  { name: "Quarterly", value: "QUARTERLY" },
  { name: "Yearly", value: "YEARLY" },
];

const startDateRange: any = ref(null);
const createdOnRange: any = ref(null);

onMounted(() => {
  currentFilter.value = { ...RecurringFilterOptions.value };
  startDateRange.value = [
    currentFilter.value.start_date_after
      ? moment(currentFilter.value.start_date_after)
      : "",
    currentFilter.value.start_date_before
      ? moment(currentFilter.value.start_date_before)
      : "",
  ];
  createdOnRange.value = [
    currentFilter.value.created_on_after
      ? moment(currentFilter.value.created_on_after)
      : "",
    currentFilter.value.created_on_before
      ? moment(currentFilter.value.created_on_before)
      : "",
  ];
});

watch(startDateRange, (newVal) => {
  if (newVal) {
    currentFilter.value.start_date_after = newVal[0]
      ? moment(newVal[0]).format("YYYY-MM-DD")
      : "";
    currentFilter.value.start_date_before = newVal[1]
      ? moment(newVal[1]).format("YYYY-MM-DD")
      : "";
  } else {
    currentFilter.value.start_date_after = "";
    currentFilter.value.start_date_before = "";
  }
});

watch(createdOnRange, (newVal) => {
  if (newVal) {
    currentFilter.value.created_on_after = newVal[0]
      ? moment(newVal[0]).format("YYYY-MM-DD")
      : "";
    currentFilter.value.created_on_before = newVal[1]
      ? moment(newVal[1]).format("YYYY-MM-DD")
      : "";
  } else {
    currentFilter.value.created_on_after = "";
    currentFilter.value.created_on_before = "";
  }
});
</script>
