<template>
  <div class="tw-flex tw-flex-col tw-gap-8">
    <!-- filter header -->
    <StatisticsFilter @update:filters="handleFiltersChange" />
    <!-- end filter header -->
    <div
      v-if="!filters.customerId"
      class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-full"
    >
      <selectionNeededIllustration
        class="tw-w-full tw-h-auto tw-max-w-[300px]"
      />
      <p class="tw-mt-4 tw-text-lg tw-text-gray-600">
        {{ $t("Statistics.SelectionNeeded_22") }}
      </p>
    </div>
    <!--    overview -->
    <StatisticsOverview
      v-if="filters.customerId"
      :startDate="filters.startDate"
      :endDate="filters.endDate"
      :customerId="filters.customerId"
    />
    <!--    end overview -->
    <!-- sections -->
    <NumberOfRequests
      v-if="filters.customerId"
      :startDate="filters.startDate"
      :endDate="filters.endDate"
      :customerId="filters.customerId"
    />
    <OrderVolume
      v-if="filters.customerId"
      :startDate="filters.startDate"
      :endDate="filters.endDate"
      :customerId="filters.customerId"
    />
    <PaymentVolume
      v-if="filters.customerId"
      :startDate="filters.startDate"
      :endDate="filters.endDate"
      :customerId="filters.customerId"
    />
    <OrderCommitmentTrend
      v-if="filters.customerId"
      :startDate="filters.startDate"
      :endDate="filters.endDate"
      :customerId="filters.customerId"
    />
    <OrderBalanceTrend
      v-if="filters.customerId"
      :startDate="filters.startDate"
      :endDate="filters.endDate"
      :customerId="filters.customerId"
    />
  </div>
</template>

<script setup>
import StatisticsFilter from "@/views/Statistics/sections/StatisticsFilter.vue";
import StatisticsOverview from "@/views/Statistics/sections/StatisticsOverview.vue";
import NumberOfRequests from "@/views/Statistics/sections/NumberOfRequests.vue";
import OrderVolume from "@/views/Statistics/sections/OrderVolume.vue";
import PaymentVolume from "@/views/Statistics/sections/PaymentVolume.vue";
import OrderCommitmentTrend from "@/views/Statistics/sections/OrderCommitmentTrend.vue";
import OrderBalanceTrend from "@/views/Statistics/sections/OrderBalanceTrend.vue";
import selectionNeededIllustration from "@/assets/images/selection-needed.svg";

import { ref } from "vue";

const filters = ref({
  startDate: "",
  endDate: "",
  customerId: null,
});

const handleFiltersChange = (values) => {
  filters.value.customerId = values.customerId;
  filters.value.startDate = values.startDate;
  filters.value.endDate = values.endDate;
};
</script>
