<template>
  <div class="card">
    <div class="card-body">
      <div class="title-and-upload">
        <div class="card-title">
          {{ $t("UserProfile.Signature_8") }}
        </div>

        <div class="upload-signature">
          <label for="uploadSignature" class="btn">
            <i class="icon-upload"></i>
            {{ $t("UserProfile.UploadSignature_9") }}
          </label>
        </div>
      </div>

      <div class="signature-container">
        <input
          type="file"
          id="uploadSignature"
          accept="image/png, image/jpeg"
          @change="onSignatureUploaded"
        />

        <img
          v-if="currentUser?.signature"
          class="user-signature"
          :src="currentUser?.signature"
          alt="User Signature"
        />

        <label v-else for="uploadSignature" class="signature-upload-zone">
          <i class="icon-upload-cloud"></i>

          <p>
            <strong>{{ $t("UserProfile.ClickToUpload_10") }}</strong>
            {{ $t("UserProfile.YourSignature_11") }}
          </p>
          <p>JPEG, PNG, (max 10mb)</p>
        </label>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from "@/stores/user.store";
import { useFileValidation } from "@/_helpers/composables/file-validation.composable";
import { storeToRefs } from "pinia";
import { ref, type Ref } from "vue";

const { currentUser } = storeToRefs(useUserStore());
const { editUserProfile } = useUserStore();

const isSignatureValid: Ref<boolean | null> = ref(null);

const onSignatureUploaded = async (e: any) => {
  const signatureFile: File = e.target.files[0];
  const { validateSize, validateTypeAndExtension } = useFileValidation();
  isSignatureValid.value =
    validateSize(signatureFile, 0, 10) &&
    validateTypeAndExtension(signatureFile);

  if (isSignatureValid.value) {
    await editUserProfile({ signature: signatureFile });
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables/variables.scss";

.card-body {
  height: 210px;
}

.title-and-upload {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.6rem;

  .upload-signature {
    label {
      background-color: #eef2f7;
      color: #3f4456;
      font-size: 0.75rem;
    }
  }
}

.signature-container {
  color: #3f4456;

  input {
    display: none;
  }

  .user-signature {
    height: 120px;
    width: 100%;
    object-fit: cover;
  }

  .signature-upload-zone {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.7rem;
    border: 1px dashed #dcdcdc;
    cursor: pointer;

    .icon-upload-cloud {
      font-size: 1.5rem;
      margin-bottom: 5px;
    }

    p:nth-child(2) {
      font-size: 0.9rem;
    }
  }
}
</style>
