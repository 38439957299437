<template>
  <div class="generic-popup adjustment-popup">
    <div class="popup-wrapper popup-wrapper-xl">
      <div class="popup-header">
        <h2 class="popup-title">
          {{ $t("Payments.AllocatePaymentAmount_32") }}
        </h2>
        <span class="popup-close ms-auto" @click="closeAdjustmentModal()">
          <i class="icon-close"></i>
        </span>
      </div>
      <div class="popup-body">
        <div>
          <div class="accordion" id="adjustAccordion">
            <div
              class="accordion-item"
              v-for="(data, index) in props.paymentsData"
              :key="index"
            >
              <h2 class="accordion-header">
                <button
                  class="accordion-button bg-light text-black"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="`#collapse${index}`"
                  aria-expanded="true"
                  :aria-controls="`#collapse${index}`"
                  v-if="paymentsData.length ! == 1"
                >
                  <p class="mb-0" v-if="data.excessAmount > 0">
                    {{ $t("Payments.PaymentAmount_5") }} "{{
                      $n(+data.payment.amount, "currency")
                    }}" {{ $t("Payments.PaymentGreaterOrders_33") }}
                  </p>
                  <p class="mb-0" v-else>
                    {{ $t("Payments.PaymentAmount_5") }} "{{
                      $n(+data.payment.amount, "currency")
                    }}" {{ $t("Payments.PaymentLessOrders_34") }}
                  </p>
                </button>
              </h2>
              <div
                :id="`collapse${index}`"
                :class="getItemClasses(index)"
                aria-labelledby="headingOne"
                data-bs-parent="#adjustAccordion"
              >
                <div class="accordion-body">
                  <div class="align-items-center justify-content-around mt-4">
                    <adjustment-table
                      :payment-id="data.payment.id"
                      :payment-amount="data.payment.amount"
                      :list="data.payment.orders_verbose"
                      :excess-amount="data.excessAmount"
                      @adjusted="updateAdjustment"
                    ></adjustment-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="alert alert-danger text-left my-3 mx-5 d-inline-block"
        role="alert"
        v-if="!allValid"
      >
        <h6 class="mb-0">
          <span><strong>Note: </strong></span>
          <span>{{ validationMessage }}</span>
        </h6>
      </div>
      <div class="d-flex justify-content-around popup-footer my-4">
        <button
          class="btn btn-primary"
          @click="upgradeOrderAmount"
          :disabled="!allValid"
        >
          {{ $t("Payments.AllocateAndSubmit_52") }}
        </button>
        <button class="btn btn-secondary" @click="closeAdjustmentModal">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch } from "vue";
import AdjustmentTable from "@/components/shared/tables/payments-table/AdjustmentTable.vue";
import { useI18n } from "vue-i18n";

const { t, n } = useI18n();

const emit = defineEmits(["closeAdjustmentModal", "adjustPayments"]);

const paymentAdjustments = ref<any>({});

// props
const props = defineProps<{
  paymentsData: any;
}>();

const customerId = ref<number>(0);
const validPayments = ref<any>({});
const validationMessage =
  "Please allocate exactly the total amount of the payment";
const allValid = computed(() => {
  return Object.values(validPayments.value).every(
    (value: any) => value === true
  );
});
onMounted(() => {
  customerId.value = props.paymentsData[0].payment.customer;
});

const closeAdjustmentModal = () => {
  emit("closeAdjustmentModal");
};

const getItemClasses = (index: number | string) => {
  return {
    "accordion-collapse": true,
    collapse: true,
    show: index === 0,
  };
};

const updateAdjustment = (data: any) => {
  const paymentId = data.paymentId;
  paymentAdjustments.value[paymentId] = data.orders;
  if (data.isValid) {
    validPayments.value[paymentId] = true;
  } else {
    validPayments.value[paymentId] = false;
  }
};

const upgradeOrderAmount = () => {
  emit("adjustPayments", paymentAdjustments.value);
};
</script>

<style scoped>
.adjustment-popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
