<template>
  <div class="list-table">
    <div class="list-header">
      <div class="list-header__text">
        <h5>{{ $t("Users.Users_14") }}</h5>
      </div>

      <div class="list-header__actions">
        <div class="bulk-actions" v-if="selectedUsers.length > 1">
          <div class="dropdown">
            <button
              class="btn btn-primary dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Bulk actions
            </button>
            <ul class="dropdown-menu">
              <li>
                <a
                  class="dropdown-item"
                  @click="onChangeBulkUsersActivityStatus('activate')"
                >
                  {{ $t("Users.ActivateSelectedUsers_30") }}
                </a>
              </li>

              <li>
                <a
                  class="dropdown-item"
                  @click="onChangeBulkUsersActivityStatus('deactivate')"
                >
                  {{ $t("Users.DeactivateSelectedUsers_20") }}
                </a>
              </li>
            </ul>
          </div>
        </div>

        <RouterLink :to="{ name: 'create-user' }" class="btn btn-primary">
          <i class="icon-upload"></i>
          <span>{{ $t("Users.NewUser_15") }}</span>
        </RouterLink>
      </div>
    </div>

    <InocuDataTable
      v-model:items-selected="selectedUsers"
      v-model:server-options="serverOptions"
      :server-items-length="usersListTotalCount"
      :loading="loading"
      :headers="usersTableHeaders"
      :items="usersList"
      :rows-items="[10, 25, 50, 100]"
      fixed-checkbox
      :checkbox-column-width="60"
      :table-min-height="500"
      theme-color="#CEEC34"
      table-class-name="listing-custom-table"
      alternating
      buttons-pagination
      no-hover
      must-sort
    >
      <!-- Filtrable header cells -->
      <template #header-first_name="header">
        <div class="filter-column" @click.stop>
          <i
            class="icon-filter filter-icon"
            @click.stop="showFirstNameFilter = !showFirstNameFilter"
          ></i>
          {{ header.text }}
          <div class="filter-menu" v-if="showFirstNameFilter">
            <input
              v-model="firstNameCriteria"
              type="text"
              class="form-control"
              @input="filterUsersList('first_name', firstNameCriteria)"
            />
          </div>
        </div>
      </template>

      <template #header-last_name="header">
        <div class="filter-column" @click.stop>
          <i
            class="icon-filter filter-icon"
            @click.stop="showLastNameFilter = !showLastNameFilter"
          ></i>
          {{ header.text }}
          <div class="filter-menu" v-if="showLastNameFilter">
            <input
              v-model="lastNameCriteria"
              type="text"
              class="form-control"
              @input="filterUsersList('last_name', lastNameCriteria)"
            />
          </div>
        </div>
      </template>

      <template #header-email="header">
        <div class="filter-column" @click.stop>
          <i
            class="icon-filter filter-icon"
            @click.stop="showEmailFilter = !showEmailFilter"
          ></i>
          {{ header.text }}
          <div class="filter-menu" v-if="showEmailFilter">
            <input
              v-model="emailCriteria"
              type="text"
              class="form-control"
              @input="filterUsersList('email', emailCriteria)"
            />
          </div>
        </div>
      </template>

      <template #header-created_on="header">
        <div class="filter-column" @click.stop>
          <i
            class="icon-filter filter-icon"
            @click.stop="showCreatedOnFilter = !showCreatedOnFilter"
          ></i>
          {{ header.text }}
          <div class="filter-menu" v-if="showCreatedOnFilter">
            <DatePicker
              v-model="creationDateRange"
              placeholder="Range"
              :clearable="false"
              :month-change-on-scroll="false"
              :format="formatDate"
              :preview-format="formatDate"
              :max-date="new Date()"
              :select-text="$t('Global.Apply_16')"
              :cancel-text="$t('Global.Cancel_17')"
              range
            />

            <button
              class="btn btn-primary mt-2"
              @click="creationDateRange = ''"
            >
              Reset
            </button>
          </div>
        </div>
      </template>

      <template #header-active="header">
        <div class="filter-column" @click.stop>
          <i
            class="icon-filter filter-icon"
            @click.stop="showActiveFilter = !showActiveFilter"
          ></i>
          {{ header.text }}
          <div class="filter-menu" v-if="showActiveFilter">
            <div class="form-group">
              <div class="form-check">
                <input
                  id="activeRadio"
                  v-model="activeCriteria"
                  type="radio"
                  value="active"
                  class="form-check-input"
                  @change="filterUsersList('active', activeCriteria)"
                />
                <label class="form-check-label" for="supplierRadio">
                  {{ $t("Users.Active_21") }}
                </label>
              </div>

              <div class="form-check">
                <input
                  id="inActiveRadio"
                  v-model="activeCriteria"
                  type="radio"
                  value="inActive"
                  class="form-check-input"
                  @change="filterUsersList('active', activeCriteria)"
                />
                <label class="form-check-label" for="supplierRadio">
                  {{ $t("Users.InActive_22") }}
                </label>
              </div>
            </div>

            <button
              class="btn btn-primary mt-2 ms-auto"
              @click="
                activeCriteria = '';
                filterUsersList('active', activeCriteria);
              "
            >
              Reset
            </button>
          </div>
        </div>
      </template>

      <!-- Custom cells display -->
      <template #item-last_name="user">
        {{ user.last_name ? user.last_name : "--" }}
      </template>

      <template #item-created_on="user">
        {{ new Date(user.created_on).toLocaleDateString("de-DE") }}
      </template>

      <template #item-active="user">
        {{ user.active ? $t("Users.Active_21") : $t("Users.InActive_22") }}
      </template>

      <template #item-actions="user">
        <div class="actions">
          <router-link :to="{ name: 'edit-user', params: { userId: user.id } }">
            <VueCustomTooltip
              :label="$t('Users.EditUser_31')"
              position="is-left"
            >
              <i class="icon-edit"></i>
            </VueCustomTooltip>
          </router-link>

          <template v-if="user.active">
            <a
              class="deactivate-user"
              @click="
                onChangeUserActivityStatus(
                  user.display_name,
                  'deactivate',
                  user.id
                )
              "
            >
              <VueCustomTooltip
                :label="$t('Users.DeactivateUser_25')"
                position="is-left"
              >
                ✖
              </VueCustomTooltip>
            </a>
          </template>

          <template v-else>
            <a
              class="activate-user"
              @click="
                onChangeUserActivityStatus(
                  user.display_name,
                  'activate',
                  user.id
                )
              "
            >
              <VueCustomTooltip
                :label="$t('Users.ActivateUser_29')"
                position="is-left"
              >
                ✓
              </VueCustomTooltip>
            </a>
          </template>
        </div>
      </template>

      <!-- No Data View -->
      <template #empty-message>
        {{ $t("Users.NoUsersToShow_38") }}...
      </template>
    </InocuDataTable>
  </div>

  <MazDialogPromise
    :data="{
      title: $t('Users.Attention_39').toLocaleUpperCase(),
      message: deactivationDialogMessage,
    }"
    identifier="deactivateUserDialog"
  >
    <template #confirm-text>
      {{ $t("Users.Confirm_40") }}
    </template>

    <template #cancel-text>
      {{ $t("Users.Cancel_41") }}
    </template>
  </MazDialogPromise>
</template>

<script setup lang="ts">
import MazDialogPromise, {
  useMazDialogPromise,
} from "maz-ui/components/MazDialogPromise";
import { onMounted, ref, type Ref, watch } from "vue";
import { useUserStore } from "@/stores/user.store";
import type { UserInList } from "@/_helpers/interfaces";

import type { Header, ServerOptions } from "vue3-easy-data-table";

import { useToast } from "vue-toast-notification";
import { useI18n } from "vue-i18n";
import { debounce } from "lodash";

const props = defineProps<{
  customerId?: number | null;
}>();

watch(props, (value) => {
  const { customerId } = value;

  filterUsersList("customer_id", customerId as number | null);
});

const $toast = useToast();

const { t } = useI18n();

const usersTableHeaders: Ref<Header[]> = ref([
  {
    text: t("Users.FirstName_3"),
    value: "first_name",
    width: 250,
    sortable: true,
  },
  {
    text: t("Users.LastName_12"),
    value: "last_name",
    width: 230,
    sortable: true,
  },
  { text: t("Global.Email_8"), value: "email", width: 300 },
  {
    text: t("Users.CreationDate_43"),
    value: "created_on",
    width: 220,
    sortable: true,
  },
  { text: t("Users.Status_44"), value: "active", width: 220, sortable: true },
  { text: t("Users.Actions_45"), value: "actions" },
]);

const usersList: Ref<UserInList[]> = ref([]);
const selectedUsers: Ref<UserInList[]> = ref([]);
const loading = ref(false);
const usersListTotalCount = ref(0);
const serverOptions = ref<ServerOptions>({
  page: 1,
  rowsPerPage: 10,
  sortBy: "first_name",
  sortType: "asc",
});

const {
  getUsersList,
  changeUserActivityStatus,
  changeBulkUsersActivityStatus,
} = useUserStore();

onMounted(async () => {
  await getUsersTableList();
});

// Fires when page number changes or items per page value changes
watch(
  serverOptions,
  async () => {
    await getUsersTableList();
  },
  {
    deep: true,
  }
);

const getUsersTableList = async () => {
  loading.value = true;

  const sortBy = serverOptions.value.sortBy;
  const sortDirection = serverOptions.value.sortType === "asc" ? "" : "-";

  const response = await getUsersList({
    page: serverOptions.value.page,
    page_size: serverOptions.value.rowsPerPage,
    ordering: sortDirection + sortBy,
    ...filtrationCriteria.value,
  });

  usersList.value = [...response.results];
  usersListTotalCount.value = response.count;

  loading.value = false;
};

const { showDialogAndWaitChoice } = useMazDialogPromise();
const deactivationDialogMessage = ref("");

const onChangeBulkUsersActivityStatus = async (
  status: "activate" | "deactivate"
) => {
  try {
    if (status === "deactivate") {
      deactivationDialogMessage.value = t(
        "Users.AreYouSureYouWantToDeactivate_42",
        {
          usersCount: selectedUsers.value.length,
        }
      );
      await showDialogAndWaitChoice("deactivateUserDialog");
    }

    const selectedUsersIds = selectedUsers.value.map((user) => user.id);

    const bulkDeactivationResults = await changeBulkUsersActivityStatus(
      status,
      selectedUsersIds
    );

    await getUsersTableList();

    $toast.open({
      message: `All selected users have been deactivated successfully.`,
      type: "success",
    });

    selectedUsers.value = [];

    if (bulkDeactivationResults.warnings?.length) {
      bulkDeactivationResults.warnings.forEach((warning) => {
        $toast.open({
          message: warning,
          type: "warning",
          duration: 0,
        });
      });
    }
  } catch (e) {
    e;
  }
};

const onChangeUserActivityStatus = async (
  userName: string,
  status: "activate" | "deactivate",
  userId: number
) => {
  try {
    if (status === "deactivate") {
      deactivationDialogMessage.value = t(
        "Users.AreYouSureYouWantToDeactivate_42",
        {
          usersCount: "this",
        }
      );
      await showDialogAndWaitChoice("deactivateUserDialog");
    }

    const deactivationResult = await changeUserActivityStatus(status, userId);
    await getUsersTableList();

    $toast.open({
      message: `User (${userName}) ${
        status === "activate" ? "activated" : "deactivated"
      } successfully.`,
      type: "success",
    });

    if (deactivationResult.warning) {
      $toast.open({
        message: deactivationResult.warning,
        type: "warning",
        duration: 0,
      });
    }
  } catch (e) {
    e;
  }
};

const filtrationCriteria = ref({});

const showFirstNameFilter = ref(false);
const firstNameCriteria = ref("");

const showLastNameFilter = ref(false);
const lastNameCriteria = ref("");

const showEmailFilter = ref(false);
const emailCriteria = ref("");

const showActiveFilter = ref(false);
const activeCriteria = ref("");

const showCreatedOnFilter = ref(false);

const creationDateRange = ref();
const formatDate = (date: Date[]) => {
  const [startDate, endDate] = date;
  const startDateDetails = {
    day: startDate.getDate(),
    month: startDate.getMonth() + 1,
    year: startDate.getFullYear(),
  };
  const endDateDetails = {
    day: endDate ? endDate.getDate() : "",
    month: endDate ? endDate.getMonth() + 1 : "",
    year: endDate ? endDate.getFullYear() : "",
  };

  return `${startDateDetails.day}.${startDateDetails.month}.${startDateDetails.year} to ${endDateDetails.day}.${endDateDetails.month}.${endDateDetails.year}`;
};

watch(creationDateRange, async (dateRange) => {
  if (dateRange) {
    filtrationCriteria.value["created_after"] = dateRange[0]
      .toISOString()
      .split("T")[0];
    filtrationCriteria.value["created_before"] = dateRange[1]
      .toISOString()
      .split("T")[0];
  } else {
    filtrationCriteria.value["created_after"] = null;
    filtrationCriteria.value["created_before"] = null;
  }

  await getUsersTableList();
});

const filterUsersList = debounce(async function (
  filterCriteria: string,
  criteriaValue: string | number | null
) {
  if (filterCriteria === "active")
    filtrationCriteria.value[filterCriteria] =
      criteriaValue === "active" ? true : criteriaValue === "" ? null : false;
  else filtrationCriteria.value[filterCriteria] = criteriaValue;

  await getUsersTableList();
},
300);
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables/variables.scss";

.activate-user {
  color: $base-dark-green;
}

.deactivate-user {
  color: $base-dark-red;
}
</style>
