<template>
  <div>
    <div class="list-header">
      <div class="list-header__text">
        <h5>Select Payments</h5>
      </div>
      <div class="list-header__actions">
        <VueDatePicker
          v-model="selectedPaymentFilter.date"
          :placeholder="$t('Order.SelectDate_49')"
          :enable-time-picker="false"
          :select-text="$t('Order.Apply_50')"
          auto-apply
          range
          :format="formatDate"
        />
      </div>
    </div>
    <InocuDataTable
      v-model:items-selected="selectedPayments"
      v-model:server-options="serverOptions"
      :server-items-length="paymentsListTotalCount"
      :loading="loading"
      :headers="paymentsTableHeaders"
      :items="computedPaymentList"
      :rows-items="[10, 25, 50, 100]"
      table-class-name="listing-custom-table"
      alternating
      buttons-pagination
      no-hover
      must-sort
    >
      <template #item-payment_no="payment">
        <router-link
          :to="{ name: 'payment', params: { id: payment.id } }"
          class="dropdown-item"
        >
          {{
            payment.payment_number !== null ? payment.payment_number : "N/A"
          }}</router-link
        >
      </template>
      <template #item-date="payment">
        {{ moment(payment.date).format("DD.MM.YYYY") }}
      </template>
      <template #item-payment_amount="payment">
        {{ payment.amount ? $n(+payment.amount, "currency") : "-" }}
      </template>
      <template #item-released_by="payment">
        {{ payment.released_by ? payment.released_by.display_name : "N/A" }}
      </template>
      <template #item-reference="payment">
        <div class="generic-form">
          <div class="form-input d-flex align-item-center m-0">
            <input
              v-model="payment.invoice_number"
              @keyup="setInvoiceNumber(payment.id, payment.invoice_number)"
              :placeholder="$t('Payments.PaymentReferenceMessage_31')"
            />
          </div>
        </div>
      </template>
      <template #item-status="payment">
        <span
          class="order-badge approved-badge"
          v-if="payment.status === 'APPROVED'"
        >
          Approved
        </span>
      </template>
      <template #item-b_numbers="payment">
        <router-link
          :to="{ name: 'payment', params: { id: item.id } }"
          v-for="(item, index) in payment.orders"
          :key="item"
        >
          {{ item.order_number
          }}<span v-if="index < payment.orders.length - 1">, </span>
        </router-link>
      </template>
    </InocuDataTable>
  </div>
</template>

<script setup lang="ts">
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { ref, reactive, type Ref, onMounted, watch, computed } from "vue";
import type { Header, ServerOptions, Item } from "vue3-easy-data-table";
import { useI18n } from "vue-i18n";
import moment from "moment";
import { usePaymentStore } from "@/stores/payment.store";

const { getPayments } = usePaymentStore();

const props = defineProps({
  index: {
    default: 0,
    type: Number,
  },
  customer: {
    default: null,
    type: Number,
  },
});

const { t } = useI18n();

let selectedPaymentFilter = reactive({
  date: [],
});

const loading = ref(false);

const paymentsListTotalCount = ref(0);

// const selectedPayments: any = ref([]);

const selectedPayments: Ref<Item[]> = ref([]);

const serverOptions = ref<ServerOptions>({
  page: 1,
  rowsPerPage: 10,
  sortBy: "payment_number",
  sortType: "asc",
});

const paymentList: any = ref([]);

const customerId: any = ref(null);

const paymentsTableHeaders: Ref<Header[]> = ref([
  {
    text: "Payment No.",
    value: "payment_no",
    sortable: true,
  },
  {
    text: "Submission Date",
    value: "date",
    sortable: true,
  },
  {
    text: "Payment Amount",
    value: "payment_amount",
  },
  {
    text: t("Order.ReleasedBy_78"),
    value: "released_by",
  },
  {
    text: t("Order.Status_80"),
    value: "status",
  },
  {
    text: t("Payments.PaymentReference_30"),
    value: "reference",
  },
  {
    text: t("Order.Requests_84"),
    value: "b_numbers",
  },
]);

const formatDate = (date: Date) => {
  const date1 = moment(date[0]).format("DD.MM.YYYY");
  let date2 = moment(date[1]).format("DD.MM.YYYY");

  if (new Date(date[1]) > new Date()) {
    date2 = moment(new Date()).format("DD.MM.YYYY");
  }

  return `${date1} To ${date2}`;
};

const getPaymentList = async (customer: number) => {
  /* Get Order */
  loading.value = true;
  const sortBy = serverOptions.value.sortBy;
  const sortDirection = serverOptions.value.sortType === "asc" ? "" : "-";
  await getPayments({
    page: serverOptions.value.page,
    page_size: serverOptions.value.rowsPerPage,
    search: "",
    ordering: sortDirection + sortBy,
    is_active: true,
    customer: customer,
    status: "APPROVED",
    created_before:
      selectedPaymentFilter.date.length > 0
        ? moment(selectedPaymentFilter.date[1]).format("YYYY-MM-DD")
        : null,
    created_after:
      selectedPaymentFilter.date.length > 0
        ? moment(selectedPaymentFilter.date[0]).format("YYYY-MM-DD")
        : null,
  }).then((response) => {
    paymentList.value = response.results;
    paymentsListTotalCount.value = response.count;

    loading.value = false;
  });
};

const computedPaymentList = computed(() => {
  return paymentList.value;
});

const setInvoiceNumber = (id: any, reference: any) => {
  paymentList.value.map((e: any) => {
    if (e.id === id) {
      e.invoice_number = reference;
    }
  });
};

onMounted(async () => {
  customerId.value = props.customer;
  await getPaymentList(customerId.value);
});

const emit = defineEmits(["select-payments"]);

watch(
  selectedPayments,
  (newValue) => {
    emit("select-payments", newValue);
  },
  {
    immediate: true,
    deep: true,
  }
);

watch(
  serverOptions,
  async () => {
    await getPaymentList(customerId.value);
  },
  {
    deep: true,
  }
);

watch(
  props,
  async (newValue) => {
    await getPaymentList(newValue.customer);
  },
  {
    deep: true,
  }
);

watch(
  selectedPaymentFilter,
  async () => {
    await getPaymentList(customerId.value);
  },
  {
    deep: true,
  }
);
</script>
