<template>
  <div class="list-bank-accounts-page">
    <div class="list-table">
      <div class="list-header">
        <div class="list-header__text">
          <h5>{{ $t("Suppliers.CustomerBankAccounts_80") }}</h5>
        </div>

        <div class="list-header__actions">
          <div class="bulk-actions" v-if="selectedBankAccounts.length > 1">
            <div class="dropdown">
              <button
                class="btn btn-primary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{ $t("Suppliers.BulkActions_59") }}
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item" @click="onBulkDeleteBankAccounts()">
                    {{ $t("Suppliers.DeleteAllSelectedBankAccounts_60") }}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <button class="btn btn-secondary" @click="bulkImportBankAccounts">
            <i class="icon-import-line"></i>
            <span>{{ $t("Suppliers.BulkImport_37") }}</span>
          </button>

          <div class="export-bank-accounts">
            <div class="dropdown">
              <button
                class="btn btn-secondary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="icon-export-line"></i>
                <span>{{ $t("Suppliers.Export_35") }}</span>
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a
                    class="dropdown-item"
                    @click="exportAllBankAccounts('csv')"
                  >
                    {{ $t("Suppliers.DownloadAs_36") }}
                    csv
                  </a>
                </li>

                <li>
                  <a
                    class="dropdown-item"
                    @click="exportAllBankAccounts('xlsx')"
                  >
                    {{ $t("Suppliers.DownloadAs_36") }}
                    xlsx
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <RouterLink
            :to="{ name: 'create-bank-account', query: { type: 'customer' } }"
            class="btn btn-primary"
          >
            <i class="icon-upload"></i>
            <span>{{ $t("Suppliers.NewBankAccount_61") }}</span>
          </RouterLink>
        </div>
      </div>

      <InocuDataTable
        v-model:items-selected="selectedBankAccounts"
        v-model:server-options="serverOptions"
        :server-items-length="bankAccountsListTotalCount"
        :loading="loading"
        :headers="bankAccountsTableHeaders"
        :items="bankAccountsList"
        :rows-items="[10, 25, 50, 100]"
        fixed-checkbox
        :checkbox-column-width="60"
        :table-min-height="500"
        theme-color="#CEEC34"
        table-class-name="listing-custom-table"
        alternating
        buttons-pagination
        no-hover
        must-sort
      >
        <!-- Filtrable header cells -->
        <template #header-bank_name="header">
          <div class="filter-column" @click.stop>
            <i
              class="icon-filter filter-icon"
              @click.stop="showBankNameFilter = !showBankNameFilter"
            ></i>
            {{ header.text }}
            <div class="filter-menu" v-if="showBankNameFilter">
              <input
                v-model="bankNameCriteria"
                type="text"
                class="form-control"
                @input="filterBankAccountsList('bank_name', bankNameCriteria)"
              />
            </div>
          </div>
        </template>

        <template #header-owner_name="header">
          <div class="filter-column" @click.stop>
            <i
              class="icon-filter filter-icon"
              @click.stop="showOwnerNameFilter = !showOwnerNameFilter"
            ></i>
            {{ header.text }}
            <div class="filter-menu" v-if="showOwnerNameFilter">
              <input
                v-model="ownerNameCriteria"
                type="text"
                class="form-control"
                @input="filterBankAccountsList('owner_name', ownerNameCriteria)"
              />
            </div>
          </div>
        </template>

        <template #header-customer_name="header">
          <div class="filter-column" @click.stop>
            <i
              class="icon-filter filter-icon"
              @click.stop="showCustomerNameFilter = !showCustomerNameFilter"
            ></i>
            {{ header.text }}
            <div class="filter-menu" v-if="showCustomerNameFilter">
              <input
                v-model="customerNameCriteria"
                type="text"
                class="form-control"
                @input="
                  filterBankAccountsList('customer_name', customerNameCriteria)
                "
              />
            </div>
          </div>
        </template>

        <template #header-supplier_name="header">
          <div class="filter-column" @click.stop>
            <i
              class="icon-filter filter-icon"
              @click.stop="showSupplierNameFilter = !showSupplierNameFilter"
            ></i>
            {{ header.text }}
            <div class="filter-menu" v-if="showSupplierNameFilter">
              <input
                v-model="supplierNameCriteria"
                type="text"
                name="searchSuppliersName"
                id="searchSuppliersName"
                class="form-control"
                @input="
                  filterBankAccountsList('supplier_name', supplierNameCriteria)
                "
              />
            </div>
          </div>
        </template>

        <template #header-created_on="header">
          <div class="filter-column" @click.stop>
            <i
              class="icon-filter filter-icon"
              @click.stop="showCreatedOnFilter = !showCreatedOnFilter"
            ></i>
            {{ header.text }}
            <div class="filter-menu" v-if="showCreatedOnFilter">
              <DatePicker
                v-model="creationDateRange"
                placeholder="Range"
                :clearable="false"
                :month-change-on-scroll="false"
                :format="formatDate"
                :preview-format="formatDate"
                :max-date="new Date()"
                :select-text="$t('Global.Apply_16')"
                :cancel-text="$t('Global.Cancel_17')"
                range
              />

              <button
                class="btn btn-primary mt-2"
                @click="creationDateRange = ''"
              >
                Reset
              </button>
            </div>
          </div>
        </template>

        <!-- Custom cells display -->
        <template #item-created_on="bankAccount">
          {{ new Date(bankAccount.created_on).toLocaleDateString("de-DE") }}
        </template>

        <template #item-supplier_name="bankAccount">
          {{ bankAccount.supplier_name ? bankAccount.supplier_name : "--" }}
        </template>

        <template #item-customer_name="bankAccount">
          {{ bankAccount.customer_name ? bankAccount.customer_name : "--" }}
        </template>

        <template #item-actions="bankAccount">
          <div class="actions">
            <router-link
              :to="{
                name: 'edit-bank-account',
                params: { bankAccountId: bankAccount.id },
              }"
            >
              <i class="icon-edit"></i>
            </router-link>

            <span
              @click="
                onDeleteBankAccount(bankAccount.id, bankAccount.bank_name)
              "
            >
              <i class="icon-delete"></i>
            </span>
          </div>
        </template>

        <!-- No Data View -->
        <template #empty-message>
          {{ $t("Suppliers.NoBankAccountsToShow_65") }}...
        </template>
      </InocuDataTable>
    </div>

    <MazDialogPromise
      :data="{
        title: $t('Suppliers.Attention_27').toLocaleUpperCase(),
        message: deletionDialogMessage,
      }"
      identifier="deleteBankAccountDialog"
    >
      <template #confirm-text>
        {{ $t("Suppliers.Confirm_29") }}
      </template>

      <template #cancel-text>
        {{ $t("Suppliers.Cancel_30") }}
      </template>
    </MazDialogPromise>

    <MazDialog
      v-model="showBulkImportBankAccountsModal"
      :title="$t('Suppliers.BulkImportBankAccounts_62')"
    >
      <div class="upload-bank-accounts-file">
        <label
          for="uploadBankAccountsFile"
          class="btn btn-secondary"
          :class="{
            disabled: isUploadingBankAccountsFile,
          }"
        >
          <i class="icon-upload-cloud"></i>

          <span>
            {{ $t("Suppliers.UploadBankAccountsFileAs_63") }}
            CSV
            {{ $t("Suppliers.Or_39") }}
            XLSX
          </span>
        </label>

        <input
          type="file"
          id="uploadBankAccountsFile"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          :style="{ display: 'none' }"
          @change="onBankAccountFileUpload(($event.target as any).files[0])"
        />
      </div>

      <p class="uploading-indicator" v-if="isUploadingBankAccountsFile">
        <MazSpinner color="success" />
        {{ $t("Suppliers.Uploading_42") }}...
      </p>

      <p class="file-errors" v-if="bankAccountsFileWithErrors">
        Uploaded file has errors, see them
        <a :href="bankAccountsFileWithErrors" download>here</a>
      </p>

      <p>
        {{ $t("Suppliers.DoesNotHaveTheTemplateDownloadIt_38") }}
        <a :href="bankAccountsCsvTemplate" download>csv</a>
        {{ $t("Suppliers.Or_39") }}
        <a :href="bankAccountsXlsxTemplate" download>xlsx</a>
      </p>
    </MazDialog>
  </div>
</template>

<script setup lang="ts">
import MazDialogPromise, {
  useMazDialogPromise,
} from "maz-ui/components/MazDialogPromise";
import MazDialog from "maz-ui/components/MazDialog";
import MazSpinner from "maz-ui/components/MazSpinner";
import { onMounted, ref, type Ref, watch } from "vue";

import debounce from "lodash/debounce";
import type { Header, ServerOptions } from "vue3-easy-data-table";

import { useToast } from "vue-toast-notification";
import { useI18n } from "vue-i18n";
import { useMasterDataFileValidation } from "@/_helpers/composables/master-data/file-validation.composable";
import { useBankAccountStore } from "@/stores/bank-account.store";
import type { BankAccountInList } from "@/_helpers/interfaces/bank-accounts/bank-account-in-list.interface";
import { useUserStore } from "@/stores/user.store";
import { storeToRefs } from "pinia";

const $toast = useToast();

const { t } = useI18n();

const { currentUser } = storeToRefs(useUserStore());

const bankAccountsTableHeaders: Ref<Header[]> = ref([
  {
    text: t("Suppliers.BankName_45"),
    value: "bank_name",
    width: 150,
    sortable: true,
  },
  { text: t("Suppliers.SwiftCode_46"), value: "swift_code", width: 150 },
  {
    text: t("Suppliers.AccountOwner_44"),
    value: "owner_name",
    width: 150,
    sortable: true,
  },
  { text: t("Suppliers.IBAN_48"), value: "iban", width: 250 },
  {
    text: t("Suppliers.CustomerName_71"),
    value: "customer_name",
    width: 200,
    sortable: true,
  },
  {
    text: t("Suppliers.CreationDate_69"),
    value: "created_on",
    width: 150,
    sortable: true,
  },
  { text: t("Suppliers.Actions_68"), value: "actions" },
]);

const bankAccountsList: Ref<BankAccountInList[]> = ref([]);
const selectedBankAccounts: Ref<BankAccountInList[]> = ref([]);
const loading = ref(false);
const bankAccountsListTotalCount = ref(0);
const serverOptions = ref<ServerOptions>({
  page: 1,
  rowsPerPage: 10,
  sortBy: "bank_name",
  sortType: "asc",
});

const {
  getBankAccountsList,
  deleteBankAccount,
  bulkDeleteBankAccounts,
  exportBankAccountsList,
  getImportCustomerBankAccountsTemplates,
  uploadBankAccountsFile,
} = useBankAccountStore();

onMounted(async () => {
  await getBankAccountsTableList();

  const templates = await getImportCustomerBankAccountsTemplates();
  bankAccountsCsvTemplate.value = templates.csv_template;
  bankAccountsXlsxTemplate.value = templates.xlsx_template;
});

// Fires when page number changes or items per page value changes
watch(
  serverOptions,
  async () => {
    await getBankAccountsTableList();
  },
  {
    deep: true,
  }
);

const getBankAccountsTableList = async () => {
  loading.value = true;

  let sortBy = serverOptions.value.sortBy;

  if (sortBy === "customer_name" || sortBy === "supplier_name")
    sortBy = sortBy.split("_").join("__");

  const sortDirection = serverOptions.value.sortType === "asc" ? "" : "-";
  if (!currentUser.value?.user_role.includes("Admin")) {
    filtrationCriteria.value["customer"] =
      localStorage.getItem("selectedCustomerId");
  }
  const response = await getBankAccountsList({
    page: serverOptions.value.page,
    page_size: serverOptions.value.rowsPerPage,
    ordering: sortDirection + sortBy,
    customer_accounts_only: true,
    ...filtrationCriteria.value,
  });

  bankAccountsList.value = [...response.results];
  bankAccountsListTotalCount.value = response.count;

  loading.value = false;
};

const { showDialogAndWaitChoice } = useMazDialogPromise();
const deletionDialogMessage = ref("");

const onBulkDeleteBankAccounts = async () => {
  try {
    deletionDialogMessage.value = t("Suppliers.AreYouSureYouWantToDelete_64", {
      bankAccountsCount: selectedBankAccounts.value.length,
    });
    await showDialogAndWaitChoice("deleteBankAccountDialog");

    const selectedBankAccountsIds = selectedBankAccounts.value.map(
      (bankAccount) => bankAccount.id
    );

    await bulkDeleteBankAccounts(selectedBankAccountsIds);
    await getBankAccountsTableList();

    $toast.open({
      message: `All selected bank accounts have been deleted successfully.`,
      type: "success",
    });

    selectedBankAccounts.value = [];
  } catch (e) {
    e;
  }
};

const onDeleteBankAccount = async (
  bankAccountId: number,
  bankAccountName: string
) => {
  try {
    deletionDialogMessage.value = t("Suppliers.AreYouSureYouWantToDelete_64", {
      bankAccountsCount: "this",
    });
    await showDialogAndWaitChoice("deleteBankAccountDialog");

    await deleteBankAccount(bankAccountId);
    await getBankAccountsTableList();

    $toast.open({
      message: `Bank Account (${bankAccountName}) is deleted successfully.`,
      type: "success",
    });
  } catch (e) {
    e;
  }
};

const filtrationCriteria = ref({});

const showBankNameFilter = ref(false);
const bankNameCriteria = ref("");

const showOwnerNameFilter = ref(false);
const ownerNameCriteria = ref("");

const showCustomerNameFilter = ref(false);
const customerNameCriteria = ref("");

const showSupplierNameFilter = ref(false);
const supplierNameCriteria = ref("");

const showCreatedOnFilter = ref(false);

const creationDateRange = ref();
const formatDate = (date: Date[]) => {
  const [startDate, endDate] = date;
  const startDateDetails = {
    day: startDate.getDate(),
    month: startDate.getMonth() + 1,
    year: startDate.getFullYear(),
  };
  const endDateDetails = {
    day: endDate ? endDate.getDate() : "",
    month: endDate ? endDate.getMonth() + 1 : "",
    year: endDate ? endDate.getFullYear() : "",
  };

  return `${startDateDetails.day}.${startDateDetails.month}.${startDateDetails.year} to ${endDateDetails.day}.${endDateDetails.month}.${endDateDetails.year}`;
};

watch(creationDateRange, async (dateRange) => {
  if (dateRange) {
    filtrationCriteria.value["created_after"] = dateRange[0]
      .toISOString()
      .split("T")[0];
    filtrationCriteria.value["created_before"] = dateRange[1]
      .toISOString()
      .split("T")[0];
  } else {
    filtrationCriteria.value["created_after"] = null;
    filtrationCriteria.value["created_before"] = null;
  }

  await getBankAccountsTableList();
});

const filterBankAccountsList = debounce(async function (
  filterCriteria: string,
  criteriaValue: string
) {
  filtrationCriteria.value[filterCriteria] = criteriaValue;

  await getBankAccountsTableList();
},
300);

const exportAllBankAccounts = async (fileFormat: "csv" | "xlsx") => {
  const fileUrl = await exportBankAccountsList(fileFormat, {
    export_type: "customers",
    customer: null,
  });

  const link = document.createElement("a");
  link.href = fileUrl;
  link.click();

  $toast.open({
    message: `All bank accounts are exported successfully.`,
    type: "success",
  });
};

const showBulkImportBankAccountsModal = ref(false);

const bankAccountsCsvTemplate = ref("");
const bankAccountsXlsxTemplate = ref("");

const { validateTypeAndExtension } = useMasterDataFileValidation();
const isUploadingBankAccountsFile = ref(false);
const bankAccountsFileWithErrors = ref("");

const bulkImportBankAccounts = () => {
  showBulkImportBankAccountsModal.value = true;
  bankAccountsFileWithErrors.value = "";
};

const onBankAccountFileUpload = async (bankAccountsFile: File) => {
  if (validateTypeAndExtension(bankAccountsFile)) {
    try {
      bankAccountsFileWithErrors.value = "";
      isUploadingBankAccountsFile.value = true;
      const uploadingResult = await uploadBankAccountsFile(
        bankAccountsFile,
        "customers"
      );
      isUploadingBankAccountsFile.value = false;

      if (uploadingResult.success) {
        $toast.open({
          message: `Bank accounts are imported successfully.`,
          type: "success",
        });

        showBulkImportBankAccountsModal.value = false;
        await getBankAccountsTableList();
      } else {
        bankAccountsFileWithErrors.value = uploadingResult.file;
      }
    } catch (e) {
      e;
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables/variables";
.upload-bank-accounts-file {
  margin-bottom: 2rem;

  label[for="uploadSuppliersFile"].disabled {
    opacity: 0.4;
    pointer-events: none;
    cursor: not-allowed;
  }
}

.uploading-indicator {
  margin-bottom: 2rem;
}

.file-errors {
  color: $base-dark-red;
}
</style>
