<template>
  <div class="list-notifications-page">
    <h4 class="page-header">{{ $t("Sidebar.NotificationCenter_13") }}</h4>
    <div class="list-table">
      <!-- Tabs -->
      <ul class="orders-tabs">
        <li :class="!$route.query.status ? 'active' : ''">
          <router-link
            :to="{ name: 'notification-center' }"
            class="dropdown-item"
          >
            {{ $t("Notifications.UnreadNotification_11") }}
          </router-link>
        </li>
        <li :class="$route.query.status === 'read' ? 'active' : ''">
          <router-link
            :to="{ name: 'notification-center', query: { status: 'read' } }"
            class="dropdown-item"
          >
            {{ $t("Notifications.ReadNotification_10") }}
          </router-link>
        </li>
      </ul>

      <div class="list-header">
        <div class="list-header__text"></div>

        <div class="list-header__actions">
          <input
            type="text"
            class="form-control"
            :placeholder="$t('Users.Search_28')"
            @input="searchNotificationsList(($event as any).target.value)"
          />

          <button class="btn btn-primary" @click="markAllAsRead">
            <span>{{ $t("Notifications.MarkAllAsRead_2") }}</span>
          </button>
        </div>
      </div>

      <ul class="list-items">
        <template v-if="notificationsListInPage.length">
          <li
            v-for="notification in notificationsListInPage"
            :key="notification.id"
            class="notification-item"
            @click="performNotificationAction(notification)"
          >
            <div
              class="notification-icon"
              :class="{
                'not-read': !notification.read,
              }"
            >
              <i class="icon-document"></i>
            </div>

            <div class="notification-info">
              <span class="title" :title="notification.title">
                {{ notification.title }}
              </span>

              <span class="content" :title="notification.text">
                {{ notification.text }}
              </span>
            </div>

            <div class="notification-action">
              <span class="time">
                {{ moment(notification.created_on).fromNow() }}
              </span>

              <div v-if="!notification.read" class="mark-as-read">
                <a @click.stop="markAsRead(notification.id)">
                  {{ $t("Notifications.MarkAsRead_3") }}
                </a>
              </div>
            </div>
          </li>
        </template>

        <template v-else>
          <li>
            <span class="d-flex mt-2 justify-content-center">
              {{ $t("Notifications.NoNotifications_7") }}
            </span>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Notification } from "@/_helpers/interfaces/notifications/notification.interface";
import { useNotificationsStore } from "@/stores/notifications.store";
import { debounce } from "lodash";
import moment from "moment";
import { storeToRefs } from "pinia";
import { onMounted, watch } from "vue";
import { useRouter } from "vue-router";

const { notificationsListInPage } = storeToRefs(useNotificationsStore());
const { getNotificationsList, markAsRead, markAllAsRead } =
  useNotificationsStore();
const router = useRouter();

onMounted(async () => {
  await getNotificationsPageList();
});

const getNotificationsPageList = async (searchTerm?: string) => {
  const response = await getNotificationsList({
    page: 1,
    page_size: -1,
    ordering: "-created_on",
    search: searchTerm,
    read: router.currentRoute.value.query.status === "read" ? true : false,
  });

  notificationsListInPage.value = [...response.results];
};

const performNotificationAction = async (notification: Notification) => {
  await markAsRead(notification.id, false);
  // remove the notification from the unread list
  notificationsListInPage.value = notificationsListInPage.value.filter(
    (n) => n.id !== notification.id
  );

  const routeName =
    notification.object_ct === "purchaseorder"
      ? "order"
      : notification.object_ct === "payment"
      ? "payment"
      : notification.object_ct === "recurringinstruction"
      ? "recurring-order"
      : "";

  if (routeName)
    router.push({
      name: routeName,
      params: {
        id: notification.object_id,
      },
    });
};

const searchNotificationsList = debounce(async function (searchTerm: string) {
  await getNotificationsPageList(searchTerm);
}, 300);

watch(
  () => router.currentRoute.value.query.status,
  async (newVal) => {
    await getNotificationsPageList();
  }
);
</script>

<style scoped lang="scss">
@import "./notification-center.scss";
</style>
