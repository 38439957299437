<template>
  <!-- Delete Notes -->
  <div class="generic-popup" v-if="isNoteDeletePopup">
    <div class="popup-wrapper">
      <div class="popup-header">
        <h4 class="popup-title">{{ $t("Order.DeleteOrder_74") }}</h4>
        <span class="popup-close" @click="closePopup">
          <i class="icon-close"></i>
        </span>
      </div>
      <div class="popup-body">
        <h5>{{ $t("Order.DeletePopupTitle_81") }} ?</h5>
        <div class="options">
          <button class="save" @click="onChangeNoteDelete">
            {{ $t("Order.DeletePopup_83") }}
          </button>
          <button class="cancel" @click="closePopup">
            {{ $t("Order.CancelPopup_82") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Self Notes Popup -->
  <div class="generic-popup" v-if="isNotesPopup">
    <div class="popup-wrapper">
      <div class="popup-header">
        <h4 class="popup-title">{{ $t("Order.AddSelfNote_129") }}</h4>
        <span class="popup-close" @click="closePopup">
          <i class="icon-close"></i>
        </span>
      </div>
      <div class="popup-body">
        <form @submit.prevent="isEditNote ? editNote() : addSelfNote()">
          <div class="form-input m-0">
            <div class="textarea-wrapper">
              <textarea
                v-model="selfNote"
                class="border w-100 px-2 py-3 rounded"
                :placeholder="$t('Order.WriteYourNote_130')"
                rows="6"
              ></textarea>
            </div>
          </div>
          <button type="submit" class="btn btn-primary mt-3 ms-auto">
            {{ isEditNote ? $t("Order.EditNote_131") : $t("Order.AddNote_70") }}
          </button>
        </form>
      </div>
    </div>
  </div>
  <div class="form-wrapper mb-4">
    <div class="generic-form">
      <div class="row mb-4">
        <div class="col-md-6">
          {{ $t("Order.SelfNotes_69") }}
        </div>
        <div class="col-md-6 text-end">
          <button class="button-gray" @click="openSelfNotePopup()">
            <i class="icon-documents-outline"></i>
            <span> {{ $t("Order.AddNote_70") }} </span>
          </button>
        </div>
      </div>
      <div class="text-center" v-if="isLoadingNotes">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only"></span>
        </div>
      </div>
      <div v-else>
        <ul class="self-notes" v-if="selfNotes?.length > 0">
          <li v-for="(item, index) in selfNotes" :key="index">
            <div class="note">
              <div class="header">
                <div class="user">
                  <div class="image">
                    <img
                      :src="item.author.profile_picture"
                      alt="avatar"
                      class="rounded-circle"
                      v-if="item.author.profile_picture"
                    />
                    <img
                      src="/src/assets/images/user-avatar.jpg"
                      alt="avatar"
                      class="rounded-circle"
                      v-else
                    />
                  </div>
                  <div class="detail">
                    <div class="name">{{ item.author?.display_name }}</div>
                    <div class="date">
                      {{ moment(item.created_on).format("DD.MM.YYYY") }}
                    </div>
                  </div>
                </div>
                <div class="note-actions">
                  <button>
                    <i class="icon-edit" @click="OpenEditNotePopup(item)"></i>
                  </button>
                  <button>
                    <i class="icon-delete" @click="deleteNote(item)"></i>
                  </button>
                </div>
              </div>
              <div class="body">
                <p>
                  {{ item.text }}
                </p>
              </div>
            </div>
          </li>
        </ul>
        <div v-else class="text-center">
          <h5>No self note present</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import moment from "moment";
import { useOrderStore } from "@/stores/order.store";
import { useToast } from "vue-toast-notification";
import { useRouter } from "vue-router";
import { useRecurringInstructionStore } from "@/stores/recurring-instructions.store";

const { getOrderNotes, createOrderNotes, editOrderNotes, deleteOrderNotes } =
  useOrderStore();
const {
  getRecurringInstructionSelfNotes,
  createRecurringInstructionSelfNote,
  editRecurringInstructionSelfNote,
  deleteRecurringInstructionSelfNote,
} = useRecurringInstructionStore();

const router = useRouter();

const isNotesPopup = ref(false);

const orderId: any = ref(null);

const isLoadingNotes = ref(false);

let selfNotes: any = ref([]);

const isNoteDeletePopup = ref(false);

const isEditNote = ref(false);

let selfNote: any = ref("");

const noteId = ref(null);

const $toast = useToast();

const props = defineProps({
  order: {
    type: Object,
    default: () => {},
  },
  recurringInstruction: {
    type: Object,
    default: () => {},
  },
});

const openSelfNotePopup = () => {
  isNotesPopup.value = true;
};

const closePopup = () => {
  isNotesPopup.value = false;
  isNoteDeletePopup.value = false;
};

const getSelfNotes = async () => {
  isLoadingNotes.value = true;
  if (props.recurringInstruction) {
    selfNotes.value = await getRecurringInstructionSelfNotes(orderId.value);
    isLoadingNotes.value = false;
  } else {
    await getOrderNotes(orderId.value).then((response) => {
      isLoadingNotes.value = false;
      selfNotes.value = response.results;
    });
  }
};

const OpenEditNotePopup = (note: any) => {
  isEditNote.value = true;
  isNotesPopup.value = true;
  selfNote.value = note.text;
  noteId.value = note.id;
};

const addSelfNote = async () => {
  if (selfNote.value === "") return;
  if (props.recurringInstruction) {
    await createRecurringInstructionSelfNote(orderId.value, selfNote.value)
      .then(async () => {
        $toast.open({
          message: `Self note created successfully.`,
          type: "success",
        });
        await getSelfNotes();
      })
      .catch((error) => console.log(error));
    selfNote.value = "";
    isNotesPopup.value = false;
    return;
  }
  let obj = {
    order: orderId.value,
    text: selfNote.value,
  };
  await createOrderNotes(obj)
    .then(async () => {
      $toast.open({
        message: `Self note created successfully.`,
        type: "success",
      });
      await getSelfNotes();
    })
    .catch((error) => console.log(error));
  selfNote.value = "";
  isNotesPopup.value = false;
};

const editNote = async () => {
  if (selfNote.value === "") return;
  if (props.recurringInstruction) {
    await editRecurringInstructionSelfNote(
      orderId.value,
      noteId.value,
      selfNote.value
    ).then(async () => {
      $toast.open({
        message: `Self note edit successful.`,
        type: "success",
      });
      await getSelfNotes();
    });
    isNotesPopup.value = false;
    isEditNote.value = false;
    selfNote.value = "";
    return;
  }
  await editOrderNotes(noteId.value, {
    order: orderId.value,
    text: selfNote.value,
  }).then(async () => {
    $toast.open({
      message: `Self note edit successful.`,
      type: "success",
    });
    await getSelfNotes();
  });
  isNotesPopup.value = false;
  isEditNote.value = false;
  selfNote.value = "";
};

const deleteNote = (note: any) => {
  isNoteDeletePopup.value = true;
  noteId.value = note.id;
};

const onChangeNoteDelete = async () => {
  closePopup();
  if (props.recurringInstruction) {
    await deleteRecurringInstructionSelfNote(orderId.value, noteId.value)
      .then(async () => {
        $toast.open({
          message: `Self note deleted.`,
          type: "success",
        });
        await getSelfNotes();
      })
      .catch((error) => console.log(error));
    return;
  }

  await deleteOrderNotes(noteId.value)
    .then(async () => {
      $toast.open({
        message: `Self note deleted.`,
        type: "success",
      });
      await getSelfNotes();
    })
    .catch((error) => console.log(error));
};

onMounted(async () => {
  orderId.value = +router.currentRoute.value.params.id;
  /* Get Order Self Notes */
  await getSelfNotes();
});
</script>

<style lang="scss" scoped>
@import "./self-notes.scss";
</style>
