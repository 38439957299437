<template>
  <div class="tw-w-10/12 tw-bg-white tw-rounded-md tw-shadow-sm">
    <p
      class="tw-text-xl tw-leading-7 tw-my-2 tw-ml-4 tw-font-light tw-text-[#333333]"
    >
      <slot name="title"></slot>
    </p>
    <ChartSkeleton v-if="isLoading" />
    <div
      v-else-if="isSeriesEmpty"
      class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-[80%] tw-gap-0"
    >
      <noDataIllustration class="tw-w-full tw-h-auto tw-max-w-[240px]" />
      <p class="tw-mt-2 tw-text-sm tw-text-gray-600">
        {{ $t("Statistics.NoDataToShow_23") }}
      </p>
    </div>
    <apexchart
      :key="chartKey"
      v-else
      type="area"
      height="200"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script setup lang="ts">
import { ref, type Ref, watch, computed } from "vue";
import type { ApexChartSeries } from "../types";
import ChartSkeleton from "./ChartSkeleton.vue";
import noDataIllustration from "@/assets/images/no-data.svg";
import { useFormatValue } from "@/_helpers/composables/formatting.composable";

const { formatCurrency } = useFormatValue();

const chartKey = ref(0);

const props = defineProps<{
  series: Array<ApexChartSeries>;
  xaxis: Array<string>;
  dark?: boolean;
  isLoading?: boolean;
  isMoney?: boolean;
}>();

const chartSeries: Ref<Array<{ name: string; data: number[] }>> = ref([]);

const chartOptions = ref<any>({
  chart: {
    type: "area",
    height: 200,
    fontFamily: "Nunito, sans-serif",
    toolbar: {
      show: false,
    },
  },
  fill: {
    type: "solid",
    colors: props.dark ? ["#2B2D42"] : ["#CEEC34"],
  },
  grid: {
    show: false,
  },
  legend: {
    show: false,
  },
  colors: props.dark ? ["#2B2D42"] : ["#CEEC34"],
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "straight",
    width: 0,
  },
  markers: {
    size: 3,
    colors: ["#E93A57"],
  },
  xaxis: {
    categories: [],
    labels: {
      style: {
        colors: "#6E6E6E",
      },
    },
    axisBorder: {
      show: true,
      color: "#B3B3B3",
    },
    axisTicks: {
      show: true,
      color: "#B3B3B3",
      width: 4,
      offsetX: 0,
      offsetY: 0,
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: "#6E6E6E",
      },
      formatter: (value: number) =>
        props.isMoney ? formatCurrency(value) : value,
    },
    axisBorder: {
      show: true,
      color: "#B3B3B3",
    },
    axisTicks: {
      show: true,
      color: "#B3B3B3",
      width: 4,
      offsetX: 0,
      offsetY: 0,
    },
  },
  tooltip: {
    y: {
      formatter: (value: number | string) => {
        return props.isMoney
          ? formatCurrency(Number(value))
          : Number(value).toString();
      },
    },
  },
});

const isSeriesEmpty = computed(() => {
  return props.series.every((s) =>
    s.data.every((value) => parseInt(String(value)) === 0)
  );
});

watch(
  props,
  (newProps) => {
    chartOptions.value.xaxis.categories = newProps.xaxis;
    chartSeries.value = newProps.series.map((series) => ({
      name: series.name,
      data: series.data.map(Number),
    }));
    chartKey.value++;
  },
  { deep: true }
);
</script>
