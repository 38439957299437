<template>
  <div class="list-users-page">
    <h4 class="page-header">
      <span>{{ $t("Users.UsersList_13") }}</span>

      <LegacyMultiSelect
        v-model="selectedCustomer"
        mode="single"
        :options="filterCustomersList"
        :filter-results="false"
        :resolve-on-load="true"
        :delay="200"
        :min-chars="0"
        :searchable="true"
        valueProp="id"
        label="name"
        :placeholder="$t('Users.ChooseCustomer_7')"
        :clearOnBlur="false"
      />
    </h4>

    <ListUsersTable :customerId="selectedCustomer" />
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import ListUsersTable from "./ListUsersTable.vue";
import { useCustomerStore } from "@/stores/customer.store";

const selectedCustomer = ref<number | null>(null);
const { getCustomersList } = useCustomerStore();

const filterCustomersList = async (query: string) => {
  const { results } = await getCustomersList({
    name: query,
    page_size: 200,
  });

  return results as Array<any>;
};
</script>

<style scoped lang="scss">
.page-header {
  display: flex;
  align-items: center;
  gap: 1rem;

  .multiselect {
    width: 350px;
    margin: 0;
  }
}
</style>
