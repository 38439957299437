<template>
  <form class="generic-form" @submit.prevent="onSubmit">
    <div class="form-group mt-4">
      <h4 class="group-title">
        {{ $t("CustomerManagement.LegalsAndTerms_35") }}
      </h4>
      <div class="row justify-content-between">
        <div class="col-md-5">
          <div class="form-input">
            <label class="form-label">{{
              $t("CustomerManagement.TypeLegalNote_36")
            }}</label>
            <div class="textarea-wrapper">
              <textarea
                v-model.trim="formData.legal_notes"
                :placeholder="$t('CustomerManagement.LegalNotesGoesHere_38')"
                maxlength="2000"
                rows="6"
              ></textarea>
              <span class="counter"
                >{{
                  formData.legal_notes ? formData.legal_notes.length : 0
                }}/2000</span
              >
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <div class="form-input">
            <label class="form-label">{{
              $t("CustomerManagement.TypeTermsAndConditions_37")
            }}</label>
            <div class="textarea-wrapper">
              <textarea
                v-model.trim="formData.terms_and_conditions"
                :placeholder="$t('CustomerManagement.TermsGoesHere_39')"
                maxlength="2000"
                rows="6"
              ></textarea>
              <span class="counter"
                >{{
                  formData.terms_and_conditions
                    ? formData.terms_and_conditions.length
                    : 0
                }}/2000</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div v-if="isCreatedSuccessfully != null">
        <div
          v-if="isCreatedSuccessfully"
          class="col-md-5 alert alert-success"
          role="alert"
        >
          {{
            isEditMode
              ? $t("CustomerManagement.CustomerEditedSuccessfully_95")
              : $t("CustomerManagement.CustomerCreatedSuccessfully_82")
          }}
        </div>
        <div v-else class="col-md-5 alert alert-danger" role="alert">
          {{ errorMessage }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="form-options">
          <router-link :to="{ name: 'customers' }" class="cancel-btn">{{
            $t("CustomerManagement.CancelDiscard_69")
          }}</router-link>
          <button
            v-if="isPrevious && isEditMode"
            @click="onPrevious"
            class="previous"
            type="submit"
          >
            {{ $t("CustomerManagement.Previous_88") }}
          </button>
          <button class="submit-btn" type="submit">
            {{
              isEditMode
                ? $t("CustomerManagement.EditCustomer_93")
                : $t("CustomerManagement.CreateCustomer_54")
            }}
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
import { ref, reactive, onMounted, type Ref } from "vue";
import router from "@/router";
import { useCustomerStore } from "@/stores/customer.store";
const { createLegal, getLegalById } = useCustomerStore();

const props = defineProps<{
  customerId: number;
  isEditMode: string;
  isPrevious: boolean;
}>();

const isEditMode: any = JSON.parse(props.isEditMode);

const formData: any = reactive({
  legal_notes: "",
  terms_and_conditions: "",
});

const isCreatedSuccessfully: Ref<boolean | null> = ref(null);
const errorMessage = ref("");

const onSubmit = async () => {
  try {
    await createLegal(formData, props.customerId);
    isCreatedSuccessfully.value = true;

    if (isEditMode) {
      setTimeout(() => {
        router.push({ name: "customers" });
      }, 2000);
    }
  } catch (error: any) {
    isCreatedSuccessfully.value = false;
    errorMessage.value = error.response.data.errors[0].detail;
  }
};

const getCustomerLegalById = async () => {
  try {
    let data = await getLegalById(props.customerId);
    Object.keys(formData).forEach((formDataItem) => {
      Object.keys(data).forEach((responseItem) => {
        if (formDataItem == responseItem) {
          formData[formDataItem] = data[responseItem];
        }
      });
    });
  } catch (e) {
    console.log(e);
  }
};

const onPrevious = () => {
  let prevBtn: any = document.querySelector(".fw-footer-left button");
  prevBtn.click();
};

onMounted(async () => {
  if (isEditMode) {
    await getCustomerLegalById();
  }
});
</script>
