<template>
  <div>
    <div class="form-group row">
      <div class="col-sm-10 col-md-6 col-lg-6 my-1">
        <input
          type="text"
          class="form-control"
          id="order_number"
          :placeholder="$t('Order.OrderNo_75')"
          v-model="currentFilter.order_number"
          :disabled="isDisabled(FIELDS.ORDER_NUMBER)"
          :hidden="isHidden(FIELDS.ORDER_NUMBER)"
        />
      </div>

      <div class="col-sm-10 col-md-6 col-lg-6 my-1">
        <input
          type="text"
          class="form-control"
          id="supplier"
          :placeholder="$t('Order.Supplier_31')"
          v-model="currentFilter.supplier_name"
          :disabled="isDisabled(FIELDS.SUPPLIER)"
          :hidden="isHidden(FIELDS.SUPPLIER)"
        />
      </div>

      <div class="col-sm-10 col-md-6 col-lg-6 my-1">
        <input
          type="text"
          class="form-control"
          id="document_no_internal"
          :placeholder="$t('Order.DocumentNo_112')"
          v-model="currentFilter.document_no_internal"
          :disabled="isDisabled(FIELDS.DOCUMENT_NO_INTERNAL)"
          :hidden="isHidden(FIELDS.DOCUMENT_NO_INTERNAL)"
        />
      </div>

      <div class="col-sm-10 col-md-6 col-lg-6 my-1">
        <input
          type="text"
          class="form-control"
          id="order_name"
          :placeholder="$t('Order.ProjectNo_146')"
          v-model="currentFilter.project_no"
          :disabled="isDisabled(FIELDS.PROJECT_NO)"
          :hidden="isHidden(FIELDS.PROJECT_NO)"
        />
      </div>

      <div class="col-sm-10 col-md-6 col-lg-6 my-1">
        <input
          type="text"
          class="form-control"
          id="released_by"
          :placeholder="$t('Order.ReleasedBy_78')"
          v-model="currentFilter.released_by"
          :disabled="isDisabled(FIELDS.RELEASED_BY)"
          :hidden="isHidden(FIELDS.RELEASED_BY)"
        />
      </div>
      <div class="col-sm-10 col-md-6 col-lg-6 my-1">
        <input
          type="text"
          class="form-control"
          id="created_by"
          :placeholder="$t('Order.CreatedBy_140')"
          v-model="currentFilter.created_by"
          :disabled="isDisabled(FIELDS.CREATED_BY)"
          :hidden="isHidden(FIELDS.CREATED_BY)"
        />
      </div>

      <div class="col-sm-10 col-md-6 col-lg-6 my-1">
        <div class="input-group mb-3">
          <input
            type="number"
            v-model.number="currentFilter.current_balance_min"
            class="form-control"
            :placeholder="$t('Order.CurrentBalanceMin_157')"
            :disabled="isDisabled(FIELDS.CURRENT_BALANCE)"
            :hidden="isHidden(FIELDS.CURRENT_BALANCE)"
          />
          <span class="input-group-text">:</span>
          <input
            type="number"
            v-model.number="currentFilter.current_balance_max"
            class="form-control"
            :placeholder="$t('Order.CurrentBalanceMax_158')"
            :disabled="isDisabled(FIELDS.CURRENT_BALANCE)"
            :hidden="isHidden(FIELDS.CURRENT_BALANCE)"
          />
        </div>
      </div>

      <div class="col-sm-10 col-md-6 col-lg-6 my-1">
        <div class="input-group mb-3">
          <input
            type="number"
            v-model.number="currentFilter.amount_min"
            class="form-control"
            :placeholder="$t('Order.GrossEurMin_159')"
            :disabled="isDisabled(FIELDS.AMOUNT)"
          />
          <span class="input-group-text">:</span>
          <input
            type="number"
            v-model.number="currentFilter.amount_max"
            class="form-control"
            :placeholder="$t('Order.GrossEurMax_160')"
            :disabled="isDisabled(FIELDS.AMOUNT)"
            :hidden="isHidden(FIELDS.AMOUNT)"
          />
        </div>
      </div>

      <div class="col-sm-10 col-md-6 col-lg-6 my-1">
        <VueDatePicker
          :placeholder="$t('Order.CreatedOn_139')"
          :enable-time-picker="false"
          range
          :select-text="$t('Order.Apply_50')"
          auto-apply
          :preset-ranges="presetRanges"
          :format="formatDate"
          v-model="createdOnRange"
          :disabled="isDisabled(FIELDS.CREATED_ON)"
          :hidden="isHidden(FIELDS.CREATED_ON)"
        >
          <template #yearly="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #last_year="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #last_month="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #this_month="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #last_week="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #last_30_days="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
        </VueDatePicker>
      </div>

      <div class="col-sm-10 col-md-6 col-lg-6 my-1">
        <VueDatePicker
          :placeholder="$t('Order.ServiceDate_30')"
          :enable-time-picker="false"
          range
          :select-text="$t('Order.Apply_50')"
          auto-apply
          :preset-ranges="presetRanges"
          :format="formatDate"
          v-model="serviceDateRange"
          :disabled="isDisabled(FIELDS.SERVICE_DATE)"
          :hidden="isHidden(FIELDS.SERVICE_DATE)"
        >
          <template #yearly="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #last_year="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #last_month="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #this_month="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #last_week="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #last_30_days="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
        </VueDatePicker>
      </div>

      <div
        class="col-sm-10 col-md-12 col-lg-12 my-1"
        v-if="!isHidden(FIELDS.CLARIFICATION_REQUESTED_ONLY)"
      >
        <MazCheckbox
          v-model="currentFilter.clarification_requested_only"
          size="mini"
          color="success"
          :disabled="isDisabled(FIELDS.CLARIFICATION_REQUESTED_ONLY)"
        >
          {{ $t("Order.ShowClarificationRequestedOnly_144") }}
        </MazCheckbox>
      </div>
      <div
        class="col-sm-10 col-md-12 col-lg-12 my-1"
        v-if="!isHidden(FIELDS.HIDE_ZERO_BALANCE)"
      >
        <MazCheckbox
          v-model="currentFilter.hide_zero_balance"
          size="mini"
          color="success"
          :disabled="isDisabled(FIELDS.HIDE_ZERO_BALANCE)"
        >
          Hide zero balance orders
        </MazCheckbox>
      </div>
    </div>
    <div style="float: right">
      <button class="btn btn-primary d-inline mx-2 my-2" @click="applyFilter()">
        {{ $t("Global.Apply_16") }}
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import { formatDate, presetRanges } from "@/_helpers/utils/date";
import { storeToRefs } from "pinia";
import { useOrderStore } from "@/stores/order.store";
import moment from "moment/moment";

const FIELDS = {
  ORDER_NUMBER: "order_number",
  SUPPLIER: "supplier_name",
  DOCUMENT_NO_INTERNAL: "document_no_internal",
  PROJECT_NO: "project_no",
  RELEASED_BY: "released_by",
  CREATED_BY: "created_by",
  CURRENT_BALANCE: "current_balance",
  AMOUNT: "amount",
  CREATED_ON: "created_on",
  SERVICE_DATE: "service_date",
  CLARIFICATION_REQUESTED_ONLY: "clarification_requested_only",
  HIDE_ZERO_BALANCE: "hide_zero_balance",
};

const props = defineProps({
  disabledFields: {
    type: Array,
    default: () => [],
  },
  hiddenFields: {
    type: Array,
    default: () => [],
  },
  useLocalStore: {
    type: Boolean,
    default: false,
  },
  initialFilter: {
    type: Object,
    default: () => ({}),
  },
});

const isDisabled = (field: string) => {
  return props.disabledFields.includes(field);
};
const isHidden = (field: string) => {
  return props.hiddenFields.includes(field);
};

const emit = defineEmits(["apply"]);

const { OrdersOptions } = storeToRefs(useOrderStore());
const { setOptions } = useOrderStore();
const currentFilter = ref({
  ordering: "",
  page: "",
  page_size: "",
  project: "",
  customer: "",
  created_after: "",
  created_before: "",
  service_date_after: "",
  service_date_before: "",
  status: "",
  order_number: "",
  clarification_requested_only: false,
  hide_zero_balance: false,
  supplier_name: "",
  document_no_internal: "",
  project_no: "",
  current_balance_max: "",
  current_balance_min: "",
  amount_max: "",
  amount_min: "",
  created_by: "",
  released_by: "",
});

const applyFilter = () => {
  if (!props.useLocalStore) {
    setOptions(currentFilter.value);
  }
  emit("apply", currentFilter.value);
};

const createdOnRange: any = ref(null);
const serviceDateRange: any = ref(null);

onMounted(() => {
  if (props.useLocalStore) {
    currentFilter.value = {
      amount_max: "",
      amount_min: "",
      clarification_requested_only: false,
      created_after: "",
      created_before: "",
      created_by: "",
      current_balance_max: "",
      current_balance_min: "",
      customer: "",
      document_no_internal: "",
      hide_zero_balance: false,
      order_number: "",
      ordering: "",
      page: "",
      page_size: "",
      project: "",
      project_no: "",
      released_by: "",
      service_date_after: "",
      service_date_before: "",
      status: "",
      supplier_name: "",
      ...props.initialFilter,
    };
  } else {
    currentFilter.value = { ...OrdersOptions.value };
  }

  createdOnRange.value = [
    currentFilter.value.created_after
      ? moment(currentFilter.value.created_after)
      : "",
    currentFilter.value.created_before
      ? moment(currentFilter.value.created_before)
      : "",
  ];
  serviceDateRange.value = [
    currentFilter.value.service_date_after
      ? moment(currentFilter.value.service_date_after)
      : "",
    currentFilter.value.service_date_before
      ? moment(currentFilter.value.service_date_before)
      : "",
  ];
});

watch(createdOnRange, (newVal) => {
  if (newVal) {
    currentFilter.value.created_after = newVal[0]
      ? moment(newVal[0]).format("YYYY-MM-DD")
      : "";
    currentFilter.value.created_before = newVal[1]
      ? moment(newVal[1]).format("YYYY-MM-DD")
      : "";
  } else {
    currentFilter.value.created_after = "";
    currentFilter.value.created_before = "";
  }
});

watch(serviceDateRange, (newVal) => {
  if (newVal) {
    currentFilter.value.service_date_after = newVal[0]
      ? moment(newVal[0]).format("YYYY-MM-DD")
      : "";
    currentFilter.value.service_date_before = newVal[1]
      ? moment(newVal[1]).format("YYYY-MM-DD")
      : "";
  } else {
    currentFilter.value.service_date_after = "";
    currentFilter.value.service_date_before = "";
  }
});
</script>
