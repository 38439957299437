<template>
  <div class="customer">
    <h1 class="page-title">
      {{
        isEditMode
          ? $t("CustomerManagement.EditProject_47")
          : $t("CustomerManagement.CreateProject_47")
      }}
    </h1>
    <div class="form-wrapper">
      <form class="generic-form" @submit.prevent="onSubmit">
        <div class="row justify-content-between">
          <div class="col-lg-5 col-md-6">
            <div class="form-input">
              <label class="form-label">{{
                $t("CustomerManagement.ChooseCustomer_41")
              }}</label>
              <LegacyMultiSelect
                v-model.trim="$v.customer.$model"
                name="customers"
                :object="false"
                :options="customersList"
                :resolve-on-load="true"
                :min-chars="0"
                :placeholder="$t('CustomerManagement.SelectCustomer_42')"
                :closeOnSelect="true"
                :clearOnBlur="false"
                :searchable="true"
                valueProp="id"
                label="name"
              />
              <!--  -->
              <span v-if="$v.customer.$error" class="form__error-msg">{{
                $t("CustomerManagement.CustomerRequired_48")
              }}</span>
            </div>
          </div>
          <div class="col-lg-5 col-md-6">
            <div class="form-input">
              <label class="form-label">{{
                $t("CustomerManagement.ProjectName_43")
              }}</label>
              <input
                v-model.trim="$v.name.$model"
                :class="{ error: $v.name.$error }"
                type="text"
                :placeholder="$t('CustomerManagement.TypeName_15')"
              />
              <span v-if="$v.name.$error" class="form__error-msg">{{
                $t("CustomerManagement.ProjectNameRequired_49")
              }}</span>
            </div>
          </div>
          <div class="col-lg-5 col-md-6">
            <div class="form-input">
              <label class="form-label">{{
                $t("CustomerManagement.SetProjectLimit_44")
              }}</label>
              <CurrencyInput
                v-model.trim="$v.project_limit.$model"
                :errorClass="{
                  error: $v.project_limit.$error,
                }"
                :placeholder="$t('CustomerManagement.TypeLimit_16')"
              />
              <span v-if="$v.project_limit.$error" class="form__error-msg">{{
                $t("CustomerManagement.ProjectLimitRequired_50")
              }}</span>
            </div>
          </div>
          <div class="col-lg-5 col-md-6">
            <div class="form-input">
              <label class="form-label">{{
                $t("CustomerManagement.EnterAutomaticApproval_45")
              }}</label>
              <CurrencyInput
                v-model.trim="$v.auto_approval_limit.$model"
                :errorClass="{
                  error: $v.auto_approval_limit.$error,
                }"
                :placeholder="$t('CustomerManagement.TypeLimit_16')"
              />
              <span
                v-if="$v.auto_approval_limit.$error"
                class="form__error-msg"
                >{{
                  $t("CustomerManagement.AutomaticApprovalLimitRequired_51")
                }}</span
              >
            </div>
          </div>
          <div class="col-lg-5 col-md-6">
            <div class="form-input">
              <label class="form-label">{{
                $t("CustomerManagement.ProjectLimitFrequency_46")
              }}</label>
              <select
                v-model="formData.project_limit_frequency"
                class="form-select"
              >
                <option value="" selected disabled>
                  {{ $t("CustomerManagement.SelectLimit_52") }}
                </option>
                <option value="NONE">
                  {{ $t("CustomerManagement.None_40") }}
                </option>
                <option value="WEEKLY">
                  {{ $t("CustomerManagement.Weekly_21") }}
                </option>
                <option value="MONTHLY">
                  {{ $t("CustomerManagement.Monthly_22") }}
                </option>
                <option value="YEARLY">
                  {{ $t("CustomerManagement.Yearly_23") }}
                </option>
                <option value="ALL_TIME">
                  {{ $t("CustomerManagement.AllTime_24") }}
                </option>
              </select>
            </div>
          </div>
          <div v-if="isCreatedSuccessfully != null">
            <div
              v-if="isCreatedSuccessfully"
              class="col-md-5 alert alert-success"
              role="alert"
            >
              {{
                isEditMode
                  ? $t("CustomerManagement.ProjectEditedSuccessfully_96")
                  : $t("CustomerManagement.ProjectCreatedSuccessfully_83")
              }}
            </div>
            <div v-else class="col-md-5 alert alert-danger" role="alert">
              {{ errorMessage }}
            </div>
          </div>
          <div class="col-12">
            <div class="form-options">
              <router-link :to="{ name: 'projects' }" class="cancel-btn">
                {{ $t("CustomerManagement.CancelDiscard_69") }}</router-link
              >
              <button class="submit-btn" type="submit">
                {{
                  isEditMode
                    ? $t("CustomerManagement.EditProject_94")
                    : $t("CustomerManagement.AddProject_53")
                }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import CurrencyInput from "@/components/shared/currency-input/CurrencyInput.vue";

import { ref, reactive, onMounted, type Ref } from "vue";
import router from "@/router";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useCustomerStore } from "@/stores/customer.store";
import { useProjectStore } from "@/stores/project.store";
import Multiselect from "@vueform/multiselect";

const { createProject, getProjectById, updateProject } = useProjectStore();
const { getCustomersOptions } = useCustomerStore();

let customersList: any = ref([]);

const formData: any = reactive({
  customer: "",
  name: "",
  project_limit: null,
  auto_approval_limit: null,
  project_limit_frequency: "ALL_TIME",
});

const isCreatedSuccessfully: Ref<boolean | null> = ref(null);
const errorMessage: any = ref("");

let isEditModeValue: any = localStorage.getItem("isEditProject");
isEditModeValue = JSON.parse(isEditModeValue);

let isEditMode: any = ref(isEditModeValue);

let projectIdEdit: any = "";

const rules = {
  customer: {
    required,
  },
  name: {
    required,
  },
  project_limit: {
    required,
  },
  auto_approval_limit: {
    required,
  },
};

const $v = useVuelidate(rules, formData);

const onSubmit = async () => {
  $v.value.$touch();
  if (!$v.value.$invalid) {
    try {
      if (!isEditMode.value) {
        await createProject(formData);
        resetProject();
      } else {
        await updateProject(formData, projectIdEdit);
      }
      isCreatedSuccessfully.value = true;

      if (isEditMode.value) {
        setTimeout(() => {
          router.push({ name: "projects" });
        }, 2000);
      }
    } catch (error: any) {
      isCreatedSuccessfully.value = false;
      errorMessage.value = error.response.data.errors[0].detail;
    }
  }
};

const getCustomerProjectById = async () => {
  try {
    let data = await getProjectById(projectIdEdit);
    Object.keys(formData).forEach((formDataItem) => {
      Object.keys(data).forEach((responseItem) => {
        if (formDataItem == responseItem) {
          formData[formDataItem] = data[responseItem];
        }
      });
    });
  } catch (e) {
    console.log(e);
  }
};

const resetProject = () => {
  formData.customer = "";
  formData.name = "";
  formData.project_limit = null;
  formData.auto_approval_limit = null;
  formData.project_limit_frequency = "ALL_TIME";
  $v.value.$reset();
};

onMounted(async () => {
  customersList.value = await getCustomersOptions();

  projectIdEdit = localStorage.getItem("projectId");

  if (isEditMode.value) {
    await getCustomerProjectById();
  }
});
</script>
