<template>
  <div class="list-suppliers-page">
    <div class="row mb-4">
      <div class="col-auto">
        <span class="page-title">{{ $t("Suppliers.MasterData_21") }}</span>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <div class="form-input mb-lg-0">
          <LegacyMultiSelect
            v-if="currentUser?.user_role.includes('Admin')"
            v-model="supplierFilter.customer"
            name="customers"
            mode="single"
            :options="customersList"
            :resolve-on-load="true"
            valueProp="id"
            label="name"
            :searchable="true"
            :placeholder="$t('Suppliers.SelectCustomer_77')"
            :clearOnBlur="false"
          />
        </div>
      </div>
    </div>
    <div class="list-table">
      <div class="list-header">
        <div class="list-header__text">
          <h5>{{ $t("Suppliers.Suppliers_22") }}</h5>
        </div>

        <div class="list-header__actions">
          <div class="bulk-actions" v-if="selectedSuppliers.length > 1">
            <div class="dropdown">
              <button
                class="btn btn-primary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{ $t("Suppliers.BulkActions_59") }}
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item" @click="onBulkDeleteSuppliers()">
                    {{ $t("Suppliers.DeleteAllSelectedSuppliers_25") }}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <button
            class="btn btn-secondary"
            @click="bulkImportSuppliers"
            v-if="supplierFilter.customer"
          >
            <i class="icon-import-line"></i>
            <span>{{ $t("Suppliers.BulkImport_37") }}</span>
          </button>

          <div class="export-suppliers">
            <div class="dropdown">
              <button
                class="btn btn-secondary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="icon-export-line"></i>
                <span>{{ $t("Suppliers.Export_35") }}</span>
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item" @click="exportAllSuppliers('csv')">
                    {{ $t("Suppliers.DownloadAs_36") }}
                    csv
                  </a>
                </li>

                <li>
                  <a class="dropdown-item" @click="exportAllSuppliers('xlsx')">
                    {{ $t("Suppliers.DownloadAs_36") }}
                    xlsx
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <RouterLink :to="{ name: 'create-supplier' }" class="btn btn-primary">
            <i class="icon-upload"></i>
            <span>{{ $t("Suppliers.NewSuppliers_23") }}</span>
          </RouterLink>
        </div>
      </div>

      <InocuDataTable
        v-model:items-selected="selectedSuppliers"
        v-model:server-options="serverOptions"
        :server-items-length="suppliersListTotalCount"
        :loading="loading"
        :headers="suppliersTableHeaders"
        :items="suppliersList"
        :rows-items="[10, 25, 50, 100]"
        fixed-checkbox
        :checkbox-column-width="60"
        theme-color="#CEEC34"
        table-class-name="listing-custom-table"
        alternating
        buttons-pagination
        no-hover
        must-sort
      >
        <!-- Filtrable header cells -->
        <template #header-supplier_number="header">
          <div class="filter-column" @click.stop>
            <i
              class="icon-filter filter-icon"
              @click.stop="showSupplierNumberFilter = !showSupplierNumberFilter"
            ></i>
            {{ header.text }}
            <div class="filter-menu" v-if="showSupplierNumberFilter">
              <input
                v-model="supplierNumberCriteria"
                type="text"
                class="form-control"
                @input="
                  filterSuppliersList('supplier_number', supplierNumberCriteria)
                "
              />
            </div>
          </div>
        </template>

        <template #header-name="header">
          <div class="filter-column" @click.stop>
            <i
              class="icon-filter filter-icon"
              @click.stop="showNameFilter = !showNameFilter"
            ></i>
            {{ header.text }}
            <div class="filter-menu" v-if="showNameFilter">
              <input
                v-model="nameCriteria"
                type="text"
                class="form-control"
                @input="filterSuppliersList('name', nameCriteria)"
              />
            </div>
          </div>
        </template>

        <template #header-country="header">
          <div class="filter-column" @click.stop>
            <i
              class="icon-filter filter-icon"
              @click.stop="showCountryFilter = !showCountryFilter"
            ></i>
            {{ header.text }}
            <div class="filter-menu" v-if="showCountryFilter">
              <input
                v-model="countryCriteria"
                type="text"
                class="form-control"
                @input="filterSuppliersList('country', countryCriteria)"
              />
            </div>
          </div>
        </template>

        <template #header-city="header">
          <div class="filter-column" @click.stop>
            <i
              class="icon-filter filter-icon"
              @click.stop="showCityFilter = !showCityFilter"
            ></i>
            {{ header.text }}
            <div class="filter-menu" v-if="showCityFilter">
              <input
                v-model="cityCriteria"
                type="text"
                class="form-control"
                @input="filterSuppliersList('city', cityCriteria)"
              />
            </div>
          </div>
        </template>

        <template #header-zip_code="header">
          <div class="filter-column" @click.stop>
            <i
              class="icon-filter filter-icon"
              @click.stop="showZipCodeFilter = !showZipCodeFilter"
            ></i>
            {{ header.text }}
            <div class="filter-menu" v-if="showZipCodeFilter">
              <input
                v-model="zipCodeCriteria"
                type="text"
                name="searchSuppliersName"
                id="searchSuppliersName"
                class="form-control"
                @input="filterSuppliersList('zip_code', zipCodeCriteria)"
              />
            </div>
          </div>
        </template>

        <template #header-created_on="header">
          <div class="filter-column" @click.stop>
            <i
              class="icon-filter filter-icon"
              @click.stop="showCreatedOnFilter = !showCreatedOnFilter"
            ></i>
            {{ header.text }}
            <div class="filter-menu" v-if="showCreatedOnFilter">
              <DatePicker
                v-model="creationDateRange"
                placeholder="Range"
                :clearable="false"
                :month-change-on-scroll="false"
                :format="formatDate"
                :preview-format="formatDate"
                :max-date="new Date()"
                select-text="Apply"
                range
              />

              <button
                class="btn btn-primary mt-2"
                @click="creationDateRange = ''"
              >
                Reset
              </button>
            </div>
          </div>
        </template>

        <!-- Custom cells display -->
        <template #item-created_on="supplier">
          {{ new Date(supplier.created_on).toLocaleDateString("de-DE") }}
        </template>

        <template #item-actions="supplier">
          <div class="actions">
            <router-link
              :to="{
                name: 'edit-supplier',
                params: { supplierId: supplier.id },
              }"
            >
              <i class="icon-edit"></i>
            </router-link>

            <span @click="onDeleteSupplier(supplier.id, supplier.name)">
              <i class="icon-delete"></i>
            </span>
          </div>
        </template>

        <!-- No Data View -->
        <template #empty-message>
          {{ $t("Suppliers.NoSuppliersToShow_24") }}...
        </template>
      </InocuDataTable>
      <div v-if="isError" class="alert alert-danger w-50 mt-3" role="alert">
        {{ errorMessage }}
      </div>
    </div>

    <MazDialogPromise
      :data="{
        title: $t('Suppliers.Attention_27').toLocaleUpperCase(),
        message: deletionDialogMessage,
      }"
      identifier="deleteSupplierDialog"
    >
      <template #confirm-text>
        {{ $t("Suppliers.Confirm_29") }}
      </template>

      <template #cancel-text>
        {{ $t("Suppliers.Cancel_30") }}
      </template>
    </MazDialogPromise>

    <MazDialog
      v-model="showBulkImportSuppliersModal"
      :title="$t('Suppliers.BulkImportSuppliers_41')"
    >
      <div class="upload-suppliers-file">
        <label
          for="uploadSuppliersFile"
          class="btn btn-secondary"
          :class="{
            disabled: isUploadingSuppliersFile,
          }"
        >
          <i class="icon-upload-cloud"></i>

          <span>
            {{ $t("Suppliers.UploadSuppliersFileAs_40") }}
            CSV
            {{ $t("Suppliers.Or_39") }}
            XLSX
          </span>
        </label>

        <input
          type="file"
          id="uploadSuppliersFile"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          :style="{ display: 'none' }"
          @change="onSupplierFileUpload(($event.target as any).files[0])"
        />
      </div>

      <p class="uploading-indicator" v-if="isUploadingSuppliersFile">
        <MazSpinner color="success" />
        {{ $t("Suppliers.Uploading_42") }}...
      </p>

      <p class="file-errors" v-if="suppliersFileWithErrors">
        Uploaded file has errors, see them
        <a :href="suppliersFileWithErrors" download>here</a>
      </p>

      <p>
        {{ $t("Suppliers.DoesNotHaveTheTemplateDownloadIt_38") }}
        <a :href="suppliersCsvTemplate" download>csv</a>
        {{ $t("Suppliers.Or_39") }}
        <a :href="suppliersXlsxTemplate" download>xlsx</a>
      </p>
    </MazDialog>
  </div>
</template>

<script setup lang="ts">
import MazDialogPromise, {
  useMazDialogPromise,
} from "maz-ui/components/MazDialogPromise";
import MazDialog from "maz-ui/components/MazDialog";
import MazSpinner from "maz-ui/components/MazSpinner";
import { onMounted, ref, reactive, type Ref, watch } from "vue";
import { useSupplierStore } from "@/stores/supplier.store";
import type { SupplierInList } from "@/_helpers/interfaces/suppliers/supplier-in-list.interface";

import debounce from "lodash/debounce";
import type { Header, ServerOptions } from "vue3-easy-data-table";

import { useToast } from "vue-toast-notification";
import { useI18n } from "vue-i18n";
import { useMasterDataFileValidation } from "@/_helpers/composables/master-data/file-validation.composable";
import { useCustomerStore } from "@/stores/customer.store";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user.store";

const $toast = useToast();

const { t } = useI18n();

const { currentUser } = storeToRefs(useUserStore());

const suppliersTableHeaders: Ref<Header[]> = ref([
  {
    text: t("Suppliers.SupplierNumber_72"),
    value: "supplier_number",
    width: 200,
    sortable: true,
  },
  { text: t("Suppliers.Name_70"), value: "name", width: 250, sortable: true },
  { text: t("Suppliers.Email_15"), value: "email", width: 250 },
  { text: t("Suppliers.Phone_13"), value: "phone_number", width: 200 },
  {
    text: t("Suppliers.Country_11"),
    value: "country",
    width: 250,
    sortable: true,
  },
  { text: t("Suppliers.City_9"), value: "city", width: 250, sortable: true },
  { text: t("Suppliers.ZipCode_7"), value: "zip_code", width: 250 },
  {
    text: t("Suppliers.CreationDate_69"),
    value: "created_on",
    width: 350,
    sortable: true,
  },
  { text: t("Suppliers.Actions_68"), value: "actions" },
]);

const { getCustomersOptions } = useCustomerStore();

let customersList: any = ref([]);

let supplierFilter = reactive({
  customer: "",
  project: null,
  date: null,
});

const suppliersList: Ref<SupplierInList[]> = ref([]);
const selectedSuppliers: Ref<SupplierInList[]> = ref([]);
const loading = ref(false);
const isError = ref(false);
let errorMessage = ref("");
const suppliersListTotalCount = ref(0);
const serverOptions = ref<ServerOptions>({
  page: 1,
  rowsPerPage: 10,
  sortBy: "supplier_number",
  sortType: "asc",
});

const {
  getSuppliersList,
  deleteSupplier,
  bulkDeleteSuppliers,
  exportSuppliersList,
  getImportSuppliersTemplates,
  uploadSuppliersFile,
} = useSupplierStore();

watch(
  () => supplierFilter.customer,
  async () => {
    await getSuppliersTableList();
  }
);

onMounted(async () => {
  if (!currentUser.value?.user_role.includes("Admin")) {
    supplierFilter.customer = String(
      localStorage.getItem("selectedCustomerId")
    );
  } else {
    if (!supplierFilter.customer) {
      await getSuppliersTableList();
    }
  }
  const templates = await getImportSuppliersTemplates();
  suppliersCsvTemplate.value = templates.csv_template;
  suppliersXlsxTemplate.value = templates.xlsx_template;

  customersList.value = await getCustomersOptions();
});

// Fires when page number changes or items per page value changes
watch(
  serverOptions,
  async () => {
    await getSuppliersTableList();
  },
  {
    deep: true,
  }
);

const getSuppliersTableList = async () => {
  loading.value = true;

  const sortBy = serverOptions.value.sortBy;
  const sortDirection = serverOptions.value.sortType === "asc" ? "" : "-";
  const customer = supplierFilter.customer ? supplierFilter.customer : null;
  try {
    const response = await getSuppliersList({
      page: serverOptions.value.page,
      page_size: serverOptions.value.rowsPerPage,
      ordering: sortDirection + sortBy,
      customer: customer,
      ...filtrationCriteria.value,
    });
    suppliersList.value = [];
    suppliersList.value = response.results;
    suppliersListTotalCount.value = response.count;
    loading.value = false;
  } catch (error: any) {
    isError.value = true;
    loading.value = true;
    errorMessage.value = error.response.data.errors[0].detail;
    setTimeout(() => {
      isError.value = false;
      loading.value = false;
    }, 3000);
  }
};

const { showDialogAndWaitChoice } = useMazDialogPromise();
const deletionDialogMessage = ref("");

const onBulkDeleteSuppliers = async () => {
  try {
    deletionDialogMessage.value = t("Suppliers.AreYouSureYouWantToDelete_28", {
      suppliersCount: selectedSuppliers.value.length,
    });
    await showDialogAndWaitChoice("deleteSupplierDialog");

    const selectedSuppliersIds = selectedSuppliers.value.map(
      (supplier) => supplier.id
    );

    await bulkDeleteSuppliers(selectedSuppliersIds);
    await getSuppliersTableList();

    $toast.open({
      message: `All selected suppliers have been deleted successfully.`,
      type: "success",
    });

    selectedSuppliers.value = [];
  } catch (e) {
    e;
  }
};

const onDeleteSupplier = async (supplierId: number, supplierName: string) => {
  try {
    deletionDialogMessage.value = t("Suppliers.AreYouSureYouWantToDelete_28", {
      suppliersCount: "this",
    });
    await showDialogAndWaitChoice("deleteSupplierDialog");

    await deleteSupplier(supplierId);
    await getSuppliersTableList();

    $toast.open({
      message: `Supplier (${supplierName}) is deleted successfully.`,
      type: "success",
    });
  } catch (e) {
    e;
  }
};

const filtrationCriteria = ref({});

const showSupplierNumberFilter = ref(false);
const supplierNumberCriteria = ref("");

const showNameFilter = ref(false);
const nameCriteria = ref("");

const showCountryFilter = ref(false);
const countryCriteria = ref("");

const showCityFilter = ref(false);
const cityCriteria = ref("");

const showZipCodeFilter = ref(false);
const zipCodeCriteria = ref("");

const showCreatedOnFilter = ref(false);

const creationDateRange = ref();
const formatDate = (date: Date[]) => {
  const [startDate, endDate] = date;
  const startDateDetails = {
    day: startDate.getDate(),
    month: startDate.getMonth() + 1,
    year: startDate.getFullYear(),
  };
  const endDateDetails = {
    day: endDate ? endDate.getDate() : "",
    month: endDate ? endDate.getMonth() + 1 : "",
    year: endDate ? endDate.getFullYear() : "",
  };

  return `${startDateDetails.day}.${startDateDetails.month}.${startDateDetails.year} to ${endDateDetails.day}.${endDateDetails.month}.${endDateDetails.year}`;
};

watch(creationDateRange, async (dateRange) => {
  if (dateRange) {
    filtrationCriteria.value["created_after"] = dateRange[0]
      .toISOString()
      .split("T")[0];
    filtrationCriteria.value["created_before"] = dateRange[1]
      .toISOString()
      .split("T")[0];
  } else {
    filtrationCriteria.value["created_after"] = null;
    filtrationCriteria.value["created_before"] = null;
  }

  await getSuppliersTableList();
});

const filterSuppliersList = debounce(async function (
  filterCriteria: string,
  criteriaValue: string
) {
  filtrationCriteria.value[filterCriteria] = criteriaValue;

  await getSuppliersTableList();
},
300);

const exportAllSuppliers = async (fileFormat: "csv" | "xlsx") => {
  const fileUrl = await exportSuppliersList(fileFormat);

  const link = document.createElement("a");
  link.href = fileUrl;
  link.click();

  $toast.open({
    message: `All suppliers are exported successfully.`,
    type: "success",
  });
};

const showBulkImportSuppliersModal = ref(false);

const suppliersCsvTemplate = ref("");
const suppliersXlsxTemplate = ref("");

const { validateTypeAndExtension } = useMasterDataFileValidation();
const isUploadingSuppliersFile = ref(false);
const suppliersFileWithErrors = ref("");

const bulkImportSuppliers = () => {
  showBulkImportSuppliersModal.value = true;
  suppliersFileWithErrors.value = "";
};

const onSupplierFileUpload = async (suppliersFile: File) => {
  if (validateTypeAndExtension(suppliersFile)) {
    try {
      suppliersFileWithErrors.value = "";
      isUploadingSuppliersFile.value = true;
      const uploadingResult = await uploadSuppliersFile(
        suppliersFile,
        supplierFilter.customer
      );
      isUploadingSuppliersFile.value = false;

      if (uploadingResult.success) {
        $toast.open({
          message: `Suppliers are imported successfully.`,
          type: "success",
        });

        showBulkImportSuppliersModal.value = false;
        await getSuppliersTableList();
      } else {
        suppliersFileWithErrors.value = uploadingResult.file;
      }
    } catch (e) {
      e;
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables/variables";
.upload-suppliers-file {
  margin-bottom: 2rem;

  label[for="uploadSuppliersFile"].disabled {
    opacity: 0.4;
    pointer-events: none;
    cursor: not-allowed;
  }
}

.uploading-indicator {
  margin-bottom: 2rem;
}

.file-errors {
  color: $base-dark-red;
}
</style>
