<template>
  <div>
    <!--    filter popup -->
    <transition name="fade">
      <div class="generic-popup" v-if="showFilterPopup">
        <div class="popup-wrapper popup-wrapper-lg">
          <div class="popup-header">
            <h4 class="popup-title">
              {{ $t("Order.SearchAndFilter_155") }}
            </h4>
            <span class="popup-close" @click="closePopup">
              <i class="icon-close"></i>
            </span>
          </div>
          <div class="popup-body">
            <PaymentFilter @apply="filterPayments"> </PaymentFilter>
          </div>
        </div>
      </div>
    </transition>

    <!--  Bulk export popup -->
    <transition name="fade">
      <div class="generic-popup" v-if="showBulkExportPopup">
        <div class="popup-wrapper popup-wrapper-lg">
          <div class="popup-header">
            <h4 class="popup-title">
              {{ $t("Order.Export_85") }}
            </h4>
            <span class="popup-close" @click="closePopup">
              <i class="icon-close"></i>
            </span>
          </div>
          <div class="popup-body">
            <div class="export-popup">
              <export-form
                :fields="exportFields"
                @export="exportAllPayments"
              ></export-form>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- // Bulk export popup   -->

    <div class="generic-popup" v-if="isPopup">
      <div class="popup-wrapper">
        <div class="popup-header">
          <h4 class="popup-title">{{ $t("Order.DeleteOrder_74") }}</h4>
          <span class="popup-close" @click="closePopup">
            <i class="icon-close"></i>
          </span>
        </div>
        <div class="popup-body">
          <h5>
            {{ $t("Order.DeletePopupTitle_81") }}
            {{ paymentNo }} ?
          </h5>
          <div class="options">
            <button class="save" @click="onChangePaymentDelete">
              {{ $t("Order.DeletePopup_83") }}
            </button>
            <button class="cancel" @click="closePopup">
              {{ $t("Order.CancelPopup_82") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="generic-popup" v-if="confirmBulkApprove">
      <div class="popup-wrapper">
        <div class="popup-header">
          <h4 class="popup-title">
            {{ $t("Payments.PaymentConfirmation_32") }}
          </h4>
          <span class="popup-close" @click="closePopup">
            <i class="icon-close"></i>
          </span>
        </div>
        <div class="popup-body">
          <h5>
            {{ $t("Payments.PaymentComfirmApproved_34") }}
          </h5>
          <div class="options">
            <button class="save" @click="bulkAction('bulk_approve')">
              {{ $t("Payments.PaymentConfirm_33") }}
            </button>
            <button class="cancel" @click="closePopup">
              {{ $t("Order.CancelPopup_82") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="generic-popup" v-if="confirmBulkReject">
      <div class="popup-wrapper">
        <div class="popup-header">
          <h4 class="popup-title">
            {{ $t("Payments.PaymentConfirmation_32") }}
          </h4>
          <span class="popup-close" @click="closePopup">
            <i class="icon-close"></i>
          </span>
        </div>
        <div class="popup-body">
          <h5>
            {{ $t("Payments.PaymentConfirmRejected_35") }}
          </h5>
          <div class="options">
            <button class="save" @click="bulkAction('bulk_reject')">
              {{ $t("Payments.PaymentConfirm_33") }}
            </button>
            <button class="cancel" @click="closePopup">
              {{ $t("Order.CancelPopup_82") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="generic-popup" v-if="confirmBulkClarification">
      <div class="popup-wrapper">
        <div class="popup-header">
          <h4 class="popup-title">
            {{ $t("Payments.PaymentConfirmation_32") }}
          </h4>
          <span class="popup-close" @click="closePopup">
            <i class="icon-close"></i>
          </span>
        </div>
        <div class="popup-body">
          <h5>
            {{ $t("Payments.PaymentComfirmClarification_36") }}
          </h5>
          <div class="textarea-wrapper mb-4">
            <textarea
              v-model="clarificationComment"
              class="border w-100 px-2 py-3 rounded"
              :placeholder="$t('Order.TypeYourMessageHere_128')"
              rows="6"
            ></textarea>
          </div>
          <div class="options">
            <button class="save" @click="clarificationPayment(true)">
              {{ $t("Payments.PaymentConfirm_33") }}
            </button>
            <button class="cancel" @click="closePopup">
              {{ $t("Order.CancelPopup_82") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="generic-popup" v-if="confirmSingleApprove">
      <div class="popup-wrapper">
        <div class="popup-header">
          <h4 class="popup-title">
            {{ $t("Payments.PaymentConfirmation_32") }}
          </h4>
          <span class="popup-close" @click="closePopup">
            <i class="icon-close"></i>
          </span>
        </div>
        <div class="popup-body">
          <h5>
            {{ $t("Payments.PaymentComfirmApproved_37") }}
          </h5>
          <div class="options">
            <button class="save" @click="approvedPayment()">
              {{ $t("Payments.PaymentConfirm_33") }}
            </button>
            <button class="cancel" @click="closePopup">
              {{ $t("Order.CancelPopup_82") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="generic-popup" v-if="confirmSingleReject">
      <div class="popup-wrapper">
        <div class="popup-header">
          <h4 class="popup-title">
            {{ $t("Payments.PaymentConfirmation_32") }}
          </h4>
          <span class="popup-close" @click="closePopup">
            <i class="icon-close"></i>
          </span>
        </div>
        <div class="popup-body">
          <h5>
            {{ $t("Payments.PaymentComfirmRejected_38") }}
          </h5>
          <div class="options">
            <button class="save" @click="rejectPayment">
              {{ $t("Payments.PaymentConfirm_33") }}
            </button>
            <button class="cancel" @click="closePopup">
              {{ $t("Order.CancelPopup_82") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="generic-popup" v-if="confirmSingleClarification">
      <div class="popup-wrapper">
        <div class="popup-header">
          <h4 class="popup-title">
            {{ $t("Payments.PaymentConfirmation_32") }}
          </h4>
          <span class="popup-close" @click="closePopup">
            <i class="icon-close"></i>
          </span>
        </div>
        <div class="popup-body">
          <h5>
            {{ $t("Payments.PaymentComfirmClarification_39") }}
          </h5>
          <div class="textarea-wrapper mb-4">
            <textarea
              v-model="clarificationComment"
              class="border w-100 px-2 py-3 rounded"
              :placeholder="$t('Order.TypeYourMessageHere_128')"
              rows="6"
            ></textarea>
          </div>
          <div class="options">
            <button
              class="save"
              style="max-width: 250px; width: 100%"
              @click="clarificationPayment()"
            >
              {{ $t("Global.SendClarification_19") }}
            </button>
            <button class="cancel" @click="closePopup">
              {{ $t("Order.CancelPopup_82") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="generic-popup" v-if="confirmSingleForward">
      <div class="popup-wrapper">
        <div class="popup-header">
          <h4 class="popup-title">
            {{ $t("Payments.PaymentConfirmation_32") }}
          </h4>
          <span class="popup-close" @click="closePopup">
            <i class="icon-close"></i>
          </span>
        </div>
        <div class="popup-body">
          <h5>
            {{ $t("Payments.PaymentComfirmSubmit_40") }}
          </h5>
          <div class="options">
            <button class="save" @click="forwardPayment()">
              {{ $t("Payments.PaymentConfirm_33") }}
            </button>
            <button class="cancel" @click="closePopup">
              {{ $t("Order.CancelPopup_82") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="list-header">
      <div class="list-header__text">
        <h5>{{ $t("Payments.Payments_1") }}</h5>
      </div>

      <div class="list-header__actions">
        <div class="d-flex gap-3 align-items-center">
          <div class="bulk-actions" v-if="showBulkSubmitDeleteBtn()">
            <button
              class="btn btn-primary"
              @click="onChangeBulkProjectsDelete()"
            >
              {{ $t("Order.DeleteOrder_74") }}
            </button>
          </div>

          <div class="bulk-actions" v-if="showBulkRequestClarificationBtn()">
            <button
              class="btn btn-secondary"
              @click="confirmClarificationPopup()"
            >
              <i class="icon-document-clarification"></i>
              Request Clarifications
            </button>
          </div>

          <div class="bulk-actions" v-if="showBulkApproveBtn()">
            <button class="btn btn-secondary" @click="confirmApprovedPopup()">
              <i class="icon-document-approve"></i>
              Approve
            </button>
          </div>

          <div class="bulk-actions" v-if="showBulkRejectBtn()">
            <button class="btn btn-secondary" @click="confirmRejectedPopup()">
              <i class="icon-document-reject"></i>
              Reject
            </button>
          </div>
        </div>

        <div class="export-suppliers" v-if="showBulkExportBtn">
          <button
            class="btn btn-secondary"
            type="button"
            @click="showBulkExportPopup = true"
          >
            <i class="icon-export-line"></i>
            <span>{{ $t("Order.Export_85") }}</span>
          </button>
        </div>

        <button
          type="button"
          class="btn btn-warning"
          v-if="filterCount()"
          @click="resetFilters()"
        >
          {{ $t("Order.ResetFilters_156") }}
        </button>
        <button
          type="button"
          class="btn btn-secondary position-relative"
          @click="showFilterPopup = true"
        >
          {{ $t("Order.Filters_154") }}
          <span
            class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
            v-if="filterCount()"
          >
            {{ filterCount() }}
          </span>
        </button>
        <a @click="changeEditMode()" class="btn btn-primary">
          <i class="icon-add-new"></i>
          <span>{{ $t("Order.NewRequest_87") }}</span>
        </a>
      </div>
    </div>
    <InocuDataTable
      v-model:items-selected="selectedPayments"
      v-model:server-options="serverOptions"
      :server-items-length="paymentsListTotalCount"
      :loading="loading"
      :headers="paymentsTableHeaders"
      :items="paymentList"
      :rows-items="[10, 25, 50, 100]"
      table-class-name="listing-custom-table"
      :body-row-class-name="bodyRowClassNameFunction"
      alternating
      buttons-pagination
      no-hover
      must-sort
    >
      <template #item-payment_number_without_prefix="payment">
        <router-link
          :to="{ name: 'payment', params: { id: payment.id } }"
          class="dropdown-item"
        >
          {{ payment.payment_number }}</router-link
        >
      </template>
      <template #item-due_date="payment">
        {{ payment.due_date ? payment.due_date : "-" }}
      </template>
      <template #item-date="payment">
        {{ payment.date ? payment.date : "-" }}
      </template>
      <template #item-amount="payment">
        {{ payment.amount ? $n(+payment.amount, "currency") : "-" }}
      </template>
      <template #item-released_by="payment">
        {{ payment.released_by_name || "N/A" }}
      </template>
      <template #item-created_on="payment">
        {{
          payment.created_on === null || payment.created_on === ""
            ? ""
            : moment(payment.created_on).format("DD.MM.YYYY")
        }}
      </template>
      <template #item-created_by="payment">
        {{ payment.created_by_name || "-" }}
      </template>
      <template #item-payed="payment">
        <div
          class="form-check form-switch"
          v-if="payment.payed === false || payment.payed === true"
        >
          <input
            class="form-check-input"
            style="cursor: pointer"
            type="checkbox"
            role="switch"
            :disabled="!currentUser?.user_role.includes('Payer')"
            :checked="payment.payed"
            v-model="payment.payed"
            @change="onChangePayedStatus(payment.payed, payment.id)"
          />
        </div>
      </template>

      <template #item-is_clarification_requested="payment">
        <div class="actions" v-if="payment.is_clarification_requested">
          <VueCustomTooltip :label="$t('Order.Clarification_requested_143')">
            <MazIcon
              name="question-mark-circle"
              size="1.5rem"
              path="/icons"
              class="clarification-icon"
            ></MazIcon>
          </VueCustomTooltip>
        </div>
      </template>

      <template #item-status="payment">
        <span
          class="order-badge reject-badge"
          v-if="payment.status_display === 'REJECTED'"
        >
          Rejected
        </span>
        <span
          class="order-badge pending-badge"
          v-if="payment.status_display === 'CLARIFICATION_REQUESTED'"
        >
          Clarification Requested
        </span>
        <span
          class="order-badge approved-badge"
          v-if="payment.status_display === 'APPROVED'"
        >
          Approved
        </span>
        <span
          class="order-badge pending-badge"
          v-if="payment.status_display === 'PENDING_APPROVAL'"
        >
          Pending
        </span>
        <span
          class="order-badge draft-badge"
          v-if="payment.status_display === 'DRAFT'"
        >
          Draft
        </span>
        <span
          class="order-badge clearing-badge"
          v-if="payment.status_display === 'CLEARING' && payment.is_restrict"
        >
          Clearing
          {{
            `(${payment.total_clearer_approved || 0}/${
              payment.total_clearer || 0
            })`
          }}
        </span>
        <span
          class="order-badge clearing-badge"
          v-if="payment.status_display === 'CLEARING' && !payment.is_restrict"
        >
          Clearing
        </span>
      </template>
      <template #item-actions="payment">
        <div class="actions">
          <!-- approvedPayment(payment.id) -->
          <span
            @click="approvedSinglePayment(payment.id)"
            title="Approve"
            v-if="showApproveOrRejectAction(payment)"
          >
            <i class="icon-document-approve"></i>
          </span>
          <span
            @click="clarificationSinglePayment(payment.id)"
            title="Clarification"
            v-if="showRequestClarificationAction(payment)"
          >
            <i class="icon-document-clarification"></i>
          </span>
          <span
            @click="rejectedSinglePayment(payment.id)"
            title="Reject"
            v-if="showApproveOrRejectAction(payment)"
          >
            <i class="icon-document-reject"></i>
          </span>
          <span
            @click="editSinglePayment(payment.id)"
            v-if="showEditPaymentAction(payment)"
          >
            <i class="icon-edit"></i>
          </span>
          <span
            v-if="
              payment.status === 'DRAFT' &&
              payment.released_by === currentUser?.id
            "
            @click="openDeletePopup(payment.id)"
          >
            <i class="icon-delete"></i>
          </span>
        </div>
      </template>
    </InocuDataTable>
    <div v-if="isError" class="alert alert-danger w-50 mt-3" role="alert">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup lang="ts">
import router from "@/router";
import { onMounted, ref, type Ref, watch, computed } from "vue";
import type {
  Header,
  ServerOptions,
  Item,
  BodyRowClassNameFunction,
} from "vue3-easy-data-table";
import { useToast } from "vue-toast-notification";
import { useI18n } from "vue-i18n";
import { useUserStore } from "@/stores/user.store";
import { storeToRefs } from "pinia";
import moment from "moment";
import { usePaymentStore } from "@/stores/payment.store";
import MazIcon from "maz-ui/components/MazIcon";
import { VueCustomTooltip } from "@adamdehaven/vue-custom-tooltip";
import PaymentFilter from "@/components/payments/PaymentFilter.vue";
import ExportForm from "@/components/shared/export/export-form.vue";

const { currentUser } = storeToRefs(useUserStore());

const {
  getPayments,
  editPaymentPatch,
  deletePayment,
  paymentsBulkAction,
  exportPayments,
  createPaymentComments,
  filterCount,
  resetOptions,
  PaymentsOptions,
} = usePaymentStore();

const props = defineProps({
  status: {
    default: "",
    type: [String],
  },
  customer: {
    default: -1,
    type: [Number],
  },
  project: {
    default: -1,
    type: [Number],
  },
  date: {
    default: null,
    type: [Array],
  },
  isdashbaord: {
    default: false,
    type: [Boolean],
  },
});

const { t } = useI18n();
const $toast = useToast();

const paymentList: any = ref([]);

const loading = ref(false);

const paymentsListTotalCount = ref(0);

const selectedPayments: any = ref([]);

const isBulk = ref(false);

const confirmBulkApprove = ref(false);

const confirmBulkReject = ref(false);

const confirmBulkClarification = ref(false);

const clarificationComment = ref("");

const selectedPaymentsIds = ref([]);

const serverOptions = ref<ServerOptions>({
  page: 1,
  rowsPerPage: 10,
  sortBy: "payment_number_without_prefix",
  sortType: "desc",
});

const bodyRowClassNameFunction: BodyRowClassNameFunction = (
  item: Item
): string => {
  if (item.status === "APPROVED" && item.payed) return "traffic-light-green";
  if (item.status === "PENDING_APPROVAL" && !item.payed)
    return "traffic-light-default";
  return "";
};

const isPopup = ref(false);

const showFilterPopup = ref(false);

const showBulkExportPopup = ref(false);

let paymentNo = ref(null);

let currentPayment = ref(null);

let confirmSingleApprove = ref(false);

let confirmSingleReject = ref(false);

let confirmSingleClarification = ref(false);

let confirmSingleForward = ref(false);

const paymentsTableHeaders: Ref<Header[]> = ref([
  {
    text: t("Payments.PaymentNo_2"),
    value: "payment_number_without_prefix",
    sortable: true,
  },
  {
    text: t("Order.Supplier_31"),
    value: "supplier_name",
  },
  {
    text: t("Payments.PaymentInvoiceNo_15"),
    value: "invoice_number",
  },
  {
    text: t("Payments.InternalOrderNo_43"),
    value: "internal_order_number",
  },
  {
    text: t("Payments.PaymentDueDate_3"),
    value: "due_date",
  },
  {
    text: t("Payments.PaymentAmount_5"),
    value: "amount",
  },
  {
    text: t("Order.CreatedOn_139"),
    value: "created_on",
  },
  {
    text: t("Order.CreatedBy_140"),
    value: "created_by",
  },
  {
    text: t("Order.ReleasedBy_78"),
    value: "released_by",
  },
  {
    text: t("Payments.PaymentPayed_6"),
    value: "payed",
  },
  {
    text: t("Order.Status_80"),
    value: "status",
  },
  { text: "", value: "actions" },
]);

const exportFields = [
  { label: t("Payments.PaymentNo_2"), value: "payment_number" },
  { label: t("Order.Supplier_31"), value: "supplier_name" },
  { label: t("Order.OrderSupplierNo_98"), value: "supplier_number" },
  { label: t("Payments.PaymentInvoiceNo_15"), value: "invoice_number" },
  { label: t("Payments.InternalOrderNo_43"), value: "internal_order_number" },
  { label: t("Payments.PaymentDueDate_3"), value: "due_date" },
  { label: t("Payments.PaymentAmount_5"), value: "amount" },
  { label: t("Order.CreatedOn_139"), value: "created_on" },
  { label: t("Order.CreatedBy_140"), value: "created_by" },
  { label: t("Order.ReleasedBy_78"), value: "released_by" },
  { label: t("Payments.PaymentPayed_6"), value: "payed" },
];

const isError = ref(false);

let errorMessage = ref("");

const showBulkSubmitDeleteBtn = () => {
  // make sure all selected orders are drafts
  return (
    selectedPayments.value.length &&
    selectedPayments.value.filter((item: any) => {
      return item.status === "DRAFT";
    }).length == selectedPayments.value.length
  );
};
const showBulkRequestClarificationBtn = () => {
  return (
    selectedPayments.value.length &&
    selectedPayments.value.filter((item: any) => {
      return (
        ["PENDING_APPROVAL", "CLEARING"].includes(item.status_display) &&
        !item.is_clarification_requested &&
        (currentUser.value?.user_role.includes("Payment Clearer") ||
          currentUser.value?.user_role.includes("Approver") ||
          currentUser.value?.user_role.includes("Admin"))
      );
    }).length == selectedPayments.value.length
  );
};

const showBulkApproveBtn = () => {
  if (currentUser.value?.user_role.includes("Admin")) {
    return (
      selectedPayments.value.length &&
      selectedPayments.value.filter((item: any) => {
        return item.status_display === "CLEARING";
      }).length === selectedPayments.value.length
    );
  }
  if (currentUser.value?.user_role.includes("Approver")) {
    return (
      selectedPayments.value.length &&
      selectedPayments.value.filter((item: any) => {
        // this only happens when all clearers have cleared the order, or at least
        // one has cleared and the order is not in strict mode
        return item.status_display === "PENDING_APPROVAL";
      }).length == selectedPayments.value.length
    );
  }
  if (currentUser.value?.user_role.includes("Payment Clearer")) {
    return (
      selectedPayments.value.length &&
      selectedPayments.value.filter((item: any) => {
        return item.status_display === "PENDING_APPROVAL";
      }).length == selectedPayments.value.length
    );
  }
  return false;
};

const showBulkRejectBtn = () => {
  return (
    (currentUser.value?.user_role.includes("Admin") ||
      currentUser.value?.user_role.includes("Approver") ||
      currentUser.value?.user_role.includes("Payment Clearer")) &&
    selectedPayments.value.length &&
    selectedPayments.value.filter((item: any) => {
      return (
        item.status_display == "CLEARING" ||
        item.status_display == "PENDING_APPROVAL"
      );
    }).length === selectedPayments.value.length
  );
};

const showBulkExportBtn = computed(() => {
  let flag = true;
  selectedPayments.value.map((payment: any) => {
    if (payment.status !== "APPROVED") flag = false;
  });
  return flag && selectedPayments.value.length > 0;
});

const onChangeBulkProjectsDelete = async () => {
  isPopup.value = true;
  selectedPaymentsIds.value = selectedPayments.value.map(
    (payment: any) => payment.id
  );
  isBulk.value = true;

  // $toast.open({
  //   message: `All selected projects have been deleted successfully.`,
  //   type: "success",
  // });
  // selectedPayments.value = [];
};

const changeEditMode = () => {
  localStorage.setItem("isEditPayment", "false");
  router.push({ name: "create-payment" });
};

const filters = ref({
  ...PaymentsOptions.value,
});

const filterPayments = async (filter: any) => {
  filters.value = filter;
  serverOptions.value.page = 1;
  closePopup();
  await getPaymentList(props.status, props.customer, props.project, props.date);
};

const getPaymentList = async (
  status: string,
  customer: number,
  project: number,
  date: any
) => {
  if (props.isdashbaord && customer === -1) {
    return;
  }

  loading.value = true;
  const sortBy = serverOptions.value.sortBy;
  const sortDirection = serverOptions.value.sortType === "asc" ? "" : "-";
  const customerId = customer !== -1 ? customer : null;
  const projectId = project !== -1 ? project : null;
  const paymentStatus = status !== "" ? status : null;

  let startDate =
    date === null || date.length === 0
      ? null
      : moment(date[0]).format("YYYY-MM-DD");
  let endDate =
    date === null || date.length === 0
      ? null
      : moment(date[1]).format("YYYY-MM-DD");

  try {
    await getPayments({
      ...filters.value,
      page: serverOptions.value.page,
      page_size: serverOptions.value.rowsPerPage,
      ordering: sortDirection + sortBy,
      created_after: filters.value.created_after || startDate,
      created_before: filters.value.created_before || endDate,
      customer: customerId,
      project_id: projectId,
      status: paymentStatus || filters.value.status,
    }).then((response) => {
      paymentList.value = response.results;
      paymentsListTotalCount.value = response.count;

      loading.value = false;
    });
    return paymentList.value;
  } catch (error: any) {
    console.log("error", error);
    isError.value = true;
    loading.value = false;
    errorMessage.value = error.response.data.errors[0].detail;
    setTimeout(() => {
      isError.value = false;
    }, 3000);
  }
};

const confirmApprovedPopup = () => {
  confirmBulkApprove.value = true;
};

const confirmRejectedPopup = () => {
  confirmBulkReject.value = true;
};

const confirmClarificationPopup = () => {
  confirmBulkClarification.value = true;
};

const openDeletePopup = (id: any) => {
  isPopup.value = true;
  paymentNo.value = id;
};

const closePopup = () => {
  isPopup.value = false;
  confirmBulkApprove.value = false;
  confirmBulkReject.value = false;
  confirmBulkClarification.value = false;
  confirmSingleApprove.value = false;
  confirmSingleReject.value = false;
  confirmSingleClarification.value = false;
  confirmSingleForward.value = false;
  showFilterPopup.value = false;
  showBulkExportPopup.value = false;
};

const onChangePaymentDelete = async () => {
  closePopup();

  if (isBulk.value === true) {
    bulkAction("bulk_delete").then(() => {
      isBulk.value = false;
      selectedPayments.value = [];
      $toast.open({
        message: `All selected Payments have been deleted successfully.`,
        type: "success",
      });
    });
  } else {
    await deletePayment(paymentNo.value);
    $toast.open({
      message: `Payment (${paymentNo.value}) deleted successfully.`,
      type: "success",
    });
  }
};

const forwardPayment = async () => {
  let ids = [currentPayment.value];
  await paymentsBulkAction("bulk_submit", ids)
    .then(async () => {
      closePopup();
      await getPaymentList(
        props.status,
        props.customer,
        props.project,
        props.date
      );
      $toast.open({
        message: `All selected payments have been submitted.`,
        type: "success",
      });
    })
    .catch((error: any) => {
      $toast.open({
        message: error.response.data.errors[0].detail,
        type: "error",
      });
      closePopup();
    });
};

const onChangePayedStatus = (payed: any, id: number) => {
  let payment = paymentList.value.filter((p: any) => p.id === id);

  payment.map(async (p: any) => {
    // let customer = p.customer;
    // let orders = p.orders;
    // let project = p.project;
    // let supplier = p.supplier;

    // p.customer = p.customer.id;
    // p.orders = p.orders.map((order: any) => order.id);
    // p.project = p.project !== undefined || p.project ? p.project.id : null;
    // p.supplier = p.supplier.id;
    p.payed = payed ? true : false;
    // p.submitted = payed ? true : false;
    await editPaymentPatch(id, { payed: p.payed })
      .then((res) => {
        p.payed = res.data.payed;
      })
      .catch((error) => {
        error.response.data.errors.map((item: any) => {
          if (error.response.data.type === "validation_error") {
            $toast.open({
              message: item.attr
                ? item.attr.toUpperCase() + ": " + item.detail
                : item.detail,
              type: "error",
            });
          } else {
            $toast.open({
              message: item.detail,
              type: "error",
            });
          }
        });
      });
  });
};

const exportAllPayments = async (data: any) => {
  selectedPaymentsIds.value = selectedPayments.value.map(
    (order: any) => order.id
  );
  if (selectedPaymentsIds.value.length === 0) {
    $toast.open({
      message: `Please select at least one order.`,
      type: "error",
    });
    return;
  }
  let fileFormat = data.exportFormat;
  let fields = data.fields;
  try {
    const data = await exportPayments(
      selectedPaymentsIds.value,
      fileFormat,
      fields
    );
    $toast.open({
      message: `All Payments are exported successfully.`,
      type: "success",
    });
    // open file url in new tab
    window.open(data.file_url, "_blank");
  } catch (error: any) {
    $toast.open({
      message: error.response.data.errors[0].detail,
      type: "error",
    });
  }
};

const forwardSinglePayment = (id: any) => {
  currentPayment.value = id;
  confirmSingleForward.value = true;
};

const approvedSinglePayment = (id: any) => {
  currentPayment.value = id;
  confirmSingleApprove.value = true;
};

const clarificationSinglePayment = (id: any) => {
  currentPayment.value = id;
  confirmSingleClarification.value = true;
};

const rejectedSinglePayment = (id: any) => {
  currentPayment.value = id;
  confirmSingleReject.value = true;
};

const clarificationPayment = async (bulk = false) => {
  let ids = [currentPayment.value];

  if (bulk) {
    selectedPaymentsIds.value = selectedPayments.value.map(
      (payment: any) => payment.id
    );
    ids = selectedPaymentsIds.value;
  }

  let obj = {
    payment: null,
    text: clarificationComment.value,
  };

  await paymentsBulkAction("bulk_request_clarification", ids)
    .then(async () => {
      if (clarificationComment.value !== "") {
        ids.forEach(async (id) => {
          obj.payment = id;
          await createPaymentComments(obj).catch((error) => {
            $toast.open({
              message: error.response.data.errors[0].detail,
              type: "error",
            });
          });
        });
      }
      closePopup();
      await getPaymentList(
        props.status,
        props.customer,
        props.project,
        props.date
      );
      $toast.open({
        message: `All selected payments have been clarified.`,
        type: "success",
      });
    })
    .catch((error: any) => {
      $toast.open({
        message: error.response.data.errors[0].detail,
        type: "error",
      });
      closePopup();
    });
};

const approvedPayment = async () => {
  let ids = [currentPayment.value];
  await paymentsBulkAction("bulk_approve", ids)
    .then(async () => {
      closePopup();
      await getPaymentList(
        props.status,
        props.customer,
        props.project,
        props.date
      );
      $toast.open({
        message: `All selected payments have been approved.`,
        type: "success",
      });
    })
    .catch((error: any) => {
      $toast.open({
        message: error.response.data.errors[0].detail,
        type: "error",
      });
      closePopup();
    });
};

const rejectPayment = async () => {
  let ids = [currentPayment.value];
  await paymentsBulkAction("bulk_reject", ids)
    .then(async () => {
      closePopup();
      await getPaymentList(
        props.status,
        props.customer,
        props.project,
        props.date
      );
      $toast.open({
        message: `All selected payments have been rejected.`,
        type: "success",
      });
    })
    .catch((error: any) => {
      $toast.open({
        message: error.response.data.errors[0].detail,
        type: "error",
      });
      closePopup();
    });
};

const bulkAction = async (actionName: any) => {
  selectedPaymentsIds.value = selectedPayments.value.map(
    (payment: any) => payment.id
  );
  let toastName =
    actionName === "bulk_approve"
      ? "Approve"
      : actionName === "bulk_reject"
      ? "Reject"
      : actionName === "bulk_request_clarification"
      ? "Request Clarification"
      : actionName === "bulk_delete"
      ? "Deleted"
      : actionName === "bulk_submit"
      ? "Submitted"
      : actionName;

  await paymentsBulkAction(actionName, selectedPaymentsIds.value)
    .then(async () => {
      closePopup();
      selectedPayments.value = [];
      await getPaymentList(
        props.status,
        props.customer,
        props.project,
        props.date
      );
      $toast.open({
        message: `All selected Payments have been ${toastName}.`,
        type: "success",
      });
    })
    .catch((error: any) => {
      $toast.open({
        message: error,
        type: "danger",
      });
    });
};

onMounted(async () => {
  await getPaymentList(props.status, props.customer, props.project, props.date);
  updateHeaders(props.status);
});

watch(
  serverOptions,
  async () => {
    await getPaymentList(
      props.status,
      props.customer,
      props.project,
      props.date
    );
    updateHeaders(props.status);
  },
  {
    deep: true,
  }
);

watch(
  () => props.status,
  async (newVal) => {
    serverOptions.value.page = 1;
    selectedPayments.value = [];
    await getPaymentList(newVal, props.customer, props.project, props.date);
    updateHeaders(newVal);
  }
);

watch(
  () => props.customer,
  async (newVal) => {
    selectedPayments.value = [];
    await getPaymentList(props.status, newVal, props.project, props.date);
  }
);

watch(
  () => props.project,
  async (newVal) => {
    selectedPayments.value = [];
    await getPaymentList(props.status, props.customer, newVal, props.date);
  }
);

watch(
  () => props.date,
  async (newVal) => {
    selectedPayments.value = [];
    await getPaymentList(props.status, props.customer, props.project, newVal);
  }
);

const editSinglePayment = (id: any) => {
  localStorage.setItem("isEditPayment", "true");
  // localStorage.setItem("paymentId", id);
  router.push({ name: "create-payment", query: { payment: id } });
};

const showEditPaymentAction = (payment) => {
  return (
    (payment.status === "DRAFT" &&
      payment.created_by === currentUser.value?.id) ||
    (payment.is_clarification_requested &&
      payment.created_by === currentUser.value?.id)
  );
};
const showApproveOrRejectAction = (payment: any) => {
  if (currentUser.value?.user_role.includes("Admin")) {
    return ["PENDING_APPROVAL", "CLEARING", "CLARIFICATION_REQUESTED"].includes(
      payment?.status_display
    );
  }
  if (currentUser.value?.user_role.includes("Approver")) {
    return payment?.status_display === "PENDING_APPROVAL";
  }
  if (currentUser.value?.user_role.includes("Order Clearer")) {
    return ["PENDING_APPROVAL", "CLARIFICATION_REQUESTED"].includes(
      payment?.status_display
    );
  }
  return false;
};

const showRequestClarificationAction = (payment: any) => {
  if (
    currentUser.value?.user_role.includes("Admin") ||
    currentUser.value?.user_role.includes("Approver") ||
    currentUser.value?.user_role.includes("Payment Clearer")
  ) {
    return (
      ["PENDING_APPROVAL", "CLEARING"].includes(payment?.status_display) &&
      !payment?.is_clarification_requested
    );
  }
  return false;
};

const updateHeaders = (status: string) => {
  if (status === "CLEARING" || status === "PENDING_APPROVAL" || status == "") {
    //   make sure is_clarification_requested header exists before the last header
    if (
      !paymentsTableHeaders.value.find(
        (header) => header.value === "is_clarification_requested"
      )
    ) {
      paymentsTableHeaders.value.splice(
        paymentsTableHeaders.value.length - 1,
        0,
        {
          text: "",
          value: "is_clarification_requested",
        }
      );
    }
  } else {
    //   remove is_clarification_requested header
    paymentsTableHeaders.value = paymentsTableHeaders.value.filter(
      (header) => header.value !== "is_clarification_requested"
    );
  }
};

const resetFilters = () => {
  filterPayments(resetOptions());
};
</script>

<style>
.clarification-icon {
  display: inline-block;
  color: #f8bd53;
  margin-left: 5px;
}
</style>
