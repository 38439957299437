<template>
  <header class="login-header">
    <h2>{{ $t("Login.TwoFactorAuthentication_10").toUpperCase() }}</h2>
    <p>{{ $t("Login.PleaseEnterTheCodeFromMfaMethod_11") }}</p>
  </header>

  <div v-if="errorMessage" class="alert alert-danger" role="alert">
    {{ errorMessage }}
  </div>

  <Form
    class="login-form"
    v-slot="{ errors, isSubmitting }"
    @submit="verifyEnteredCode"
  >
    <div class="form-group code-group">
      <label for="code" class="form-label">{{ $t("Login.Code_12") }}</label>

      <Field
        id="code"
        name="code"
        type="text"
        class="form-control"
        :class="{ 'is-invalid': errors.code }"
        :placeholder="$t('Login.EnterCodeHere_13')"
        :rules="codeRules"
      />

      <div class="invalid-feedback">{{ errors.code }}</div>
    </div>

    <button class="login-action-btn verify-btn" :disabled="isSubmitting">
      {{ $t("Login.Verify_14") }}
    </button>
  </Form>
</template>

<script setup lang="ts">
import { useAuthStore } from "@/stores/auth.store";
import { storeToRefs } from "pinia";

import { useI18n } from "vue-i18n";
import { Form, Field } from "vee-validate";
import { string } from "yup";

const { t } = useI18n();
const codeRules = string()
  .required(t("Login.CodeIsRequired_32"))
  .matches(/^\d{6}$/, t("Login.CodeMustBeValid_33"));

const props = defineProps<{
  codeReceivedFrom: "TOTP" | "SMS" | null;
}>();

const { errorMessage } = storeToRefs(useAuthStore());
const { verifyCode } = useAuthStore();

const verifyEnteredCode = async (values: any) => {
  const mfaMethod = props.codeReceivedFrom as "TOTP" | "SMS";
  await verifyCode(values.code, mfaMethod);
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables/variables.scss";
@import "./login-styles.scss";

.login-form {
  .form-group.code-group {
    margin-bottom: 1.75rem;
  }
}

.login-link.resend-code {
  align-self: center;
}

.login-action-btn.verify-btn {
  margin-bottom: 1.5rem;
}
</style>
