<template>
  <div class="customer">
    <h1 class="page-title">
      {{
        isEditCustomer
          ? $t("CustomerManagement.EditCustomer_5")
          : $t("CustomerManagement.CreateCustomer_5")
      }}
    </h1>
    <div class="form-wrapper">
      <Wizard
        navigable-tabs
        :custom-tabs="[
          {
            title: $t('CustomerManagement.MainData_6'),
          },
          {
            title: $t('CustomerManagement.AssignTeam_7'),
          },
          {
            title: $t('CustomerManagement.FormsOrder_8'),
          },
          {
            title: $t('CustomerManagement.Legals_9'),
          },
        ]"
        @change="onChangeCurrentTab"
      >
        <div v-if="currentTabIndex === 0">
          <MainData
            @setCustomerID="setCustomerID($event)"
            :isEditMode="isEditCustomer"
            :isMainDate="true"
            :customerIdEdit="customerIdEdit"
            :isPrevious="true"
            :isFormWizard="true"
          />
        </div>
        <div v-if="currentTabIndex === 1">
          <AssignTeam
            :customerId="customerId"
            :isEditMode="isEditCustomer"
            :isPrevious="true"
            :isFormWizard="true"
          />
        </div>
        <div v-if="currentTabIndex === 2">
          <FormsOrder
            :customerId="customerId"
            :isEditMode="isEditCustomer"
            :isPrevious="true"
            :isFormWizard="true"
          />
        </div>
        <div v-if="currentTabIndex === 3">
          <Legals
            :customerId="customerId"
            :isEditMode="isEditCustomer"
            :isPrevious="true"
          />
        </div>
      </Wizard>
    </div>
  </div>
</template>

<script setup lang="ts">
import MainData from "./Tabs/MainData/MainData.vue";
import AssignTeam from "./Tabs/AssignTeam/AssignTeam.vue";
import FormsOrder from "./Tabs/FormsOrder/FormsOrder.vue";
import Legals from "./Tabs/Legals/Legals.vue";

import Wizard from "form-wizard-vue3";
import "form-wizard-vue3/dist/form-wizard-vue3.css";

import { ref, reactive } from "vue";

let currentTabIndex = reactive({});
let customerId: any = ref("");
let isEditCustomer: any = ref(false);
let customerIdEdit: any = ref("");

isEditCustomer.value = localStorage.getItem("isEditCustomer");
isEditCustomer.value = JSON.parse(isEditCustomer.value);

customerIdEdit.value = localStorage.getItem("customerId");

customerId.value = isEditCustomer.value
  ? localStorage.getItem("customerId")
  : "";

const setCustomerID = (id: any) => {
  customerId.value = id;
};

function onChangeCurrentTab(index: any, oldIndex: any) {
  currentTabIndex = index;
}
</script>
