<template>
  <div class="card">
    <div class="card-body">
      <div class="card-title">{{ $t("Global.ResetPassword_3") }}</div>
      <div class="card-subtitle">{{ $t("Global.Password_2") }}</div>

      <div class="card-content">
        <button
          v-if="!isResetPasswordInputsShown"
          class="btn reset-password-btn"
          @click="showResetPasswordInputs"
        >
          {{ $t("Global.ResetPassword_3") }}
        </button>

        <Form
          v-else
          class="reset-password-form"
          :validation-schema="newPasswordSchema"
          v-slot="{ errors, isSubmitting }"
          @submit="onNewPasswordCreated"
        >
          <div class="form-group password-group">
            <Field
              id="password"
              name="password"
              :type="'password'"
              class="form-control"
              :class="{ 'is-invalid': errors.password }"
              :placeholder="$t('Global.EnterYourPasswordHere_4')"
            />

            <div class="invalid-feedback">{{ errors.password }}</div>
          </div>

          <div class="form-group confirm-password-group">
            <Field
              id="confirmPassword"
              name="confirmPassword"
              :type="'password'"
              class="form-control"
              :class="{ 'is-invalid': errors.confirmPassword }"
              :placeholder="$t('Global.ConfirmYourPasswordHere_5')"
            />

            <div class="invalid-feedback">
              {{ errors.confirmPassword }}
            </div>
          </div>

          <div
            v-if="passwordResetSuccessState !== null"
            class="password-reset-result"
          >
            <span v-if="passwordResetSuccessState" class="text-success">
              {{ $t("UserProfile.PasswordHasBeenReset_3") }}
            </span>
            <span v-else class="text-danger"> Something went wrong. </span>
          </div>

          <button class="btn reset-password-btn" :disabled="isSubmitting">
            {{ $t("Global.ResetPassword_3") }}
          </button>
        </Form>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, type Ref } from "vue";
import { useUserStore } from "@/stores/user.store";

import { Form, Field } from "vee-validate";
import { object, string, ref as YupRef } from "yup";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const newPasswordSchema = object().shape({
  password: string()
    .required(t("Login.PleaseEnterYourPassword_39"))
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).{8,}$/,
      t("Login.PasswordMustBe_40")
    ),
  confirmPassword: string()
    .required(t("Login.PleaseConfirmYourPassword_41"))
    .oneOf([YupRef("password"), null, ""], t("Login.PasswordMustMatch_42")),
});

const isResetPasswordInputsShown = ref(false);
const passwordResetSuccessState: Ref<null | boolean> = ref(null);
const { resetPassword } = useUserStore();

const showResetPasswordInputs = () => {
  isResetPasswordInputsShown.value = true;
};

const onNewPasswordCreated = async (values: any) => {
  try {
    await resetPassword(values.password, values.confirmPassword);

    passwordResetSuccessState.value = true;
  } catch (error) {
    console.log(error);
    passwordResetSuccessState.value = false;
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables/variables.scss";

.card {
  height: 100%;
  max-height: 290px;

  .card-content {
    .form-group {
      margin-bottom: 1rem;
    }

    .password-reset-result {
      span {
        font-size: 0.9rem;
      }
    }

    .reset-password-btn {
      background-color: $primary;
      color: $text-color-primary;
      border-radius: 0.2rem;
      width: 100%;
      margin-top: 1rem;
    }
  }
}
</style>
