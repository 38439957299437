<template>
  <div class="customer">
    <h1 class="page-title">{{ $t("CustomerManagement.EditLimits_87") }}</h1>
    <div class="form-wrapper mb-4">
      <div class="form-title border-0 mb-4">
        <h2>{{ $t("CustomerManagement.SelectCustomer_42") }}</h2>
      </div>
      <form class="generic-form">
        <div class="row">
          <div class="col-md-5">
            <div class="form-input m-0">
              <label class="form-label">{{
                $t("CustomerManagement.ChooseCustomer_85")
              }}</label>
              <!-- <select
                v-model="customerId"
                class="form-select"
              >
                <option value="" selected disabled>
                  {{ $t("CustomerManagement.SelectCustomer_42") }}
                </option>
                <template v-for="item in customersList" :key="item.id">
                  <option :value="item.id" v-if="item.is_active">
                    {{ item.name }}
                  </option>
                </template>
              </select> -->
              <!--  -->
              <LegacyMultiSelect
                v-model="customerId"
                name="customers"
                :object="false"
                :options="customersList"
                :resolve-on-load="true"
                :min-chars="0"
                :placeholder="$t('CustomerManagement.SelectCustomer_42')"
                :closeOnSelect="true"
                :clearOnBlur="false"
                :searchable="true"
                valueProp="id"
                label="name"
              />
              <!--  -->
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="form-wrapper">
      <div class="form-title">
        <h2>{{ $t("CustomerManagement.EditLimits_87") }}</h2>
      </div>
      <MainData
        :customerIdEdit="customerId"
        :isEditMode="true"
        :isMainDate="false"
        :isPrevious="false"
        :isFormWizard="false"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import MainData from "../../CreateCustomer/Tabs/MainData/MainData.vue";
import Multiselect from "@vueform/multiselect";

import { ref, onMounted } from "vue";
import { useCustomerStore } from "@/stores/customer.store";
const { getCustomersOptions } = useCustomerStore();

let customersList: any = ref([]);
let customerId: any = ref("");

onMounted(async () => {
  customersList.value = await getCustomersOptions();
});
</script>
