<template>
  <div>
    <!-- Export Sepa Payment Modal -->
    <div class="generic-popup" v-if="isExport">
      <div class="popup-wrapper">
        <div class="popup-header">
          <h4 class="popup-title">Export Sepa Payments</h4>
          <span class="popup-close" @click="closePopup">
            <i class="icon-close"></i>
          </span>
        </div>
        <div class="popup-body">
          <h5>
            We have received your request and processing it, once finished we
            will send an email with the link to download
          </h5>
        </div>
      </div>
    </div>
    <!-- View Sepa Payment Modal -->
    <div class="generic-popup" v-if="isViewSepa">
      <div class="popup-wrapper popup-wrapper-lg">
        <div class="popup-header">
          <h4 class="popup-title">View Sepa Payments</h4>
          <span class="popup-close" @click="closePopup">
            <i class="icon-close"></i>
          </span>
        </div>
        <div class="popup-body">
          <table class="table table-striped table-hover table-bordered">
            <tbody>
              <tr>
                <th scope="row">Owner Name:</th>
                <td>{{ currentSepaPayment.owner_name }}</td>
              </tr>
              <tr>
                <th scope="row">Source Iban:</th>
                <td>{{ currentSepaPayment.source_iban }}</td>
              </tr>
              <tr>
                <th scope="row">Amount:</th>
                <td>{{ currentSepaPayment.amount }}</td>
              </tr>
              <tr>
                <th scope="row">Payment Number:</th>
                <td>
                  {{
                    currentSepaPayment.payment_numbers &&
                    currentSepaPayment.payment_numbers.length > 0
                      ? currentSepaPayment.payment_numbers
                      : "N/A"
                  }}
                </td>
              </tr>
              <tr>
                <th scope="row">Created on:</th>
                <td>
                  {{
                    currentSepaPayment.created_on
                      ? moment(currentSepaPayment.created_on).format(
                          "DD.MM.YYYY"
                        )
                      : "-"
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="list-header">
      <div class="list-header__text">
        <h5>Sepa Payments</h5>
      </div>

      <div class="list-header__actions">
        <router-link
          :to="{ name: 'create-sepa-payments' }"
          class="btn btn-primary"
          v-if="
            currentUser?.user_role.includes('Admin') ||
            currentUser?.user_role.includes('Payment Clearer') ||
            currentUser?.user_role.includes('Approver') ||
            currentUser?.user_role.includes('Payer')
          "
        >
          <i class="icon-add-new"></i>
          <span> New Sepa Payment </span>
        </router-link>
      </div>
    </div>
    <InocuDataTable
      v-model:items-selected="selectedSepa"
      v-model:server-options="serverOptions"
      :server-items-length="sepaListTotalCount"
      :loading="loading"
      :headers="sepaTableHeaders"
      :items="sepaList"
      :rows-items="[10, 25, 50, 100]"
      table-class-name="listing-custom-table"
      alternating
      buttons-pagination
      no-hover
      must-sort
    >
      <template #item-account_owner="sepa">
        {{ sepa.owner_name }}
      </template>
      <template #item-account_iban="sepa">
        {{ sepa.source_iban }}
      </template>
      <template #item-creation_date="sepa">
        {{ moment(sepa.created_on).format("DD.MM.YYYY") }}
      </template>
      <template #item-total_payed_amount="sepa">
        {{ sepa.amount ? $n(+sepa.amount, "currency") : "-" }}
      </template>
      <template #item-assigned_payment_no="sepa">
        {{
          sepa.payment_numbers && sepa.payment_numbers.length > 0
            ? sepa.payment_numbers
            : "N/A"
        }}
      </template>
      <template #item-actions="sepa">
        <div class="actions">
          <button @click="openSepaPopup(sepa)" title="View">
            <i class="icon-eye"></i>
          </button>
          <div class="export-sepa">
            <div class="dropdown dropstart dropdown-center" title="Export">
              <button
                class="btn btn-secondary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="icon-download"></i>
              </button>
              <ul class="dropdown-menu dropdown-menu-right">
                <li>
                  <a
                    class="dropdown-item pointer"
                    @click="exportSepa('xml', sepa.id)"
                  >
                    {{ $t("Order.DownloadAs_86") }}
                    xml
                  </a>
                </li>

                <li>
                  <a
                    class="dropdown-item pointer"
                    @click="exportSepa('pdf', sepa.id)"
                  >
                    {{ $t("Order.DownloadAs_86") }}
                    pdf
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </template>
    </InocuDataTable>
    <div v-if="isError" class="alert alert-danger w-50 mt-3" role="alert">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from "@/stores/user.store";
import { storeToRefs } from "pinia";
import { ref, type Ref, onMounted, watch } from "vue";
import moment from "moment";
import type { Header, ServerOptions } from "vue3-easy-data-table";
import { useI18n } from "vue-i18n";
import { useSepaStore } from "@/stores/sepa.store";
import { useToast } from "vue-toast-notification";

const { currentUser } = storeToRefs(useUserStore());
const { getSepaPayments, exportSepaPayments } = useSepaStore();

const props = defineProps({
  customer: {
    default: -1,
    type: [Number],
  },
  project: {
    default: -1,
    type: [Number],
  },
  date: {
    default: null,
    type: [Array],
  },
});

const { t, n } = useI18n();

const $toast = useToast();

const selectedSepa: any = ref([]);

const selectedSepasIds = ref([]);

const serverOptions = ref<ServerOptions>({
  page: 1,
  rowsPerPage: 10,
  sortBy: "creation_date",
  sortType: "asc",
});

const loading = ref(false);

const isError = ref(false);

let errorMessage = ref("");

const isExport = ref(false);

const isViewSepa = ref(false);

const currentSepaPayment: any = ref({});

const sepaTableHeaders: Ref<Header[]> = ref([
  {
    text: "Account Owner",
    value: "account_owner",
    sortable: true,
  },
  {
    text: "IBAN",
    value: "account_iban",
    sortable: true,
  },
  {
    text: "Creation Date",
    value: "creation_date",
    sortable: true,
  },
  {
    text: "Total Payed Amount",
    value: "total_payed_amount",
  },
  {
    text: "Assigned Payment No.",
    value: "assigned_payment_no",
  },
  { text: "", value: "actions" },
]);

const sepaList = ref([]);

const sepaListTotalCount = ref(0);

const openSepaPopup = (sepa: any) => {
  isViewSepa.value = true;
  currentSepaPayment.value = sepa;
};

const closePopup = () => {
  isExport.value = false;
  isViewSepa.value = false;
};

const exportSepa = async (fileFormat: "xml" | "pdf", id: number) => {
  try {
    await exportSepaPayments([id], fileFormat).then(() => {
      isExport.value = true;
    });
  } catch (error: any) {
    $toast.open({
      message: error.response.data.errors[0].detail,
      type: "error",
    });
  }
};

const getSepaLists = async (customer: number, project: number, date: any) => {
  loading.value = true;

  const sortBy = serverOptions.value.sortBy;
  const sortDirection = serverOptions.value.sortType === "asc" ? "" : "-";
  const customerId = customer !== -1 ? customer : null;
  const projectId = project !== -1 ? project : null;

  let startDate =
    date === null || date.length === 0
      ? null
      : moment(date[0]).format("YYYY-MM-DD");
  let endDate =
    date === null || date.length === 0
      ? null
      : moment(date[1]).format("YYYY-MM-DD");

  try {
    let response = await getSepaPayments({
      page: serverOptions.value.page,
      page_size: serverOptions.value.rowsPerPage,
      ordering: sortDirection + sortBy,
      customer: customerId,
      project: projectId,
      created_after: startDate,
      created_before: endDate,
    });

    sepaList.value = response.results;
    sepaListTotalCount.value = response.count;

    loading.value = false;

    return sepaList.value;
  } catch (error: any) {
    loading.value = false;
    errorMessage.value = error.response.data.errors[0].detail;
    setTimeout(() => {
      isError.value = false;
    }, 3000);
  }
};

onMounted(async () => {
  await getSepaLists(props.customer, props.project, props.date);
});

watch(
  () => props.customer,
  async (newVal) => {
    selectedSepa.value = [];
    await getSepaLists(newVal, props.project, props.date);
  }
);

watch(
  () => props.project,
  async (newVal) => {
    selectedSepa.value = [];
    await getSepaLists(props.customer, newVal, props.date);
  }
);

watch(
  () => props.date,
  async (newVal) => {
    selectedSepa.value = [];
    await getSepaLists(props.customer, props.project, newVal);
  }
);
</script>
<style scoped>
.dropdown-toggle:before {
  display: none !important;
}
</style>
