<template>
  <div class="sidebar" :class="{ 'sidebar-collapsed': isCollapsed }">
    <div class="sidebar__logo">
      <router-link :to="{ name: 'dashboard' }">
        <InocuLogoCollapsed v-if="isCollapsed" />
        <InocuLogo v-else />
      </router-link>
    </div>
    <h2>{{ $t("Sidebar.Title_1") }}</h2>
    <ul class="sidebar__main-items">
      <template v-for="(link, index) in sidebarLinks" :key="index">
        <li
          v-if="
            link.allowedRoles.some((allowedRole) =>
              currentUser?.user_role.includes(allowedRole)
            ) || link.routerName === 'my-profile'
          "
        >
          <router-link :to="{ name: link.routerName }">
            <i :class="link.iconClass"></i>
            <span>{{ $t(link.nameKey) }}</span>

            <template v-if="link.routerName === 'notification-center'">
              <div v-if="notReadNotificationsCount" class="notifications-badge">
                {{ notReadNotificationsCount }}
              </div>
            </template>
          </router-link>

          <ul v-if="link.subLinks" class="sidebar__sub-items">
            <template v-for="(subLink, index) in link.subLinks" :key="index">
              <li
                v-if="
                  subLink.allowedRoles.some((allowedRole) =>
                    currentUser?.user_role.includes(allowedRole)
                  )
                "
              >
                <template v-if="subLink.routerName === 'create-customer'">
                  <a @click="changeCustomerEditMode(false)">
                    <span>{{ $t("Sidebar.AddCustomer_4") }}</span>
                  </a>
                </template>

                <template v-else>
                  <router-link :to="{ name: subLink.routerName }">
                    <span>{{ $t(subLink.nameKey) }}</span>
                  </router-link>
                </template>
              </li>
            </template>
          </ul>
        </li>
      </template>
    </ul>
    <!--  -->
    <div
      class="card customer-detail-card"
      v-if="
        (loading && currentUser?.user_role.includes('Order Clearer')) ||
        currentUser?.user_role.includes('Approver') ||
        currentUser?.user_role.includes('Payment Clearer')
      "
    >
      <div class="card-body">
        <div class="customer-name">{{ customer.name }}</div>
        <div class="customer-limit">
          {{ $t("Order.Customer_Limit_105") }}:
          <span>{{ $n(+customer.customer_limit, "currency") }}</span>
        </div>
        <div class="customer-remaining">
          {{ $t("Order.Remaining_Limit_104") }}:
          <span
            ><span
              style="color: #09cf97"
              :style="
                currentBalance >= 0 ? 'color: #09cf97;' : 'color: #fa5c7c;'
              "
            >
              {{ $n(+currentBalance, "currency") }}
            </span></span
          >
        </div>
      </div>
    </div>
    <!--  -->
  </div>
</template>

<script setup lang="ts">
import InocuLogo from "../../assets/images/inocu-logo.svg";
import InocuLogoCollapsed from "../../assets/images/inocu-logo-icon.svg";
import router from "@/router";
import { useUserStore } from "@/stores/user.store";
import { SideBarLinks } from "./side-bar.config";
import { storeToRefs } from "pinia";
import { useNotificationsStore } from "@/stores/notifications.store";
import { useCustomerStore } from "@/stores/customer.store";
import { useOrderStore } from "@/stores/order.store";

import { ref, onMounted } from "vue";
const sidebarLinks = SideBarLinks;

const { currentUser } = useUserStore();
const { notReadNotificationsCount } = storeToRefs(useNotificationsStore());
const { getMainDataById } = useCustomerStore();
const { getCustomerCurrentBalance } = useOrderStore();

defineProps({
  isCollapsed: Boolean,
});

const customer = ref({
  name: "",
  customer_limit: "",
});

const loading = ref(false);

const currentBalance: any = ref(0);

const changeCustomerEditMode = (boolean: any) => {
  localStorage.setItem("isEditCustomer", boolean);
  router.push({ name: "create-customer" });
};

onMounted(() => {
  let customerId = localStorage.getItem("selectedCustomerId");

  if (customerId !== null) {
    getMainDataById(customerId)
      .then((response) => {
        customer.value = response;
        loading.value = true;
      })
      .catch((error) => console.log(error));

    if (
      currentUser?.user_role.includes("Order Clearer") ||
      currentUser?.user_role.includes("Approver") ||
      currentUser?.user_role.includes("Payment Clearer")
    ) {
      getCustomerCurrentBalance(customerId, null).then((response) => {
        currentBalance.value = response.current_balance;
      });
    }
  }
});
</script>

<style scoped lang="scss">
@import "./sidebar.scss";
.customer-detail-card {
  margin-left: -20px;
  margin-right: -20px;
  border-left: 4px solid #ceec34;
  .card-body {
    padding: 10px;
  }
  .customer-name {
    color: #8391a2 !important;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .customer-limit {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    font-size: 13px;
    color: var(--bs-dropdown-link-color);
    margin-bottom: 4px;
  }
  .customer-remaining {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    font-size: 13px;
    color: var(--bs-dropdown-link-color);
  }
}
</style>
