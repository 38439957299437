<template>
  <div class="generic-popup" v-if="isPopup">
    <div class="popup-wrapper">
      <div class="popup-header">
        <h4 class="popup-title">{{ $t("CustomerManagement.Delete_67") }}</h4>
        <span class="popup-close" @click="closePopup">
          <i class="icon-close"></i>
        </span>
      </div>
      <div class="popup-body">
        <h5>
          {{ $t("CustomerManagement.DeletePopupTitle_114") }}
          {{ projectName }} ?
        </h5>
        <div class="options">
          <button class="save" @click="onChangeProjectDelete">
            {{ $t("CustomerManagement.Delete_67") }}
          </button>
          <button class="cancel" @click="closePopup">
            {{ $t("CustomerManagement.Cancel_113") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="list-header">
    <div class="list-header__text">
      <h5>{{ $t("CustomerManagement.Projects_63") }}</h5>
    </div>

    <div class="list-header__actions">
      <div class="bulk-actions" v-if="selectedProjects.length">
        <button class="btn btn-primary" @click="onChangeBulkProjectsDelete()">
          {{ $t("CustomerManagement.DeleteProjects_116") }}
        </button>
      </div>

      <a @click="changeEditMode(false)" class="btn btn-primary">
        <i class="icon-add-new"></i>
        <span>{{ $t("CustomerManagement.NewProject_64") }}</span>
      </a>

      <!-- <input
        type="text"
        name="searchProjectsList"
        id="searchProjectsList"
        :placeholder="$t('CustomerManagement.Search_4')"
        class="form-control"
        @input="debounceSearchProjects"
      /> -->

      <!-- <FilterList @filterList="getAllProjectsByFilter($event)" /> -->
    </div>
  </div>

  <InocuDataTable
    v-model:items-selected="selectedProjects"
    v-model:server-options="serverOptions"
    :server-items-length="projectsListTotalCount"
    :loading="loading"
    :headers="projectsTableHeaders"
    :items="projectsList"
    :rows-items="[10, 25, 50, 100]"
    theme-color="#CEEC34"
    table-class-name="listing-custom-table"
    alternating
    buttons-pagination
    no-hover
    must-sort
  >
    <!-- Filtrable header cells -->
    <template #header-name="header">
      <div class="filter-column" @click.stop>
        <i
          class="icon-filter filter-icon"
          @click.stop="showNameFilter = !showNameFilter"
        ></i>
        {{ header.text }}
        <div class="filter-menu" v-if="showNameFilter">
          <input
            v-model="projectsListSearchTerm"
            type="text"
            class="form-control"
            @input="debounceSearchProjects"
          />
        </div>
      </div>
    </template>

    <template #item-name="project"> {{ project.name }} </template>

    <template #item-project_limit="project">
      {{ project.project_limit }}
    </template>

    <template #item-auto_approval_limit="project">
      {{ project.auto_approval_limit }}
    </template>

    <template #item-project_limit_frequency="project">
      {{ toCapitalize(project.project_limit_frequency) }}
    </template>

    <template #item-actions="project">
      <div class="actions">
        <span @click="editProject(project.id, true)">
          <i class="icon-edit"></i>
        </span>
        <span @click="openDeletePopup(project.name, project.id)">
          <i class="icon-delete"></i>
        </span>
      </div>
    </template>

    <!-- No Data View -->
    <template #empty-message>
      {{ $t("CustomerManagement.NoProjectsToShow_145") }}...
    </template>
  </InocuDataTable>
</template>

<script setup lang="ts">
import FilterList from "@/components/shared/filters/project-filter-list/FilterList.vue";
import { onMounted, ref, type Ref, watch } from "vue";
import router from "@/router";
import debounce from "lodash/debounce";
import type { Header, ServerOptions } from "vue3-easy-data-table";
import { useToast } from "vue-toast-notification";
import { useFilters } from "@/_helpers/composables/global-filters.composable";
import { useProjectStore } from "@/stores/project.store";
import { useI18n } from "vue-i18n";
const { t, n } = useI18n();

const { toCapitalize } = useFilters();
const { getProjects, deleteProject } = useProjectStore();

const $toast = useToast();

const projectsTableHeaders: Ref<Header[]> = ref([
  {
    text: t("CustomerManagement.Name_61"),
    value: "name",
    width: 350,
    sortable: true,
  },
  {
    text: t("CustomerManagement.ProjectLimit_65"),
    value: "project_limit",
    width: 300,
  },
  {
    text: t("CustomerManagement.AutomaticApprovalLimit_14"),
    value: "auto_approval_limit",
  },
  {
    text: t("CustomerManagement.ProjectLimitFrequency_46"),
    value: "project_limit_frequency",
  },
  { text: t("CustomerManagement.Actions_117"), value: "actions" },
]);

const showNameFilter = ref(false);
const projectsList: any = ref([]);
const selectedProjects: any = ref([]);
const loading = ref(false);
const projectsListTotalCount = ref(0);
const customerId: any = ref("");

const serverOptions = ref<ServerOptions>({
  page: 1,
  rowsPerPage: 10,
  sortBy: "name",
  sortType: "asc",
});

const projectsListSearchTerm: Ref<string> = ref("");
const projectsListFilter: any = ref("");

let projectId = "";
let projectName = "";

const isPopup = ref(false);

onMounted(async () => {
  customerId.value = localStorage.getItem("selectedCustomerId");
  await getProjectsTableList();
});

// Fires when page number changes or items per page value changes
watch(
  serverOptions,
  async () => {
    await getProjectsTableList();
  },
  {
    deep: true,
  }
);

const getProjectsTableList = async () => {
  loading.value = true;

  const sortBy = serverOptions.value.sortBy;
  const sortDirection = serverOptions.value.sortType === "asc" ? "" : "-";

  const response = await getProjects(
    {
      page: serverOptions.value.page,
      page_size: serverOptions.value.rowsPerPage,
      search: projectsListSearchTerm.value!,
      ordering: sortDirection + sortBy,
      is_active: true,
      customer: customerId.value,
    },
    projectsListFilter.value
  );

  projectsList.value = [...response.results];
  projectsList.value.map((item: any) => {
    item.project_limit = n(+item.project_limit, "currency");
    item.auto_approval_limit = n(+item.auto_approval_limit, "currency");
  });
  projectsListTotalCount.value = response.count;

  loading.value = false;
};

const onChangeBulkProjectsDelete = async () => {
  const selectedProjectsIds = selectedProjects.value.map((user) => user.id);

  await deleteProject(selectedProjectsIds);

  await getProjectsTableList();

  $toast.open({
    message: `All selected projects have been deleted successfully.`,
    type: "success",
  });

  selectedProjects.value = [];
};

const onChangeProjectDelete = async () => {
  closePopup();
  await deleteProject([projectId]);
  await getProjectsTableList();

  $toast.open({
    message: `Project (${projectName}) deleted successfully.`,
    type: "success",
  });
};

const debounceSearchProjects = debounce(async function (event) {
  projectsListSearchTerm.value = event.target.value;
  await getProjectsTableList();
}, 300);

const getAllProjectsByFilter = async (filterData) => {
  projectsListFilter.value = filterData;
  await getProjectsTableList();
};

const changeEditMode = (boolean: any) => {
  localStorage.setItem("isEditProject", boolean);
  router.push({ name: "create-project" });
};

const openDeletePopup = (name: any, id: any) => {
  isPopup.value = true;
  projectId = id;
  projectName = name;
};

const closePopup = () => {
  isPopup.value = false;
};

const editProject = (id: any, boolean: any) => {
  localStorage.setItem("isEditProject", boolean);
  localStorage.setItem("projectId", id);
  router.push({ name: "create-project" });
};
</script>
