<template>
  <div class="generic-list-page">
    <div class="row">
      <div
        :class="
          !(
            currentUser?.user_role.includes('Purchaser') ||
            currentUser?.user_role.includes('Payer') ||
            currentUser?.user_role.includes('Order Clearer') ||
            currentUser?.user_role.includes('Payment Clearer') ||
            currentUser?.user_role.includes('Approver')
          )
            ? 'col-lg-3 col-md-6 col-12'
            : 'col-lg-6 col-md-6 col-12'
        "
      >
        <h1 class="page-title">{{ $t("Order.Orders_72") }}</h1>
      </div>
      <div
        class="col-lg-3 col-md-6 col-12"
        v-if="
          !(
            currentUser?.user_role.includes('Purchaser') ||
            currentUser?.user_role.includes('Payer') ||
            currentUser?.user_role.includes('Order Clearer') ||
            currentUser?.user_role.includes('Payment Clearer') ||
            currentUser?.user_role.includes('Approver')
          )
        "
      >
        <div class="form-input mb-lg-0">
          <LegacyMultiSelect
            v-model="orderFilter.customer"
            name="customers"
            :object="false"
            :options="customersList"
            :resolve-on-load="true"
            :min-chars="0"
            :placeholder="$t('Order.SelectCustomer_2')"
            :closeOnSelect="true"
            :clearOnBlur="false"
            :searchable="true"
            valueProp="id"
            label="name"
          />
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <div class="form-input mb-lg-0">
          <LegacyMultiSelect
            v-model="orderFilter.project"
            name="projects"
            :object="false"
            :options="projectsList"
            :resolve-on-load="true"
            :min-chars="0"
            :placeholder="$t('Order.SelectProject_5')"
            :closeOnSelect="true"
            :clearOnBlur="false"
            :searchable="true"
            valueProp="id"
            label="name"
          />
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-12 ms-auto text-right">
        <VueDatePicker
          v-model="orderFilter.date"
          :placeholder="$t('Order.SelectDate_49')"
          :enable-time-picker="false"
          range
          :select-text="$t('Order.Apply_50')"
          auto-apply
          :max-date="new Date()"
          :preset-ranges="presetRanges"
          :format="formatDate"
        >
          <template #yearly="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}</span
            >
          </template>
          <template #last_year="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}</span
            >
          </template>
          <template #last_month="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}</span
            >
          </template>
          <template #this_month="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}</span
            >
          </template>
          <template #last_week="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}</span
            >
          </template>
          <template #last_30_days="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}</span
            >
          </template>
        </VueDatePicker>
      </div>
    </div>
    <div class="list-table">
      <!-- Tabs -->
      <ul class="orders-tabs">
        <li :class="!$route.query.status ? 'active' : ''">
          <router-link :to="{ name: 'recurring-orders' }" class="dropdown-item">
            {{ $t("Order.AllTypes_89") }}</router-link
          >
        </li>
        <li
          :class="$route.query.status === 'PENDING_APPROVAL' ? 'active' : ''"
          v-if="showPendingTab()"
        >
          <router-link
            :to="{
              name: 'recurring-orders',
              query: { status: 'PENDING_APPROVAL' },
            }"
            class="dropdown-item"
          >
            <div
              class="status-indicator status-indicator--pending_approval"
            ></div>
            {{ $t("Order.Pending_90") }}
          </router-link>
        </li>
        <!-- Hide this tab for clearers -->
        <li
          v-if="!currentUser?.user_role?.includes('Order Clearer')"
          :class="$route.query.status === 'CLEARING' ? 'active' : ''"
        >
          <router-link
            :to="{ name: 'recurring-orders', query: { status: 'CLEARING' } }"
            class="dropdown-item"
          >
            <div class="status-indicator status-indicator--clearing"></div>
            {{ $t("Order.Clearing_141") }}
          </router-link>
        </li>
        <li :class="$route.query.status === 'APPROVED' ? 'active' : ''">
          <router-link
            :to="{ name: 'recurring-orders', query: { status: 'APPROVED' } }"
            class="dropdown-item"
          >
            <div class="status-indicator status-indicator--approved"></div>
            {{ $t("Order.Approved_91") }}</router-link
          >
        </li>
        <li :class="$route.query.status === 'REJECTED' ? 'active' : ''">
          <router-link
            :to="{ name: 'recurring-orders', query: { status: 'REJECTED' } }"
            class="dropdown-item"
          >
            <div class="status-indicator status-indicator--rejected"></div>
            {{ $t("Order.Rejected_92") }}</router-link
          >
        </li>
        <li :class="$route.query.status === 'DRAFT' ? 'active' : ''">
          <router-link
            :to="{ name: 'recurring-orders', query: { status: 'DRAFT' } }"
            class="dropdown-item"
          >
            <div class="status-indicator status-indicator--draft"></div>
            {{ $t("Order.Drafts_93") }}</router-link
          >
        </li>
      </ul>
      <div>
        <RecurringOrdersTable
          :status="
            typeof $route.query.status === 'string' ? $route.query.status : ''
          "
          :customer="orderFilter.customer ? orderFilter.customer : -1"
          :project="orderFilter.project ? orderFilter.project : -1"
          :date="orderFilter.date"
        />
      </div>
      <!-- Tabs -->
    </div>
  </div>
</template>

<script setup lang="ts">
import Multiselect from "@vueform/multiselect";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { ref, reactive, onMounted, watch, onBeforeMount } from "vue";
import { useProjectStore } from "@/stores/project.store";
import { useCustomerStore } from "@/stores/customer.store";
import RecurringOrdersTable from "@/components/shared/tables/recurring-orders-table/RecurringOrdersTable.vue";
import moment from "moment";
import {
  endOfMonth,
  endOfYear,
  endOfWeek,
  startOfMonth,
  startOfYear,
  startOfWeek,
  subMonths,
  subYears,
  subWeeks,
  subDays,
} from "date-fns";
import { useUserStore } from "@/stores/user.store";
import { storeToRefs } from "pinia";

const { getCustomersOptions } = useCustomerStore();
const { getProjectsByCustomer } = useProjectStore();
const { currentUser } = storeToRefs(useUserStore());

let customersList: any = ref([]);

let projectsList: any = ref([]);

let orderFilter: any = reactive({
  customer: null,
  project: null,
  date: [],
});

/* Get Project By Customer id*/
const getProjectsTableList = async (customerId: any) => {
  const response = await getProjectsByCustomer({
    customer: customerId,
    is_active: true,
  });

  projectsList.value = [...response.results];
};

watch(
  () => orderFilter.customer,
  async (newVal) => {
    orderFilter.project = null;

    await getProjectsTableList(newVal);
    /* if customer is null then list also be empty */
    if (newVal === null) {
      projectsList.value = [];
    }
  }
);

const formatDate = (date: Date) => {
  const date1 = moment(date[0]).format("DD.MM.YYYY");
  let date2 = moment(date[1]).format("DD.MM.YYYY");

  if (new Date(date[1]) > new Date()) {
    date2 = moment(new Date()).format("DD.MM.YYYY");
  }

  return `${date1} To ${date2}`;
};
const presetRanges = ref([
  {
    label: "Last Week",
    range: [
      startOfWeek(subWeeks(new Date(), 1)),
      endOfWeek(subWeeks(new Date(), 1)),
    ],
    slot: "last_week",
  },
  {
    label: "This month",
    range: [startOfMonth(new Date()), endOfMonth(new Date())],
    slot: "this_month",
  },
  {
    label: "Last month",
    range: [
      startOfMonth(subMonths(new Date(), 1)),
      endOfMonth(subMonths(new Date(), 1)),
    ],
    slot: "last_month",
  },
  {
    label: "Last 30 days",
    range: [subDays(new Date(), 30), new Date()],
    slot: "last_30_days",
  },
  {
    label: "Last Year",
    range: [
      startOfYear(subYears(new Date(), 1)),
      endOfYear(subYears(new Date(), 1)),
    ],
    slot: "last_year",
  },
  {
    label: "This year",
    range: [startOfYear(new Date()), endOfYear(new Date())],
    slot: "yearly",
  },
]);

const showPendingTab = () => {
  if (currentUser.value?.user_role.includes("Admin")) {
    return false;
  }
  if (
    currentUser.value?.user_role.includes("Order Clearer") ||
    currentUser.value?.user_role.includes("Approver")
  ) {
    return true;
  }
  if (currentUser.value?.user_role.includes("Purchaser")) {
    return false;
  }
  return true;
};

onBeforeMount(() => {
  let getStoreCustomerId = localStorage.getItem("selectedCustomerId");
  getStoreCustomerId ? (orderFilter.customer = +getStoreCustomerId) : null;
});
onMounted(async () => {
  customersList.value = await getCustomersOptions();
});
</script>
