<template>
  <div class="create-page">
    <h4 class="page-header">{{ $t("Suppliers.NewMasterPage_1") }}</h4>

    <div class="create-page-content">
      <div class="item-data-header">{{ $t("Suppliers.BankAccount_43") }}</div>

      <form
        @submit="onSubmitBankAccountForm"
        class="item-data-form container-fluid d-flex flex-column"
      >
        <!-- Owner and Bank Name -->
        <div class="row justify-content-between">
          <div class="form-group col-lg-5">
            <div class="form-label">
              {{ $t("Suppliers.AccountOwner_44") }}
              <span class="text-danger">*</span>
            </div>

            <input
              v-model="ownerName"
              name="ownerName"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.ownerName }"
              :placeholder="$t('Suppliers.TypeNameHere_4') + '...'"
            />

            <div class="invalid-feedback">{{ errors.ownerName }}</div>
          </div>

          <div class="form-group col-lg-5">
            <div class="form-label">
              {{ $t("Suppliers.BankName_45") }}
              <span class="text-danger">*</span>
            </div>

            <input
              v-model="bankName"
              name="bankName"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.bankName }"
              :placeholder="$t('Suppliers.TypeNameHere_4') + '...'"
            />

            <div class="invalid-feedback">{{ errors.bankName }}</div>
          </div>
        </div>

        <!-- SWIFT Code and IBAN -->
        <div class="row justify-content-between mt-4">
          <div class="form-group col-lg-5">
            <div class="form-label">
              {{ $t("Suppliers.SwiftCode_46") }}
              <span class="text-danger">*</span>
            </div>

            <input
              v-model="swiftCode"
              name="swiftCode"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.swiftCode }"
              :placeholder="$t('Suppliers.TypeSwiftCodeHere_47') + '...'"
            />

            <div class="invalid-feedback">{{ errors.swiftCode }}</div>
          </div>

          <div class="form-group col-lg-5">
            <div class="form-label">
              {{ $t("Suppliers.IBAN_48") }}
              <span class="text-danger">*</span>
            </div>

            <input
              v-model="iban"
              name="iban"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.iban }"
              :placeholder="$t('Suppliers.TypeIbanHere_49') + '...'"
            />

            <div class="invalid-feedback">{{ errors.iban }}</div>
          </div>
        </div>

        <!-- customer or supplier -->
        <div class="row justify-content-between mt-4">
          <div class="form-group col-lg-5" v-if="!hideCustomerSelection">
            <div class="form-label">
              {{ $t("Suppliers.Customer_53") }}
              <span class="text-danger">*</span>
            </div>

            <LegacyMultiSelect
              :key="customerId"
              v-model="customerId"
              name="customerId"
              mode="single"
              :options="customerList"
              :searchable="true"
              valueProp="id"
              label="name"
              :placeholder="$t('Suppliers.ChooseCustomer_55')"
              :clearOnBlur="false"
              :clearable="false"
              :class="{ 'is-invalid': errors.customerId }"
              @search-change="debounceFilterCustomersList"
            />
            <div class="invalid-feedback">
              {{ errors.customerId }}
            </div>
          </div>
          <div
            class="form-group col-lg-5"
            v-if="associatedPersona === 'supplier'"
          >
            <div class="form-label">
              {{ $t("Suppliers.Supplier_54") }}
              <span class="text-danger">*</span>
            </div>
            <LegacyMultiSelect
              :key="supplierId"
              :disabled="!customerId"
              :loading="isSupplierLoading"
              v-model="supplierId"
              name="supplierId"
              ref="supplierSelectRef"
              mode="single"
              :options="supplierList"
              :searchable="true"
              valueProp="id"
              label="display_name"
              :placeholder="$t('Suppliers.ChooseSupplier_56')"
              :clearOnBlur="false"
              :clearable="false"
              :class="{ 'is-invalid': errors.supplierId }"
              @search-change="debounceFilterSuppliersList"
            />
            <div class="invalid-feedback" v-if="errors.supplierId">
              {{ errors.supplierId }}
            </div>
          </div>
        </div>

        <div
          v-if="bankAccountCreatedSuccessfully != null"
          class="result-alert mt-auto"
        >
          <div
            v-if="bankAccountCreatedSuccessfully && !meta.dirty"
            class="col-lg-7 alert alert-success"
            role="alert"
          >
            {{ $t("Suppliers.BankAccountCreatedSuccessfully_50") }}
          </div>
          <div
            v-if="!bankAccountCreatedSuccessfully"
            class="col-lg-7 alert alert-danger"
            role="alert"
          >
            {{ errorMessage }}
          </div>
        </div>

        <div
          class="create-item-footer"
          :class="{
            'mt-auto': bankAccountCreatedSuccessfully === null,
          }"
        >
          <button
            type="submit"
            class="btn btn-primary"
            :disabled="isSubmitting"
          >
            {{ $t("Suppliers.AddBankAccount_51") }}
          </button>
          <button
            type="button"
            class="btn btn-grey"
            @click="backToBankAccountsList"
            :disabled="isSubmitting"
          >
            {{ $t("Suppliers.Discard_19") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, type Ref, watch } from "vue";

import { useBankAccountForm } from "@/_helpers/composables/master-data/bank-account-changing.composable";
import { useCustomerStore } from "@/stores/customer.store";
import { useSupplierStore } from "@/stores/supplier.store";
import { useBankAccountStore } from "@/stores/bank-account.store";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user.store";
import type { Customer } from "@/_helpers/interfaces/customers/customer.interface";
import { useToast } from "vue-toast-notification";
import { debounce } from "lodash";
import { useI18n } from "vue-i18n";

const { currentUser } = storeToRefs(useUserStore());
const $toast = useToast();
const { t } = useI18n();

const {
  errors,
  handleSubmit,
  isSubmitting,
  meta,
  resetForm,
  ownerName,
  bankName,
  swiftCode,
  iban,
  associatedPersona,
  customerId,
  supplierId,
  backToBankAccountsList,
  resetField,
} = useBankAccountForm();

const router = useRouter();

const hideCustomerSelection = ref(false);
const bankAccountCreatedSuccessfully: Ref<boolean | null> = ref(null);
const errorMessage = ref("");

const { createBankAccount } = useBankAccountStore();

const onSubmitBankAccountForm = handleSubmit(async (values) => {
  try {
    await createBankAccount({
      owner_name: values.ownerName,
      bank_name: values.bankName,
      swift_code: values.swiftCode,
      iban: values.iban,
      customer: values.customerId,
      supplier:
        associatedPersona.value === "supplier" ? values.supplierId : null,
    });

    let oldPersona = associatedPersona.value;
    let oldCustomerId = customerId.value;
    bankAccountCreatedSuccessfully.value = true;
    resetForm();
    associatedPersona.value = oldPersona;
    const { value: user } = currentUser;
    if (!user?.user_role.includes("Admin")) {
      customerId.value = oldCustomerId;
    }
    supplierId.value = null;
    $toast.open({
      message: t("Suppliers.BankAccountCreatedSuccessfully_83"),
      type: "success",
    });
  } catch (error: any) {
    bankAccountCreatedSuccessfully.value = false;
    errorMessage.value = error.response.data.errors[0].detail;
  }
});

const { getCustomersList } = useCustomerStore();
const { getSuppliersList } = useSupplierStore();

const customerList: Ref<Array<Customer>> = ref([]);
const supplierList: Ref<Array<any>> = ref([]);
const supplierSelectRef: Ref<any> = ref(null);
const isSupplierLoading = ref(false);

const filterCustomersList = async (query: string, _element: any = null) => {
  const { results } = await getCustomersList({
    name: query,
    page_size: 500,
  });
  customerList.value = results;
};

const debounceFilterCustomersList = debounce(filterCustomersList, 500);

const filterSuppliersList = async (query: string, _element: any = null) => {
  let options = {
    search: query,
    page_size: 200,
  };
  if (!customerId.value) {
    return [];
  }
  options["customer"] = customerId.value;
  isSupplierLoading.value = true;
  const { results } = await getSuppliersList(options);
  isSupplierLoading.value = false;

  supplierList.value = results.map((supplier: any) => {
    return {
      ...supplier,
      display_name: `${supplier.name} - ${supplier.supplier_number}`,
    };
  });
};

const debounceFilterSuppliersList = debounce(filterSuppliersList, 500);

onMounted(async () => {
  const query = router.currentRoute.value.query;
  const queryType = Array.isArray(query.type) ? query.type[0] : query.type;
  const { value: user } = currentUser;
  const userIsAdmin = user?.user_role.includes("Admin");

  associatedPersona.value = queryType;
  hideCustomerSelection.value = !userIsAdmin;

  if (userIsAdmin) {
    await filterCustomersList("");
  }

  if (!userIsAdmin) {
    customerId.value = +localStorage.getItem("selectedCustomerId")!;
  }
});

// watch customerId and load suppliers list
watch(customerId, async (newCustomerId) => {
  if (newCustomerId) {
    await filterSuppliersList("");
  }
  if (supplierId.value) {
    supplierSelectRef.value?.clear();
    resetField("supplierId");
  }
});
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables/_variables.scss";

.associated-persona-label {
  color: $base-dark-gray;
}
</style>
