<template>
  <div class="form-wrapper">
    <div class="generic-form comment-box">
      <span> {{ $t("Order.Comments_71") }} </span>
      <!-- Comments -->
      <ul class="comments" id="commentBox">
        <li class="comment" v-for="(item, index) in orderComments" :key="index">
          <div class="header">
            <div class="user">
              <div class="image">
                <img
                  :src="item.author.profile_picture"
                  alt="avatar"
                  class="rounded-circle"
                  v-if="item.author.profile_picture"
                />
                <img
                  src="/src/assets/images/user-avatar.jpg"
                  alt="avatar"
                  class="rounded-circle"
                  v-else
                />
              </div>
              <div class="name">{{ item.author.display_name }}</div>
            </div>
            <div class="date">
              {{ moment(item.created_on).format("DD.MM.YYYY") }}
            </div>
          </div>
          <div class="body">
            <p>
              {{ item.text }}
            </p>
          </div>
        </li>
      </ul>
      <!-- Comments -->
      <!-- Post Comment -->
      <div
        class="post-comment-box"
        title="The comments is allowed in Pending and Request Clarification Status"
      >
        <div>
          <input
            type="text"
            v-model="comment"
            @keypress.enter="addComment()"
            :placeholder="$t('Order.TypeYourMessageHere_128')"
            :disabled="isCommentingDisabled()"
            :style="isCommentingDisabled() ? 'cursor: not-allowed;' : ''"
          />
        </div>
        <button
          @click="addComment()"
          :disabled="isCommentingDisabled()"
          :style="isCommentingDisabled() ? 'cursor: not-allowed;' : ''"
        >
          <i class="icon-arrow-right"></i>
          <span> {{ $t("Global.Send_18") }} </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUpdated, ref } from "vue";
import moment from "moment";
import { useToast } from "vue-toast-notification";
import { useOrderStore } from "@/stores/order.store";
import { useRouter } from "vue-router";
import { useRecurringInstructionStore } from "@/stores/recurring-instructions.store";

const $toast = useToast();

const { getOrderComments, createOrderComments } = useOrderStore();
const { getRecurringInstructionComments, createRecurringInstructionComment } =
  useRecurringInstructionStore();

const router = useRouter();

const props = defineProps({
  order: {
    type: Object,
    default: () => {},
  },
  recurringInstruction: {
    type: Object,
    default: () => {},
  },
});

let orderComments: any = ref([]);

let comment: any = ref("");

const orderId: any = ref(null);

onUpdated(() => {
  const container = document.querySelector("#commentBox");
  if (container) {
    container.scrollIntoView({ behavior: "smooth" });
    container.scrollTop = container.scrollHeight;
  }
});

const getComments = async () => {
  if (props.recurringInstruction) {
    orderComments.value = await getRecurringInstructionComments(orderId.value);
  } else {
    const response = await getOrderComments(orderId.value);
    orderComments.value = response.results;
  }
};

const addComment = async () => {
  if (comment.value == "") return;

  if (props.recurringInstruction) {
    await createRecurringInstructionComment(orderId.value, comment.value)
      .then(async () => {
        $toast.open({
          message: `Comment Added.`,
          type: "success",
        });
        await getComments();
        comment.value = "";
      })
      .catch((error) => console.log(error));
  } else {
    await createOrderComments({ order: orderId.value, text: comment.value })
      .then(async () => {
        $toast.open({
          message: `Comment Added.`,
          type: "success",
        });
        await getComments();
        comment.value = "";
      })
      .catch((error) => console.log(error));
  }
};

const isCommentingDisabled = () => {
  const status: string =
    props.order?.status || props.recurringInstruction?.status;
  return ["DRAFT", "APPROVED", "REJECTED"].includes(status);
};

onMounted(async () => {
  orderId.value = +router.currentRoute.value.params.id;
  /* Get Order Comments */
  await getComments();
});
</script>
