<template>
  <div class="customer">
    <div class="generic-popup" v-if="isCancelPopup">
      <div class="popup-wrapper">
        <div class="popup-header">
          <h4 class="popup-title">{{ $t("Order.DeleteOrder_74") }}</h4>
          <span class="popup-close" @click="closePopup">
            <i class="icon-close"></i>
          </span>
        </div>
        <div class="popup-body">
          <h5>Are You sure to discard this order ?</h5>
          <div class="options">
            <button class="save" @click="onChangeOrderCancel">
              {{ $t("Order.CancelDiscard_55") }}
            </button>
            <button class="cancel" @click="closePopup">
              {{ $t("Order.CancelPopup_82") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- New Supplier Popup -->
    <div class="generic-popup" style="z-index: 100" v-if="isSupplierPopup">
      <div class="popup-wrapper popup-wrapper-lg">
        <div class="popup-header">
          <h4 class="popup-title">Add New Supplier</h4>
          <span class="popup-close" @click="closeNewSupplierModal">
            <i class="icon-close"></i>
          </span>
        </div>
        <div class="popup-body">
          <!-- Supplier Form -->
          <form @submit.prevent="addNewSupplier">
            <div class="row">
              <!-- NAME -->
              <div class="col-md-6 mb-3">
                <div class="form-group">
                  <div class="form-label">
                    {{ $t("Suppliers.SupplierName_3") }}
                    <span class="text-danger">*</span>
                  </div>
                  <input
                    v-model="supplierName"
                    name="supplierName"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': errors.supplierName }"
                    :placeholder="$t('Suppliers.TypeNameHere_4') + '...'"
                  />

                  <div class="invalid-feedback">{{ errors.supplierName }}</div>
                </div>
              </div>
              <!-- SUPPLIER NUMBER -->
              <div class="col-md-6 mb-3">
                <div class="form-group">
                  <div class="form-label">
                    {{ $t("Suppliers.SupplierNumber_72") }}
                    <span class="text-danger">*</span>
                  </div>
                  <input
                    v-model="supplierNumber"
                    name="supplierNumber"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': errors.supplierNumber }"
                    :placeholder="$t('Suppliers.TypeNumberHere_73') + '...'"
                  />

                  <div class="invalid-feedback">
                    {{ errors.supplierNumber }}
                  </div>
                </div>
              </div>
              <input hidden :value="formData.customer" />
              <!-- ADDRESS -->
              <div class="col-md-6 mb-3">
                <div class="form-group">
                  <div class="form-label">
                    {{ $t("Suppliers.Address_5") }}
                  </div>
                  <input
                    v-model="address"
                    name="address"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': errors.address }"
                    :placeholder="$t('Suppliers.TypeAddressHere_6') + '...'"
                  />

                  <div class="invalid-feedback">{{ errors.address }}</div>
                </div>
              </div>
              <!-- ZIP CODE -->
              <div class="col-md-6 mb-3">
                <div class="form-group">
                  <div class="form-label">
                    {{ $t("Suppliers.ZipCode_7") }}
                  </div>
                  <input
                    v-model="zipCode"
                    name="zipCode"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': errors.zipCode }"
                    :placeholder="$t('Suppliers.TypeZipCodeHere_8') + '...'"
                  />

                  <div class="invalid-feedback">{{ errors.zipCode }}</div>
                </div>
              </div>
              <!-- COUNTRY -->
              <div class="col-md-6 mb-3">
                <div class="form-group">
                  <div class="form-label">{{ $t("Suppliers.Country_11") }}</div>
                  <LegacyMultiSelect
                    v-model="country"
                    name="country"
                    mode="single"
                    :options="countriesList"
                    valueProp="name"
                    label="displayed_name"
                    :searchable="true"
                    :placeholder="$t('Suppliers.TypeCountryHere_12') + '...'"
                    :clearOnBlur="false"
                    :class="{
                      'is-invalid': errors.country,
                    }"
                  />
                  <div class="invalid-feedback">{{ errors.country }}</div>
                </div>
              </div>
              <!-- CITY -->
              <div class="col-md-6 mb-3">
                <div class="form-group">
                  <div class="form-label">{{ $t("Suppliers.City_9") }}</div>
                  <LegacyMultiSelect
                    v-model="city"
                    name="city"
                    mode="single"
                    :noOptionsText="
                      country
                        ? $t('Suppliers.NoCitiesInThisCountry_31')
                        : $t('Suppliers.PleaseSelectCountryFirst_26')
                    "
                    :options="citiesList"
                    valueProp="name"
                    label="name"
                    :searchable="true"
                    :placeholder="$t('Suppliers.TypeCityHere_10') + '...'"
                    :clearOnBlur="false"
                    :class="{
                      'is-invalid': errors.city,
                    }"
                  />
                  <div class="invalid-feedback">{{ errors.city }}</div>
                </div>
              </div>
              <!-- PHONE -->
              <div class="col-md-6 mb-3">
                <div class="form-group">
                  <div class="form-label">{{ $t("Suppliers.Phone_13") }}</div>
                  <PhoneNumberInput
                    v-model="phone"
                    name="phone"
                    size="sm"
                    show-code-on-list
                    defaultCountryCode="DE"
                    :preferredCountries="['DE', 'EG']"
                    :class="{ 'is-invalid': errors.phone }"
                  />

                  <div class="invalid-feedback">{{ errors.phone }}</div>
                </div>
              </div>
              <!-- EMAIL -->
              <div class="col-md-6 mb-3">
                <div class="form-group">
                  <div class="form-label">
                    {{ $t("Suppliers.Email_15") }}
                  </div>

                  <input
                    v-model="supplierEmail"
                    name="supplierEmail"
                    type="email"
                    class="form-control"
                    :class="{ 'is-invalid': errors.supplierEmail }"
                    :placeholder="$t('Suppliers.TypeEmailHere_16') + '...'"
                  />

                  <div class="invalid-feedback">{{ errors.supplierEmail }}</div>
                </div>
              </div>
              <!-- ERRORS -->
              <div class="col-12">
                <div
                  v-if="supplierCreatedSuccessfully != null"
                  class="result-alert"
                >
                  <div
                    v-if="supplierCreatedSuccessfully && !meta.dirty"
                    class="col-lg-7 alert alert-success"
                    role="alert"
                  >
                    {{ $t("Suppliers.SupplierCreatedSuccessfully_17") }}
                  </div>
                  <div
                    v-if="!supplierCreatedSuccessfully"
                    class="col-lg-7 alert alert-danger"
                    role="alert"
                  >
                    {{ errorSupplierMessage }}
                  </div>
                </div>
              </div>
            </div>
            <!-- ACTION BUTTONS -->
            <div
              class="options"
              :class="{ 'mt-3': supplierCreatedSuccessfully === null }"
            >
              <button :disabled="isSubmitting" class="save" type="submit">
                Add Supplier
              </button>
              <button class="cancel" @click="closeNewSupplierModal">
                {{ $t("Order.CancelPopup_82") }}
              </button>
            </div>
          </form>
          <!-- Supplier Form -->
        </div>
      </div>
    </div>
    <!-- end New Supplier Popup -->
    <h1 class="page-title">{{ $t("CustomerManagement.Orders_126") }}</h1>
    <!--  -->
    <div class="form-wrapper mb-4">
      <div class="generic-form">
        <div class="row">
          <!-- Customer -->
          <div
            class="col-lg-4 col-12"
            v-if="
              !(
                currentUser?.user_role.includes('Purchaser') ||
                currentUser?.user_role.includes('Payer') ||
                currentUser?.user_role.includes('Order Clearer') ||
                currentUser?.user_role.includes('Payment Clearer') ||
                currentUser?.user_role.includes('Approver')
              )
            "
          >
            <div class="form-input mb-lg-0">
              <label class="form-label">
                {{ $t("Order.Customer_1") }} <span class="text-danger">*</span>
              </label>
              <LegacyMultiSelect
                v-model.trim="$v.customer.$model"
                name="customers"
                :object="false"
                :options="customersList"
                :resolve-on-load="true"
                :min-chars="0"
                :placeholder="$t('Order.SelectCustomer_2')"
                :closeOnSelect="true"
                :clearOnBlur="false"
                :searchable="true"
                valueProp="id"
                label="name"
              />
              <!--  -->
              <span v-if="$v.customer.$error" class="form__error-msg">{{
                $t("Order.CustomerRequired_3")
              }}</span>
            </div>
          </div>
          <!-- Project -->
          <div class="col-12 col-lg-4">
            <div class="form-input mb-lg-0">
              <label class="form-label"> {{ $t("Order.Project_4") }}</label>
              <LegacyMultiSelect
                v-model="formData.project"
                name="projects"
                :object="false"
                :options="projectsList"
                :resolve-on-load="true"
                :min-chars="0"
                :placeholder="$t('Order.SelectProject_5')"
                :closeOnSelect="true"
                :clearOnBlur="false"
                :searchable="true"
                valueProp="id"
                label="name"
              />
            </div>
          </div>
          <!-- Type -->
          <div
            class="col-12"
            :class="
              !(
                currentUser?.user_role.includes('Purchaser') ||
                currentUser?.user_role.includes('Payer') ||
                currentUser?.user_role.includes('Order Clearer') ||
                currentUser?.user_role.includes('Payment Clearer') ||
                currentUser?.user_role.includes('Approver')
              )
                ? 'col-lg-4'
                : 'col-lg-7'
            "
          ></div>
        </div>
      </div>
    </div>
    <!-- Order Form -->
    <div v-if="formData.customer === null" class="text-center">
      Please Select Customer to view the related order fields
    </div>
    <div v-else>
      <!-- Order Info -->
      <div class="form-wrapper mb-4">
        <div class="form-title">
          <h2>
            {{
              currentUser?.is_admin
                ? $t("Order.OrderInfo_11")
                : $t("Order.Recurring_Section_59")
            }}
          </h2>
        </div>
        <div class="generic-form">
          <div class="row">
            <div class="col-lg-9 col-12">
              <div class="row">
                <!-- Invoice Name -->
                <div class="col-lg-8 col-12">
                  <div class="form-input">
                    <label class="form-label">
                      {{ $t("Order.OrderName_13") }}
                    </label>
                    <input
                      type="text"
                      v-model="formData.name"
                      :placeholder="$t('Order.EnterOrderName_14')"
                    />
                  </div>
                </div>
                <!-- Start Date -->
                <div class="col-lg-4 col-12">
                  <div class="form-input">
                    <label class="form-label">
                      {{ $t("Order.StartDate_15") }}
                    </label>
                    <VueDatePicker
                      v-model="formData.startDate"
                      :placeholder="$t('Order.SelectDate_49')"
                      :enable-time-picker="false"
                      :select-text="$t('Order.Apply_50')"
                      auto-apply
                      :format="formatDate"
                    />
                  </div>
                </div>
                <!-- Frequency -->
                <div class="col-lg-4 col-12">
                  <div class="form-input">
                    <label class="form-label">
                      {{ $t("Order.Frequency_17") }}
                    </label>
                    <select v-model="formData.frequency" class="form-select">
                      <option value="" selected disabled>
                        {{ $t("Order.SelectFrequency_18") }}
                      </option>
                      <option value="DAILY">
                        {{ $t("Order.Daily_19") }}
                      </option>
                      <option value="WEEKLY">
                        {{ $t("Order.Weekly_20") }}
                      </option>
                      <option value="MONTHLY">
                        {{ $t("Order.Monthly_21") }}
                      </option>
                      <option value="QUARTERLY">
                        {{ $t("Order.Quarterly_21_1") }}
                      </option>
                      <option value="YEARLY">
                        {{ $t("Order.Yearly_21") }}
                      </option>
                    </select>
                  </div>
                </div>
                <!-- Ends After -->
                <div class="col-lg-4 col-12">
                  <div class="form-input">
                    <label class="form-label">
                      {{ $t("Order.EndsAfter_22") }}
                    </label>
                    <div class="form-input-limits">
                      <span style="min-width: auto">
                        {{ $t("Order.Invoices_51") }}
                      </span>
                      <input
                        type="number"
                        min="1"
                        v-model="formData.endsAfter"
                        :placeholder="$t('Order.EnterEndsAfter_23')"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Order Form -->
      <div
        v-for="(item, index) in formData.orderCount"
        :key="index"
        v-if="orders && orders.length"
      >
        <div class="row">
          <!-- Invoice Data -->
          <div class="col-lg-4 col-12">
            <div class="form-wrapper">
              <div class="form-title mb-3">
                <h2>{{ $t("Order.InvoiceData_24") }}</h2>
              </div>
              <div class="generic-form">
                <!-- Preliminary Proceeding Radio -->
                <div class="form-input mb-3">
                  <label class="form-label">
                    {{ $t("Order.PreliminaryProceeding_12") }}
                  </label>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="d-flex align-items-center">
                        <input
                          type="radio"
                          v-model="
                            orders[index].preliminary_insolvency_proceedings
                          "
                          :name="`preliminary-proceeding-yes-${index}`"
                          :id="`preliminary-proceeding-yes-${index}`"
                          :value="true"
                          style="width: max-content; margin-right: 5px"
                        />
                        <label
                          class="mb-0"
                          :for="`preliminary-proceeding-yes-${index}`"
                        >
                          {{ $t("Order.Yes_45") }}
                        </label>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="d-flex align-items-center">
                        <input
                          type="radio"
                          v-model="
                            orders[index].preliminary_insolvency_proceedings
                          "
                          :name="`preliminary-proceeding-no-${index}`"
                          :id="`preliminary-proceeding-no-${index}`"
                          :value="false"
                          style="width: max-content; margin-right: 5px"
                        />
                        <label
                          class="mb-0"
                          :for="`preliminary-proceeding-no-${index}`"
                        >
                          {{ $t("Order.No_46") }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Dynamic Form Fields -->
                <div class="form-input" v-for="item in formFields" :key="item">
                  <label
                    class="form-label"
                    v-if="item.field.model_field_name !== 'order_triggered_on'"
                  >
                    {{ item.field.field_name }}
                    <span class="text-danger" v-if="item.is_mandatory">*</span>
                  </label>
                  <!-- Input Text Fields -->
                  <input
                    v-if="item.field.data_type === 'TEXT'"
                    type="text"
                    :placeholder="'Enter ' + item.field.field_name"
                    v-model="orders[index][item.field.model_field_name]"
                  />
                  <!-- Input Number Fields -->
                  <input
                    v-else-if="item.field.data_type === 'INTEGER'"
                    type="text"
                    :placeholder="'Enter ' + item.field.field_name"
                    min="0"
                    v-model="orders[index][item.field.model_field_name]"
                  />
                  <!-- Date Picker -->
                  <VueDatePicker
                    v-else-if="
                      item.field.data_type === 'DATE' &&
                      item.field.model_field_name !== 'order_triggered_on'
                    "
                    auto-apply
                    :placeholder="'Enter ' + item.field.field_name"
                    v-model="orders[index][item.field.model_field_name]"
                    :format="formatDate"
                  />
                  <!-- (Boolean) Radio = order_triggered, order_related_purchase_order -->
                  <div
                    class="row"
                    v-else-if="item.field.data_type === 'BOOLEAN'"
                  >
                    <div class="col-md-6">
                      <div class="d-flex align-items-center">
                        <input
                          type="radio"
                          :name="`item.field.model_field_name-${index}`"
                          :value="true"
                          v-model="orders[index][item.field.model_field_name]"
                          :id="`${item.field.model_field_name}-yes-${index}`"
                          style="width: max-content; margin-right: 5px"
                        />
                        <label
                          class="mb-0"
                          :for="`${item.field.model_field_name}-yes-${index}`"
                          >Yes</label
                        >
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="d-flex align-items-center">
                        <input
                          type="radio"
                          :name="`item.field.model_field_name-${index}`"
                          :value="false"
                          v-model="orders[index][item.field.model_field_name]"
                          :id="`${item.field.model_field_name}-no-${index}`"
                          style="width: max-content; margin-right: 5px"
                        />
                        <label
                          class="mb-0"
                          :for="`${item.field.model_field_name}-no-${index}`"
                          >No</label
                        >
                      </div>
                    </div>
                  </div>
                  <!-- Order Triggered on = Show if order_triggered: true  -->
                  <label
                    class="form-label"
                    v-if="
                      item.field.model_field_name === 'order_triggered_on' &&
                      orders[index].order_triggered
                    "
                  >
                    {{ item.field.field_name }}
                    <span v-if="item.field.is_mandatory">*</span>
                  </label>
                  <VueDatePicker
                    v-if="
                      item.field.model_field_name === 'order_triggered_on' &&
                      orders[index].order_triggered
                    "
                    auto-apply
                    :placeholder="'Enter ' + item.field.field_name"
                    v-model="orders[index][item.field.model_field_name]"
                    :format="formatDate"
                  />
                  <!-- Order Triggered on = Show if order_triggered: true  -->
                  {{ orderErrors[index][item.field.model_field_name] }}
                  <span
                    v-if="
                      isErrorFound &&
                      orderErrors[index][item.field.model_field_name] !== ''
                    "
                    class="validation-error-msg text-danger"
                  >
                    {{ orderErrors[index][item.field.model_field_name] }}
                  </span>
                </div>
                <!-- Supplier -->
                <div class="form-input">
                  <label
                    class="form-label d-flex align-items-center justify-content-between"
                  >
                    <label class="form-label">
                      {{ $t("Order.Supplier_98") }}
                      <span class="text-danger">*</span>
                    </label>
                    <button
                      class="add-new-supplier"
                      @click="openNewSupplierModal(index)"
                    >
                      {{ $t("Order.AddNewSupplier_103") }}
                    </button>
                  </label>
                  <LegacyMultiSelect
                    :key="formData.customer"
                    v-model="orders[index].supplier"
                    :loading="suppliersListLoading"
                    @search-change="filterSuppliers"
                    name="supplier"
                    :object="false"
                    :options="suppliersList"
                    :resolve-on-load="true"
                    :min-chars="0"
                    :placeholder="$t('Order.SelectSupplier_99')"
                    :closeOnSelect="true"
                    :clearOnBlur="false"
                    :searchable="true"
                    valueProp="id"
                    label="display_name"
                  >
                  </LegacyMultiSelect>
                  <span
                    v-if="isErrorFound && orderErrors[index].supplier !== ''"
                    class="validation-error-msg text-danger"
                  >
                    {{ orderErrors[index].supplier }}
                  </span>
                </div>
                <!-- Supplier -->
                <!-- Order No. Supplier -->
                <div class="form-input">
                  <label class="form-label">
                    {{ $t("Order.OrderSupplierNo_98") }}
                    <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    readonly
                    v-model="orders[index].order_no_supplier"
                  />
                </div>
                <!-- Order No. Supplier -->
                <!-- Net Euro -->
                <div class="form-input">
                  <label class="form-label">
                    {{ $t("Order.NetEuro_37") }}
                    <span class="text-danger">*</span>
                  </label>
                  <CurrencyInput
                    v-model="orders[index].net_eur"
                    :placeholder="$t('Order.EnterNetEur_100')"
                  />
                  <span
                    v-if="isErrorFound && orderErrors[index].net_eur !== ''"
                    class="validation-error-msg text-danger"
                  >
                    {{ orderErrors[index].net_eur }}
                  </span>
                </div>
                <!-- Net Euro -->
                <!-- Order Amount -->
                <div class="form-input">
                  <label class="form-label">
                    {{ $t("Order.GrossEuro_38") }}
                    <span class="text-danger">*</span>
                  </label>
                  <CurrencyInput
                    v-model="orders[index].amount"
                    :placeholder="$t('Order.EnterGrossEur_101')"
                  />
                  <span
                    v-if="isErrorFound && orderErrors[index].amount !== ''"
                    class="validation-error-msg text-danger"
                  >
                    {{ orderErrors[index].amount }}
                  </span>
                </div>
                <!-- Order Amount -->
                <!-- Service Date -->
                <div class="form-input">
                  <label class="form-label">
                    {{ $t("Order.ServiceDate_30") }}
                    <span class="text-danger">*</span>
                  </label>
                  <VueDatePicker
                    auto-apply
                    :placeholder="$t('Order.EnterServiceDate_102')"
                    v-model="orders[index].service_date"
                    :format="formatDate"
                  />
                  <span
                    v-if="
                      isErrorFound && orderErrors[index].service_date !== ''
                    "
                    class="validation-error-msg text-danger"
                  >
                    {{ orderErrors[index].service_date }}
                  </span>
                </div>
                <!-- Service Date -->
              </div>
            </div>
          </div>
          <!-- Invoice Attachment -->
          <div class="col-lg-8 col-12">
            <div class="form-wrapper h-100">
              <div
                class="form-title mb-3 d-flex align-items-center justify-content-between"
              >
                <h2>{{ $t("Order.Attachment_40") }}</h2>
                <div class="d-flex align-items-center">
                  <!-- Take Picture -->
                  <div class="d-lg-none d-block ms-4">
                    <h2>
                      <label
                        for="take-picture"
                        class="pointer border px-2 py-1 rounded"
                        >{{ $t("Order.TakePicture_41") }}</label
                      >
                    </h2>
                    <input type="file" id="take-picture" class="d-none" />
                  </div>
                </div>
              </div>
              <div
                style="height: calc(100% - 55px)"
                v-if="orders[index].files && orders[index].files.length > 0"
              >
                <span
                  @click="removeAttachment(index)"
                  class="pointer d-block text-end mb-3"
                >
                  <i class="icon-delete"></i
                ></span>
                <embed
                  :src="orders[index].files[0].file"
                  v-if="orders[index].files && orders[index].files.length > 0"
                  width="100%"
                  height="95%"
                />
              </div>
              <div style="height: calc(100% - 55px)" v-else>
                <label
                  v-if="orderFiles[index].attachedDocument === null"
                  :for="`choose-document-${index}`"
                  class="w-100 h-100 block pointer border border-secondary d-flex flex-column align-items-center justify-content-center"
                  style="
                    border-style: dashed !important;
                    background-color: #eff0f6;
                  "
                >
                  <span class="mb-2"
                    ><strong>{{ $t("Order.ClickUpload_52") }}</strong>
                    {{ $t("Order.YourDocument_53") }}
                  </span>
                  <span> {{ $t("Order.DocumentType_54") }} </span>
                </label>
                <div v-else>
                  <span
                    @click="closePreview(index)"
                    class="pointer d-block text-end mb-3"
                  >
                    <i class="icon-delete"></i
                  ></span>
                  <div v-html="orderFiles[index].url" />
                </div>
                <input
                  type="file"
                  :id="`choose-document-${index}`"
                  class="d-none"
                  accept="jpg,jpeg,png,pdf"
                  @change="chooseDocument($event, index)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="my-4 border" v-if="index < formData.orderCount - 1"></div>
      </div>
      <!-- Order Form -->
      <!-- Remaining Balance -->
      <div
        class="row"
        v-if="
          currentUser?.is_admin ||
          currentUser?.user_role.includes('Purchaser') ||
          currentUser?.user_role.includes('Approver') ||
          currentUser?.user_role.includes('Order Clearer')
        "
      >
        <div class="col-lg-4 col-12 mt-3">
          <div class="form-input mb-lg-0">
            <label class="form-label">
              {{ $t("Order.Remaining_Balance_58") }}
            </label>
            <span
              style="color: #09cf97; font-size: 16px; margin-left: 8px"
              :style="
                currentBalance >= 0 ? 'color: #09cf97;' : 'color: #fa5c7c;'
              "
            >
              {{ $n(currentBalance, "currency") }}
            </span>
          </div>
        </div>
      </div>
      <!-- Action Buttons -->
      <div class="row mt-4 border-t">
        <div class="col-12">
          <div class="generic-form">
            <div
              class="mt-4"
              v-if="
                isCreatedSuccessfully != null ||
                isEditSuccessfully !== null ||
                isDraftSuccessfully !== null
              "
            >
              <div
                v-if="isCreatedSuccessfully"
                class="col-md-5 alert alert-success"
                role="alert"
              >
                {{ $t("CustomerManagement.FormsOrderCreatedSuccessfully_81") }}
              </div>
              <div
                v-else-if="isEditSuccessfully"
                class="col-md-5 alert alert-success"
                role="alert"
              >
                Edit Order Successfully
              </div>
              <div
                v-else-if="isDraftSuccessfully"
                class="col-md-5 alert alert-success"
                role="alert"
              >
                Saved as draft
              </div>
              <div v-else class="col-md-5 alert alert-danger" role="alert">
                {{ errorMessage }}
              </div>
            </div>
            <div
              v-if="isUploadError"
              class="col-md-4 mt-4 alert alert-danger"
              role="alert"
            >
              Max. size of file is 20 MB
            </div>
            <div class="form-options mt-4">
              <button class="cancel-btn" @click="onCancel()">
                {{ $t("Order.CancelDiscard_55") }}
              </button>
              <button
                class="cancel-btn"
                @click="isEditOrder ? onDraftEdit() : onDraft()"
                v-if="
                  !isEditOrder ||
                  (isEditOrder && !orders[0]?.is_clarification_requested)
                "
              >
                {{ $t("Order.SaveDraft_56") }}
              </button>
              <button
                class="submit-btn"
                @click.prevent="isEditOrder ? onEdit() : onSubmit()"
                :disabled="isSubmitDisabled"
                :class="isSubmitDisabled ? 'disabled' : ''"
              >
                {{
                  currentUser?.is_admin
                    ? "Submit & Sign"
                    : $t("Order.SubmitSign_57")
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  computed,
  onMounted,
  onUnmounted,
  reactive,
  type Ref,
  ref,
  watch,
} from "vue";

import Multiselect from "@vueform/multiselect";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { useCustomerStore } from "@/stores/customer.store";
import { useProjectStore } from "@/stores/project.store";
import { useUserStore } from "@/stores/user.store";
import { useSupplierStore } from "@/stores/supplier.store";

import { storeToRefs } from "pinia";

import CurrencyInput from "@/components/shared/currency-input/CurrencyInput.vue";
import router from "@/router";
import axios from "axios";
import moment from "moment";
import { useOrderStore } from "@/stores/order.store";
import { useSupplierForm } from "@/_helpers/composables/master-data/supplier-changing.composable";
import { useWorldStore } from "@/stores/world.store";
import { useRecurringInstructionStore } from "@/stores/recurring-instructions.store";

const { getCustomersOptions } = useCustomerStore();
const { getProjectsByCustomer } = useProjectStore();
const {
  createOrder,
  createOrderFile,
  getOrderById,
  editOrder,
  getCustomerCurrentBalance,
} = useOrderStore();
const {
  createRecurringInstruction,
  getRecurringInstructionById,
  updateRecurringInstruction,
} = useRecurringInstructionStore();
const { getSuppliersList, createSupplier } = useSupplierStore();
const { currentUser } = storeToRefs(useUserStore());

const { getCitiesListByCountry } = useWorldStore();

const {
  errors,
  handleSubmit,
  isSubmitting,
  meta,
  resetForm,
  supplierName,
  supplierNumber,
  address,
  zipCode,
  country,
  city,
  resetCity,
  phone,
  supplierEmail,
  countriesList,
  citiesList,
  setFieldValue,
} = useSupplierForm();

let customersList: any = ref([]);

let projectsList: any = ref([]);

const suppliersList: any = ref([]);

const suppliersListLoading: any = ref(false);

const isCancelPopup = ref(false);

const isSupplierPopup = ref(false);

const isEditOrder = ref(false);

const isUploadError = ref(false);

const formFields: any = ref([]);

const currentBalance: any = ref(0);

const newCustomerId: any = ref(null);

let orders: any = ref([
  {
    no: "",
    date_order_list: null,
    document_no_internal: null,
    order_type: null,
    delivery_date: null,
    order_triggered: false,
    order_triggered_on: null,
    vendor_no: "",
    supplier: null,
    order_no_supplier: "",
    range_in_months: null,
    order_related_purchase_order: null,
    project_no: null,
    expected_date_processing: null,
    comment: null,
    net_eur: null,
    gross_eur: null,
    release_effected: null,
    comment_allea: null,
    comment_management: null,
    comment_insv: null,
    preliminary_insolvency_proceedings: true,
    service_date: "",
    status: "DRAFT",
    project: null,
    is_recurring: false,
    amount: null,
    attachedDocument: null,
    files: [],
    is_clarification_requested: false,
  },
]);

let isErrorFound = ref(false);

let orderErrors: any = ref([
  {
    no: "",
    date_order_list: "",
    document_no_internal: "",
    order_type: "",
    delivery_date: "",
    order_triggered: "",
    order_triggered_on: "",
    vendor_no: "",
    supplier: "",
    order_no_supplier: "",
    range_in_months: "",
    order_related_purchase_order: "",
    project_no: "",
    expected_date_processing: "",
    comment: "",
    net_eur: "",
    gross_eur: "",
    release_effected: "",
    comment_allea: "",
    comment_management: "",
    comment_insv: "",
    preliminary_insolvency_proceedings: "",
    service_date: "",
    status: "",
    project: "",
    is_recurring: "",
    amount: "",
    attachedDocument: "",
  },
]);

const orderFiles = ref([
  {
    attachedDocument: null,
    document: null,
    url: "",
  },
]);

let fileCount = ref(0);

let editOrderId = ref(0);

const newFiles: any = ref([
  {
    file: null,
    index: null,
    file_name: null,
    file_size: null,
  },
]);

let formData: any = reactive({
  customer: null,
  project: null,
  orderCount: 1,
  name: "",
  startDate: null,
  frequency: "",
  endsAfter: 0,
});

const isCreatedSuccessfully: Ref<boolean | null> = ref(null);

const isEditSuccessfully: Ref<boolean | null> = ref(null);

const isDraftSuccessfully: Ref<boolean | null> = ref(null);

const errorMessage: any = ref("");

const supplierCreatedSuccessfully: Ref<boolean | null> = ref(null);
const errorSupplierMessage = ref("");

const orderSupplierIndex = ref(0);

const rules = {
  customer: {
    required,
  },
};

const $v = useVuelidate(rules, formData);

/* Get Project By Customer id*/
const getProjectsTableList = async (customerId: any) => {
  const response = await getProjectsByCustomer({
    customer: customerId,
    is_active: true,
  });

  projectsList.value = [...response.results];
};

watch(
  () => formData.customer,
  async (newVal, oldVal) => {
    if (!newVal) return;
    formFields.value = [];
    await getProjectsTableList(newVal);
    await axios
      .get(`/orders/customer_order_fields/?customer=${newVal}`)
      .then((res) => {
        res.data.results.map((item: any) => {
          formFields.value.push(item);
        });

        /* sort array */
        formFields.value.sort((a, b) => {
          const fieldA = a.field.field_name.toUpperCase();
          const fieldB = b.field.field_name.toUpperCase();
          if (fieldA < fieldB) {
            return -1;
          }
          if (fieldA > fieldB) {
            return 1;
          }
          return 0;
        });
        /* remove Order No. Supplier  */
        formFields.value = formFields.value.filter(
          (item: any) => item.field.model_field_name !== "order_no_supplier"
        );
      })
      .catch((error) => console.log(error));
    newCustomerId.value = newVal;
    if (newVal !== null) {
      await getCustomerCurrentBalance(newVal, null).then((response) => {
        currentBalance.value = response.current_balance;
      });
    }

    if (oldVal !== null) {
      // empty supplier selection
      orders.value = orders.value.map((order: any) => {
        return { ...order, supplier: null };
      });
    }
    // list supplier by customer
    await getSupplierByCustomerAndName(newCustomerId.value, null);
  }
);

watch(
  () => formData.project,
  async (newVal) => {
    if (newCustomerId.value !== null) {
      await getCustomerCurrentBalance(newCustomerId.value, newVal).then(
        (response) => {
          currentBalance.value = response.current_balance;
        }
      );
    }
  }
);

/* Choose Document */

const chooseDocument = (event: any, index: number) => {
  let fileToUpload = event.target.files[0];
  let size = fileToUpload.size / 1024;
  if (size > 20000) {
    isUploadError.value = true;
    return;
  }
  isUploadError.value = false;
  orderFiles.value[index].attachedDocument = fileToUpload;
  orderFiles.value[index].document = fileToUpload;

  var reader = new FileReader();
  reader.readAsDataURL(fileToUpload);
  if (fileToUpload.type.includes("pdf")) {
    reader.onload = async () => {
      let response = await fetch(`${reader.result}`);
      let pdfBlob = await response.blob();

      const blobUrl = URL.createObjectURL(pdfBlob);

      orderFiles.value[index].url =
        "<embed src='" +
        blobUrl +
        "' type='application/pdf' frameBorder='0' scrolling='auto' width='100%' height='700px' />";
    };
  }
  if (fileToUpload.type.includes("image")) {
    reader.onload = async () => {
      let response = await fetch(`${reader.result}`);
      let pdfBlob = await response.blob();

      const blobUrl = URL.createObjectURL(pdfBlob);
      orderFiles.value[index].url =
        "<img src='" +
        blobUrl +
        "' alt='preview' style='width: 100%' class='img-fluid' />";
    };
  }
  onUploadDocx(index, fileToUpload.name, fileToUpload.size);
};

/* Close Preview Document */
const closePreview = (index: number) => {
  orderFiles.value[index].attachedDocument = null;
  orderFiles.value[index].document = null;
  orderFiles.value[index].url = "";

  newFiles.value.forEach((item) => {
    if (item.index === index) {
      newFiles.value.pop(item);
    }
  });
};

const isSubmitDisabled = computed(() => {
  let flag = true;
  orders.value.forEach((order: any) => {
    if (
      order.supplier !== null &&
      order.net_eur !== null &&
      order.amount !== null &&
      order.service_date !== ""
    ) {
      flag = false;
    }
  });
  return flag;
});

/* Mapping all errors messages to respected field */
const showErrorMessageToField = (errors: []) => {
  errors.map((error: any) => {
    if (error.attr.includes(".")) {
      let array = error.attr.split(".");
      let errorOrderIndex = array[0];
      let errorOrderField = array[1];
      let message = error.detail;

      orderErrors.value[errorOrderIndex][errorOrderField] = message;
    } else {
      isEditSuccessfully.value = false;
      errorMessage.value = `${error.attr} ${error.detail}`;
    }
  });
};

const cleanOrders = (submit: boolean = false) => {
  // prepare order data, adding in all the fields required by the backend
  orders.value.map((item: any, _) => {
    item.submit = submit;
    item.project = formData.project;
    item.customer = formData.customer;
    item.end_after = formData.endsAfter;
    item.frequency = formData.frequency;
    item.name = formData.name;
    item.start_date =
      formData.startDate !== null
        ? moment(formData.startDate).format("YYYY-MM-DD")
        : null;
    item.date_order_list =
      item.date_order_list !== null
        ? moment(item.date_order_list).format("YYYY-MM-DD")
        : null;
    item.delivery_date =
      item.delivery_date !== null
        ? moment(item.delivery_date).format("YYYY-MM-DD")
        : null;
    item.order_triggered_on =
      item.order_triggered_on !== null
        ? moment(item.order_triggered_on).format("YYYY-MM-DD")
        : null;
    item.service_date =
      item.service_date === "" || item.service_date === null
        ? null
        : moment(item.service_date).format("YYYY-MM-DD");
    item.expected_date_processing = item.expected_date_processing
      ? moment(item.expected_date_processing).format("YYYY-MM-DD")
      : item.expected_date_processing;
    item.release_effected = item.release_effected
      ? moment(item.release_effected).format("YYYY-MM-DD")
      : item.expected_date_processing;
  });
};

const removeAttachment = (index: number) => {
  orders.value[index].files.shift();
  orders.value[index].remove_files = true;
};
/* Submit the data */
const onSubmit = async () => {
  isErrorFound.value = false;
  isCreatedSuccessfully.value = null;
  cleanOrders(true);

  /* Check Validations */
  $v.value.$touch();

  if (!$v.value.$invalid) {
    try {
      let data = await createRecurringInstruction({
        ...orders.value[0],
        files: newFiles.value.length ? [newFiles.value[0].file] : [],
      });
      if (data) {
        isCreatedSuccessfully.value = true;
        setTimeout(() => {
          router.push({ name: "recurring-orders" });
        }, 1000);
      } else {
        isCreatedSuccessfully.value = false;
      }
    } catch (error: any) {
      if (error.response.data.type === "validation_error") {
        isErrorFound.value = true;
        showErrorMessageToField(error.response.data.errors);
      } else {
        errorMessage.value = error.response.data.errors[0].detail;
        isCreatedSuccessfully.value = false;
      }
    }
  }
};

const onEdit = async () => {
  cleanOrders(true);

  try {
    let data = await updateRecurringInstruction(editOrderId.value, {
      ...orders.value[0],
      files: newFiles.value.length ? [newFiles.value[0].file] : [],
    });
    if (data) {
      isEditSuccessfully.value = true;
      setTimeout(() => {
        router.push({ name: "recurring-orders" });
      }, 1000);
    } else {
      isEditSuccessfully.value = false;
    }
  } catch (error: any) {
    if (error.response.data.type === "validation_error") {
      isErrorFound.value = true;
      showErrorMessageToField(error.response.data.errors);
    } else {
      errorMessage.value = error.response.data.errors[0].detail;
      isEditSuccessfully.value = false;
      setTimeout(() => {
        isEditSuccessfully.value = null;
      }, 2000);
    }
  }
};

const closePopup = () => {
  isCancelPopup.value = false;
};

const onChangeOrderCancel = async () => {
  closePopup();
  router.push({ name: "recurring-orders" });
};

/* on Cancel */
const onCancel = () => {
  isCancelPopup.value = true;
};

/* on Draft */
const onDraft = async () => {
  cleanOrders(false);
  try {
    let data = await createRecurringInstruction({
      ...orders.value[0],
      files: newFiles.value.length ? [newFiles.value[0].file] : [],
    });
    if (data) {
      isDraftSuccessfully.value = true;
      setTimeout(() => {
        router.push({ name: "recurring-orders", query: { status: "DRAFT" } });
      }, 1000);
    } else {
      isDraftSuccessfully.value = false;
    }
  } catch (error: any) {
    if (error.response.data.type === "validation_error") {
      isErrorFound.value = true;
      showErrorMessageToField(error.response.data.errors);
    } else {
      errorMessage.value = error.response.data.errors[0].detail;
      isDraftSuccessfully.value = false;
      setTimeout(() => {
        isDraftSuccessfully.value = null;
      }, 2000);
    }
  }
};

/* on Draft Edit */
const onDraftEdit = async () => {
  cleanOrders(false);
  try {
    let data = await updateRecurringInstruction(editOrderId.value, {
      ...orders.value[0],
      files: newFiles.value.length ? [newFiles.value[0].file] : [],
    });
    if (data) {
      isEditSuccessfully.value = true;
      setTimeout(() => {
        router.push({ name: "recurring-orders" });
      }, 1000);
    }
  } catch (error: any) {
    if (error.response.data.type === "validation_error") {
      isErrorFound.value = true;
      showErrorMessageToField(error.response.data.errors);
    } else {
      errorMessage.value = error.response.data.errors[0].detail;
      isEditSuccessfully.value = false;
      setTimeout(() => {
        isEditSuccessfully.value = null;
      }, 2000);
    }
  }
};

/* Upload File */
const onUploadDocx = (index: any, fileName: string, fileSize: string) => {
  if (fileCount.value === 0) {
    newFiles.value[index].file = orderFiles.value[index].document;
    newFiles.value[index].index = index;
    newFiles.value[index].file_name = fileName;
    newFiles.value[index].file_size = fileSize;
  } else {
    newFiles.value.push({
      file: orderFiles.value[index].document,
      index: index,
      file_name: fileName,
      file_size: fileSize,
    });
  }
  fileCount.value++;
  orders.value[index].remove_files = false;
};

const formatDate = (date: Date) => {
  return moment(date).format("DD.MM.YYYY");
};

const getSupplierByCustomerAndName = async (customerId: any, name?: any) => {
  suppliersListLoading.value = true;
  const response = await getSuppliersList({
    page_size: 500,
    customer: customerId,
    search: name,
  });
  suppliersList.value = [...response.results];

  suppliersList.value.map((supplier: any) => {
    supplier.display_name = supplier.name + " - " + supplier.supplier_number;
  });
  suppliersListLoading.value = false;
  return suppliersList.value;
};

let supplierFilterTimer = setTimeout(() => {}, 300);

const filterSuppliers = (query: any, e: any) => {
  clearTimeout(supplierFilterTimer);
  supplierFilterTimer = setTimeout(() => {
    getSupplierByCustomerAndName(newCustomerId.value, query);
  }, 500);
};

onMounted(async () => {
  /* Edit Order */
  isEditOrder.value = !!(
    localStorage.getItem("isEditRecurringOrder") === "true" &&
    localStorage.getItem("recurringOrderId")
  );

  // this part is used when changing locale, we store the current form data
  let getStoredOrder: any = localStorage.getItem("orders");
  getStoredOrder = JSON.parse(getStoredOrder);

  let getStoredFormData: any = localStorage.getItem("formData");
  getStoredFormData = JSON.parse(getStoredFormData);

  let getStoredOrderFiles: any = localStorage.getItem("orderFiles");
  getStoredOrderFiles = JSON.parse(getStoredOrderFiles);

  if (getStoredOrder) {
    orders.value = [];
    orders.value = getStoredOrder;
  }

  if (getStoredFormData) {
    formData.value = getStoredFormData;
  }

  if (getStoredOrderFiles) {
    orderFiles.value = getStoredOrderFiles;
  }

  if (isEditOrder.value) {
    let orderID = localStorage.getItem("recurringOrderId");
    orders.value = [];
    getRecurringInstructionById(orderID).then((response) => {
      orders.value.push(response);
      orders.value.map(async (order: any) => {
        editOrderId.value = order.id;
        formData.customer = order.customer;
        formData.project = order.project;
        formData.orderCount = 1;
        formData.name = order.name === null ? "" : order.name;
        formData.startDate = order.start_date;
        formData.frequency = order.frequency === null ? "" : order.frequency;
        formData.endsAfter = order.end_after === null ? 0 : order.end_after;
        // order.attachedDocument = order.files.length > 0 ? order.files[0].file : null;
        await getSupplierByCustomerAndName(order.customer, order.supplier_name);
      });
    });
  } else {
    getSupplierByCustomerAndName(null, null);
  }
  /* Edit Order */
  await getCustomersOptions().then((response) => {
    customersList.value = response;
    let getSelectedCustomerID = localStorage.getItem("selectedCustomerId");
    let customerId = getSelectedCustomerID
      ? JSON.parse(getSelectedCustomerID)
      : -1;
    if (customerId !== -1) formData.customer = customerId;
  });

  let state: any = localStorage.getItem("formData");
  if (state) {
    state = JSON.parse(state);
    setTimeout(() => {
      Object.assign(formData, state);
      /* STATE ORDERS */
      let stateOrders: any = localStorage.getItem("orders");
      if (stateOrders) {
        stateOrders = JSON.parse(stateOrders);
        setTimeout(() => {
          orders.value = stateOrders;
        }, 3000);
      }
    }, 3000);
  }
});

onUnmounted(() => {
  localStorage.removeItem("formData");
  localStorage.removeItem("orders");
  localStorage.setItem("isEditRecurringOrder", "false");
  localStorage.removeItem("orderFiles");
});

watch(
  () => router.currentRoute.value.params.locale,
  (newVal) => {
    if (!isEditOrder.value) {
      localStorage.setItem("formData", JSON.stringify(formData));
      localStorage.setItem("orders", JSON.stringify(orders.value));
      localStorage.setItem("orderFiles", JSON.stringify(orderFiles.value));
    }
  }
);

const openNewSupplierModal = (currentIndex: number) => {
  isSupplierPopup.value = true;
  orderSupplierIndex.value = currentIndex;
  setFieldValue("customer", formData.customer);
};
const closeNewSupplierModal = () => {
  isSupplierPopup.value = false;
  resetForm();
};

const addNewSupplier = handleSubmit(async (values) => {
  try {
    createSupplier({
      name: values.supplierName,
      supplier_number: values.supplierNumber,
      address: values.address,
      zip_code: values.zipCode!,
      city: values.city!,
      country: values.country!,
      phone_number: values.phone,
      email: values.supplierEmail,
      customer: formData.customer,
    })
      .then((response) => {
        let supplierResponse: any = response;

        supplierResponse["display_name"] =
          supplierResponse.name + " - " + supplierResponse.supplier_number;
        suppliersList.value.push(supplierResponse);
        orders.value[orderSupplierIndex.value].supplier = supplierResponse.id;
        orders.value[orderSupplierIndex.value].order_no_supplier =
          supplierResponse.supplier_number;
        supplierCreatedSuccessfully.value = true;
        resetForm();
        setTimeout(() => {
          supplierCreatedSuccessfully.value = null;
          closeNewSupplierModal();
        }, 1000);
      })
      .catch((error: any) => {
        supplierCreatedSuccessfully.value = false;
        errorSupplierMessage.value = error.response.data.errors[0].detail;
      });
  } catch (error: any) {
    console.error(error);
    throw error;
  }
});

watch(
  orders,
  (newOrders, oldOrders) => {
    newOrders.forEach((newObj, index) => {
      const oldObj = oldOrders[index];
      suppliersList.value.map((item: any) => {
        if (item.id === oldObj.supplier) {
          newObj.order_no_supplier = item.supplier_number;
        }
      });
    });
  },
  { deep: true }
);

watch(country, async (selectedCountry: string | null) => {
  resetCity();
  if (selectedCountry)
    citiesList.value = await getCitiesListByCountry(selectedCountry);
});
</script>

<style lang="css" scoped>
.order-counter {
  display: flex;
  align-items: center;
  border: 1px solid #eee;
  border-left: 0;
  border-right: 0;
  text-align: center;
}

.order-counter button {
  width: 32px;
  height: 32px;
  color: #ffffff;
  background-color: #7a79f3;
  border-radius: 3px;
}

.order-counter div {
  width: calc(100% - 64px);
  padding: 0 10px;
}

.upload-btn {
  font-size: 15px;
  background-color: #ceec34;
  color: #323232;
  border-radius: 3px;
  padding: 10px 25px;
  min-width: 120px;
  margin-bottom: 10px;
}

.add-new-supplier {
  background: #ceec34;
  border-radius: 3px;
  padding: 8px;
  font-size: 14px;
  line-height: 1.3;
  cursor: pointer;
}

.validation-error-msg {
  font-size: 14px;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.8;
}
</style>
