<template>
  <div>
    <div class="alert alert-warning text-left mb-0" role="alert">
      <p class="mb-0">
        {{ $t("Payments.PaymentAdjustmentMessage_35") }}
      </p>
      <p class="mb-0">
        Total Amount: {{ $n(+props.paymentAmount, "currency") }}
      </p>
      <p class="mb-0">
        Unallocated Amount: {{ $n(+unallocatedAmount, "currency") }}
      </p>
    </div>
    <InocuDataTable
      v-model:server-options="serverOptions"
      :server-items-length="ordersListTotalCount"
      :loading="loading"
      :headers="ordersTableHeaders"
      :items="orderList"
      :rows-items="[10, 25, 50, 100]"
      table-class-name="listing-custom-table mt-4"
      alternating
      buttons-pagination
      no-hover
      must-sort
    >
      <template #item-orderNo="order">
        {{ order.order_number }}
      </template>
      <template #item-service_date="order">
        {{ moment(order.service_date).format("DD.MM.YYYY") }}
      </template>
      <template #item-order_amount="order">
        <div class="d-flex align-items-center gap-3">
          {{ order.amount ? $n(+order.amount, "currency") : $n(0, "currency") }}
        </div>
      </template>
      <template #item-current_balance="order">
        <div class="d-flex align-items-center gap-3">
          {{
            order.current_balance
              ? $n(+order.current_balance, "currency")
              : $n(0, "currency")
          }}
        </div>
      </template>
      <template #item-new_balance="order">
        <div class="d-flex align-items-center gap-3">
          {{
            order.new_balance
              ? $n(+order.new_balance, "currency")
              : $n(0, "currency")
          }}
        </div>
      </template>
      <template #item-allocated_amount="order">
        <CurrencyInput
          v-model.number="orderList[getOrderIndex(order.id)].allocated_amount"
          class="form-control"
          placeholder="Amount to Allocate"
        >
        </CurrencyInput>
      </template>
      <template #item-new_amount="order">
        {{
          order.new_amount
            ? $n(+order.new_amount, "currency")
            : $n(0, "currency")
        }}
      </template>
    </InocuDataTable>
  </div>
</template>

<script setup lang="ts">
import moment from "moment";
import { ref, type Ref, onMounted, watch } from "vue";

import type { Header, ServerOptions } from "vue3-easy-data-table";
import { useI18n } from "vue-i18n";
import CurrencyInput from "@/components/shared/currency-input/CurrencyInput.vue";

const props = defineProps({
  paymentId: {
    default: null,
    type: [Number, String],
  },
  paymentAmount: {
    default: 0,
    type: Number,
  },
  list: {
    default: () => [],
    type: Array,
  },
  excessAmount: {
    default: 0,
    type: Number,
  },
});

const { t } = useI18n();

const loading = ref(false);

const ordersListTotalCount = ref(0);

const serverOptions = ref<ServerOptions>({
  page: 1,
  rowsPerPage: 10,
  sortBy: "order_number_without_prefix",
  sortType: "asc",
});

const ordersTableHeaders: Ref<Header[]> = ref([
  {
    text: t("Order.OrderNo_75"),
    value: "order_number",
    sortable: true,
  },
  {
    text: t("Order.Supplier_31"),
    value: "supplier_name",
    sortable: true,
  },
  {
    text: t("Order.DocumentNo_112"),
    value: "document_no_internal",
  },
  {
    text: t("Order.ServiceDate_30"),
    value: "service_date",
  },
  {
    text: "Amount",
    value: "order_amount",
  },
  {
    text: "Balance",
    value: "current_balance",
  },
  {
    text: "Allocated Amount",
    value: "allocated_amount",
    width: 200,
  },
  {
    text: "New Balance",
    value: "new_balance",
  },
  {
    text: "New Amount",
    value: "new_amount",
  },
]);

const orderList: any = ref([]);

const amountExcess = ref(0);

const getOrderIndex = (id: number) => {
  return orderList.value.findIndex((item: any) => item.id === id);
};

onMounted(() => {
  unallocatedAmount.value = props.paymentAmount;
  orderList.value = props.list?.map((item: any) => {
    return {
      ...item,
      allocated_amount: 0,
      new_balance: item.current_balance,
      new_amount: Number(item.amount),
      amount: Number(item.amount),
    };
  });
  if (props.list.length == 1) {
    orderList.value[0].allocated_amount = props.paymentAmount;
  }
  onAdjustment();
});

const emit = defineEmits(["adjusted"]);

const unallocatedAmount = ref(0);
const unAllocatedAmountCents = ref(0);

watch(
  () => orderList.value,
  (newVal) => {
    onAdjustment();
  },
  { deep: true }
);

const onAdjustment = () => {
  let totalAllocatedAmountCents = 0;
  orderList.value.forEach((item: any) => {
    let itemAllocatedAmountCents = item.allocated_amount * 100;
    totalAllocatedAmountCents += itemAllocatedAmountCents;
    let newBalanceCents = item.balance * 100 - item.allocated_amount * 100;

    if (newBalanceCents <= 0) {
      item.new_balance = 0;
      item.new_amount =
        item.amount + (itemAllocatedAmountCents - item.balance * 100) / 100;
    } else {
      item.new_balance = newBalanceCents / 100;
      item.new_amount = item.amount;
    }
  });
  unAllocatedAmountCents.value =
    props.paymentAmount * 100 - totalAllocatedAmountCents;
  unallocatedAmount.value = unAllocatedAmountCents.value / 100;

  emit("adjusted", {
    orders: orderList.value.map((item: any) => {
      return {
        id: item.id,
        allocated_amount: item.allocated_amount,
      };
    }),
    paymentId: props.paymentId,
    isValid: unAllocatedAmountCents.value === 0,
  });
};
</script>
