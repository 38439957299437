<template>
  <div class="create-page">
    <h4 class="page-header">{{ $t("Suppliers.NewMasterPage_1") }}</h4>

    <div class="create-page-content">
      <div class="item-data-header">{{ $t("Suppliers.SupplierInfo_2") }}</div>

      <form
        @submit="onSubmitSupplierForm"
        class="item-data-form container-fluid d-flex flex-column"
      >
        <!-- Number and Name -->
        <div class="row justify-content-between">
          <div class="form-group col-lg-5">
            <div class="form-label">
              {{ $t("Suppliers.SupplierNumber_72") }}
              <span class="text-danger">*</span>
            </div>

            <input
              v-model="supplierNumber"
              name="supplierNumber"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.supplierNumber }"
              :placeholder="$t('Suppliers.TypeNumberHere_73') + '...'"
            />

            <div class="invalid-feedback">{{ errors.supplierNumber }}</div>
          </div>

          <div class="form-group col-lg-5">
            <div class="form-label">
              {{ $t("Suppliers.SupplierName_3") }}
              <span class="text-danger">*</span>
            </div>

            <input
              v-model="supplierName"
              name="supplierName"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.supplierName }"
              :placeholder="$t('Suppliers.TypeNameHere_4') + '...'"
            />

            <div class="invalid-feedback">{{ errors.supplierName }}</div>
          </div>
        </div>

        <!-- Address and Zip code -->
        <div class="row justify-content-between mt-4">
          <div class="form-group col-lg-5">
            <div class="form-label">
              {{ $t("Suppliers.Address_5") }}
            </div>

            <input
              v-model="address"
              name="address"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.address }"
              :placeholder="$t('Suppliers.TypeAddressHere_6') + '...'"
            />

            <div class="invalid-feedback">{{ errors.address }}</div>
          </div>

          <div class="form-group col-lg-5">
            <div class="form-label">
              {{ $t("Suppliers.ZipCode_7") }}
            </div>

            <input
              v-model="zipCode"
              name="zipCode"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.zipCode }"
              :placeholder="$t('Suppliers.TypeZipCodeHere_8') + '...'"
            />

            <div class="invalid-feedback">{{ errors.zipCode }}</div>
          </div>
        </div>

        <!-- Country and City  -->
        <div class="row justify-content-between mt-4">
          <div class="form-group col-lg-5">
            <div class="form-label">{{ $t("Suppliers.Country_11") }}</div>

            <LegacyMultiSelect
              v-model="country"
              name="country"
              mode="single"
              :options="countriesList"
              valueProp="name"
              label="displayed_name"
              :searchable="true"
              :placeholder="$t('Suppliers.TypeCountryHere_12') + '...'"
              :clearOnBlur="false"
              :class="{
                'is-invalid': errors.country,
              }"
            />

            <div class="invalid-feedback">{{ errors.country }}</div>
          </div>

          <div class="form-group col-lg-5">
            <div class="form-label">
              {{ $t("Suppliers.City_9") }}
            </div>

            <LegacyMultiSelect
              v-model="city"
              name="city"
              mode="single"
              :noOptionsText="
                country
                  ? $t('Suppliers.NoCitiesInThisCountry_31')
                  : $t('Suppliers.PleaseSelectCountryFirst_26')
              "
              :options="citiesList"
              valueProp="name"
              label="name"
              :searchable="true"
              :placeholder="$t('Suppliers.TypeCityHere_10') + '...'"
              :clearOnBlur="false"
              :class="{
                'is-invalid': errors.city,
              }"
            />

            <div class="invalid-feedback">{{ errors.city }}</div>
          </div>
        </div>

        <!-- Phone and Email -->
        <div class="row justify-content-between mt-4">
          <div class="form-group col-lg-5">
            <div class="form-label">{{ $t("Suppliers.Phone_13") }}</div>
            <PhoneNumberInput
              v-model="phone"
              name="phone"
              size="sm"
              show-code-on-list
              defaultCountryCode="DE"
              :preferredCountries="['DE', 'EG']"
              :class="{ 'is-invalid': errors.phone }"
            />

            <div class="invalid-feedback">{{ errors.phone }}</div>
          </div>

          <div class="form-group col-lg-5">
            <div class="form-label">
              {{ $t("Suppliers.Email_15") }}
            </div>

            <input
              v-model="supplierEmail"
              name="supplierEmail"
              type="email"
              class="form-control"
              :class="{ 'is-invalid': errors.supplierEmail }"
              :placeholder="$t('Suppliers.TypeEmailHere_16') + '...'"
            />

            <div class="invalid-feedback">{{ errors.supplierEmail }}</div>
          </div>
        </div>

        <!-- Assign Customer -->
        <div class="row justify-content-between mt-4 mb-4">
          <div class="form-group col-lg-5">
            <div class="form-label">
              {{ $t("Suppliers.AssignCustomer_76") }}
            </div>
            <LegacyMultiSelect
              v-model="customer"
              name="customer"
              mode="single"
              :options="customersList"
              :resolve-on-load="true"
              valueProp="id"
              label="name"
              :searchable="true"
              :placeholder="$t('Suppliers.SelectCustomer_77')"
              :clearOnBlur="false"
              :class="{
                'is-invalid': errors.customer,
              }"
            />
            <div class="invalid-feedback">{{ errors.customer }}</div>
          </div>
        </div>

        <div
          v-if="supplierCreatedSuccessfully != null"
          class="result-alert mt-auto"
        >
          <div
            v-if="supplierCreatedSuccessfully && !meta.dirty"
            class="col-lg-7 alert alert-success"
            role="alert"
          >
            {{ $t("Suppliers.SupplierCreatedSuccessfully_17") }}
          </div>
          <div
            v-if="!supplierCreatedSuccessfully"
            class="col-lg-7 alert alert-danger"
            role="alert"
          >
            {{ errorMessage }}
          </div>
        </div>

        <div
          class="create-item-footer"
          :class="{
            'mt-auto': supplierCreatedSuccessfully === null,
          }"
        >
          <button
            type="submit"
            class="btn btn-primary"
            :disabled="isSubmitting"
          >
            {{ $t("Suppliers.AddSupplier_18") }}
          </button>
          <button
            type="button"
            class="btn btn-grey"
            @click="backToSuppliersList"
            :disabled="isSubmitting"
          >
            {{ $t("Suppliers.Discard_19") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch, type Ref } from "vue";

import { useSupplierStore } from "@/stores/supplier.store";

import { useSupplierForm } from "@/_helpers/composables/master-data/supplier-changing.composable";
import { useWorldStore } from "@/stores/world.store";
import { useCustomerStore } from "@/stores/customer.store";

const {
  errors,
  handleSubmit,
  isSubmitting,
  meta,
  resetForm,
  supplierNumber,
  supplierName,
  address,
  zipCode,
  country,
  city,
  resetCity,
  phone,
  supplierEmail,
  countriesList,
  citiesList,
  backToSuppliersList,
  customer,
} = useSupplierForm();

const { createSupplier } = useSupplierStore();
const { getCitiesListByCountry } = useWorldStore();
const { getCustomersOptions } = useCustomerStore();

const supplierCreatedSuccessfully: Ref<boolean | null> = ref(null);
const errorMessage = ref("");
let customersList: any = ref([]);

const onSubmitSupplierForm = handleSubmit(async (values) => {
  try {
    await createSupplier({
      supplier_number: values.supplierNumber,
      name: values.supplierName,
      address: values.address,
      zip_code: values.zipCode!,
      city: values.city!,
      country: values.country!,
      phone_number: values.phone,
      email: values.supplierEmail,
      customer: values.customer!,
    });

    supplierCreatedSuccessfully.value = true;

    resetForm();
  } catch (error: any) {
    supplierCreatedSuccessfully.value = false;
    errorMessage.value = error.response.data.errors[0].detail;
  }
});

onMounted(async () => {
  customersList.value = await getCustomersOptions();
});

watch(country, async (selectedCountry: string | null) => {
  resetCity();
  if (selectedCountry)
    citiesList.value = await getCitiesListByCountry(selectedCountry);
});
</script>

<style scoped></style>
