<template>
  <div class="list-history-log-page">
    <h4 class="page-header">
      <span>{{ $t("HistoryLog.HistoryLog_1") }}</span>
    </h4>

    <div class="list-table">
      <div class="list-header">
        <div class="list-header__text">
          <h5>{{ $t("HistoryLog.HistoryList_2") }}</h5>
        </div>
      </div>

      <InocuDataTable
        v-model:server-options="serverOptions"
        :server-items-length="logsListTotalCount"
        :loading="loading"
        :headers="logsTableHeaders"
        :items="logsList"
        :rows-items="[10, 25, 50, 100]"
        :table-min-height="500"
        :body-row-class-name="pointOnClickableRows"
        theme-color="#CEEC34"
        table-class-name="listing-custom-table"
        alternating
        buttons-pagination
        no-hover
        must-sort
        @click-row="performActionOnLog"
      >
        <!-- Filtrable header cells -->
        <template #header-user_name="header">
          <div class="filter-column" @click.stop>
            <i
              class="icon-filter filter-icon"
              @click.stop="showUserNameFilter = !showUserNameFilter"
            ></i>
            {{ header.text }}
            <div class="filter-menu" v-if="showUserNameFilter">
              <input
                v-model="userNameCriteria"
                type="text"
                class="form-control"
                @input="filterLogsList('user_name', userNameCriteria)"
              />
            </div>
          </div>
        </template>

        <template #header-created_on="header">
          <div class="filter-column" @click.stop>
            <i
              class="icon-filter filter-icon"
              @click.stop="showCreatedOnFilter = !showCreatedOnFilter"
            ></i>
            {{ header.text }}
            <div class="filter-menu" v-if="showCreatedOnFilter">
              <DatePicker
                v-model="creationDateRange"
                placeholder="Range"
                :clearable="false"
                :month-change-on-scroll="false"
                :format="formatDate"
                :preview-format="formatDate"
                :max-date="new Date()"
                :select-text="$t('Global.Apply_16')"
                :cancel-text="$t('Global.Cancel_17')"
                range
              />

              <button
                class="btn btn-primary mt-2"
                @click="creationDateRange = ''"
              >
                Reset
              </button>
            </div>
          </div>
        </template>

        <template #header-project_name="header">
          <div class="filter-column" @click.stop>
            <i
              class="icon-filter filter-icon"
              @click.stop="showProjectNameFilter = !showProjectNameFilter"
            ></i>
            {{ header.text }}
            <div class="filter-menu" v-if="showProjectNameFilter">
              <input
                v-model="projectNameCriteria"
                type="text"
                class="form-control"
                @input="filterLogsList('project_name', projectNameCriteria)"
              />
            </div>
          </div>
        </template>

        <template #header-customer_name="header">
          <div class="filter-column" @click.stop>
            <i
              class="icon-filter filter-icon"
              @click.stop="showCustomerFilter = !showCustomerFilter"
            ></i>
            {{ header.text }}
            <div class="filter-menu" v-if="showCustomerFilter">
              <input
                v-model="customerCriteria"
                type="text"
                class="form-control"
                @input="filterLogsList('customer_name', customerCriteria)"
              />
            </div>
          </div>
        </template>

        <!-- Custom cells display -->
        <template #item-project_name="log">
          {{ log.project_name ? log.project_name : "--" }}
        </template>

        <template #item-created_on="log">
          {{ new Date(log.created_on).toLocaleDateString("de-DE") }}
        </template>

        <!-- No Data View -->
        <template #empty-message>
          {{ $t("HistoryLog.NoLogsToShow_9") }}...
        </template>
      </InocuDataTable>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, type Ref, watch } from "vue";

import type {
  BodyRowClassNameFunction,
  ClickRowArgument,
  Header,
  Item,
  ServerOptions,
} from "vue3-easy-data-table";

import { useToast } from "vue-toast-notification";
import { useI18n } from "vue-i18n";
import { debounce } from "lodash";
import type { LogInList } from "@/_helpers/interfaces/history-logs/logs-in-list.interface";
import { useHistoryLogStore } from "@/stores/history-logs.store";
import router from "@/router";

const $toast = useToast();

const { t } = useI18n();

const logsTableHeaders: Ref<Header[]> = ref([
  {
    text: t("HistoryLog.ActionTitle_3"),
    value: "description",
    width: 400,
    sortable: true,
  },
  {
    text: t("HistoryLog.ActionType_4"),
    value: "action",
    width: 200,
    sortable: true,
  },
  {
    text: t("HistoryLog.UserName_5"),
    value: "user_name",
    width: 300,
    sortable: true,
  },
  {
    text: t("HistoryLog.ActionDate_6"),
    value: "created_on",
    width: 220,
    sortable: true,
  },
  {
    text: t("HistoryLog.ProjectName_7"),
    value: "project_name",
    width: 200,
    sortable: true,
  },
  {
    text: t("HistoryLog.CustomerName_8"),
    value: "customer_name",
    width: 200,
    sortable: true,
  },
]);

const logsList: Ref<LogInList[]> = ref([]);
const loading = ref(false);
const logsListTotalCount = ref(0);
const serverOptions = ref<ServerOptions>({
  page: 1,
  rowsPerPage: 10,
  sortBy: "created_on",
  sortType: "desc",
});

const { getLogsList } = useHistoryLogStore();

onMounted(async () => {
  await getLogsTableList();
});

// Fires when page number changes or items per page value changes
watch(
  serverOptions,
  async () => {
    await getLogsTableList();
  },
  {
    deep: true,
  }
);

const getLogsTableList = async () => {
  loading.value = true;

  const sortBy = serverOptions.value.sortBy;
  const sortDirection = serverOptions.value.sortType === "asc" ? "" : "-";

  const response = await getLogsList({
    page: serverOptions.value.page,
    page_size: serverOptions.value.rowsPerPage,
    ordering: sortDirection + sortBy,
    ...filtrationCriteria.value,
  });

  logsList.value = [...response.results];
  logsListTotalCount.value = response.count;

  loading.value = false;
};

const filtrationCriteria = ref({});

const showUserNameFilter = ref(false);
const userNameCriteria = ref("");

const showProjectNameFilter = ref(false);
const projectNameCriteria = ref("");

const showCustomerFilter = ref(false);
const customerCriteria = ref("");

const showCreatedOnFilter = ref(false);

const creationDateRange = ref();
const formatDate = (date: Date[]) => {
  const [startDate, endDate] = date;
  const startDateDetails = {
    day: startDate.getDate(),
    month: startDate.getMonth() + 1,
    year: startDate.getFullYear(),
  };
  const endDateDetails = {
    day: endDate ? endDate.getDate() : "",
    month: endDate ? endDate.getMonth() + 1 : "",
    year: endDate ? endDate.getFullYear() : "",
  };

  return `${startDateDetails.day}.${startDateDetails.month}.${startDateDetails.year} to ${endDateDetails.day}.${endDateDetails.month}.${endDateDetails.year}`;
};

watch(creationDateRange, async (dateRange) => {
  if (dateRange) {
    filtrationCriteria.value["created_on_after"] = dateRange[0]
      .toISOString()
      .split("T")[0];
    filtrationCriteria.value["created_on_before"] = dateRange[1]
      .toISOString()
      .split("T")[0];
  } else {
    filtrationCriteria.value["created_on_after"] = null;
    filtrationCriteria.value["created_on_before"] = null;
  }

  await getLogsTableList();
});

const filterLogsList = debounce(async function (
  filterCriteria: string,
  criteriaValue: string | number | null
) {
  filtrationCriteria.value[filterCriteria] = criteriaValue;

  await getLogsTableList();
},
300);

const performActionOnLog = (logData: ClickRowArgument) => {
  if (logData.object_ct === "purchaseorder") {
    router.push({
      name: "order",
      params: {
        id: logData.object_id,
      },
    });
  } else if (logData.object_ct === "payment") {
    router.push({
      name: "payment",
      params: {
        id: logData.object_id,
      },
    });
  } else if (logData.object_ct === "customer" && logData.action !== "DELETE") {
    router.push({
      name: "view-customer",
      params: {
        id: logData.object_id,
      },
    });
  } else {
    $toast.open({
      message: t("HistoryLog.NoMoreDetailsToBeViewed_10"),
      type: "warning",
    });
  }
};

const pointOnClickableRows: BodyRowClassNameFunction = (item: Item): string => {
  return item.object_ct === "purchaseorder" ||
    item.object_ct === "payment" ||
    (item.object_ct === "customer" && item.action !== "DELETE")
    ? "clickable"
    : "";
};
</script>

<style lang="scss">
.clickable {
  cursor: pointer;
}
</style>
