<template>
  <div class="generic-list-page">
    <div class="row">
      <div
        :class="
          !(
            currentUser?.user_role.includes('Purchaser') ||
            currentUser?.user_role.includes('Payer') ||
            currentUser?.user_role.includes('Order Clearer') ||
            currentUser?.user_role.includes('Payment Clearer') ||
            currentUser?.user_role.includes('Approver')
          )
            ? 'col-lg-3 col-md-6 col-12'
            : 'col-lg-6 col-md-6 col-12'
        "
      >
        <h1 class="page-title">Sepa Payments</h1>
      </div>
      <div
        class="col-lg-3 col-md-6 col-12"
        v-if="
          !(
            currentUser?.user_role.includes('Purchaser') ||
            currentUser?.user_role.includes('Payer') ||
            currentUser?.user_role.includes('Order Clearer') ||
            currentUser?.user_role.includes('Payment Clearer') ||
            currentUser?.user_role.includes('Approver')
          )
        "
      >
        <div class="form-input mb-lg-0">
          <LegacyMultiSelect
            v-model="sepaPaymentsFilter.customer"
            name="customers"
            :object="false"
            :options="customersList"
            :resolve-on-load="true"
            :min-chars="0"
            :placeholder="$t('Order.SelectCustomer_2')"
            :closeOnSelect="true"
            :clearOnBlur="false"
            :searchable="true"
            valueProp="id"
            label="name"
          />
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <div class="form-input mb-lg-0">
          <LegacyMultiSelect
            v-model="sepaPaymentsFilter.project"
            name="projects"
            :object="false"
            :options="projectsList"
            :resolve-on-load="true"
            :min-chars="0"
            :placeholder="$t('Order.SelectProject_5')"
            :closeOnSelect="true"
            :clearOnBlur="false"
            :searchable="true"
            valueProp="id"
            label="name"
          />
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-12 ms-auto text-right">
        <VueDatePicker
          v-model="sepaPaymentsFilter.date"
          :placeholder="$t('Order.SelectDate_49')"
          :enable-time-picker="false"
          range
          :select-text="$t('Order.Apply_50')"
          auto-apply
          :max-date="new Date()"
          :preset-ranges="presetRanges"
          :format="formatDate"
        >
          <template #yearly="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}</span
            >
          </template>
          <template #last_year="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}</span
            >
          </template>
          <template #last_month="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}</span
            >
          </template>
          <template #this_month="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}</span
            >
          </template>
          <template #last_week="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}</span
            >
          </template>
          <template #last_30_days="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}</span
            >
          </template>
        </VueDatePicker>
      </div>
    </div>
    <div class="list-table">
      <div>
        <SepaPaymentsTable
          :customer="
            sepaPaymentsFilter.customer ? sepaPaymentsFilter.customer : -1
          "
          :project="
            sepaPaymentsFilter.project ? sepaPaymentsFilter.project : -1
          "
          :date="sepaPaymentsFilter.date"
        />
      </div>
      <!-- Tabs -->
    </div>
  </div>
</template>

<script setup lang="ts">
import Multiselect from "@vueform/multiselect";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { ref, reactive, onMounted, watch, onBeforeMount } from "vue";
import { useProjectStore } from "@/stores/project.store";
import { useCustomerStore } from "@/stores/customer.store";
import SepaPaymentsTable from "@/components/shared/tables/sepa-payments-table/SepaPaymentsTable.vue";
import moment from "moment";
import {
  endOfMonth,
  endOfYear,
  endOfWeek,
  startOfMonth,
  startOfYear,
  startOfWeek,
  subMonths,
  subYears,
  subWeeks,
  subDays,
} from "date-fns";
import { useUserStore } from "@/stores/user.store";
import { storeToRefs } from "pinia";

const { getCustomersOptions } = useCustomerStore();
const { getProjectsByCustomer } = useProjectStore();
const { currentUser } = storeToRefs(useUserStore());

let customersList: any = ref([]);

let projectsList: any = ref([]);

let sepaPaymentsFilter: any = reactive({
  customer: null,
  project: null,
  date: [],
});

/* Get Project By Customer id*/
const getProjectsTableList = async (customerId: any) => {
  const response = await getProjectsByCustomer({
    customer: customerId,
    is_active: true,
  });

  projectsList.value = [...response.results];
};

watch(
  () => sepaPaymentsFilter.customer,
  async (newVal) => {
    sepaPaymentsFilter.project = null;

    await getProjectsTableList(newVal);
    /* if customer is null then list also be empty */
    if (newVal === null) {
      projectsList.value = [];
    }
  }
);

const formatDate = (date: Date) => {
  const date1 = moment(date[0]).format("DD.MM.YYYY");
  let date2 = moment(date[1]).format("DD.MM.YYYY");

  if (new Date(date[1]) > new Date()) {
    date2 = moment(new Date()).format("DD.MM.YYYY");
  }

  return `${date1} To ${date2}`;
};
const presetRanges = ref([
  {
    label: "Last Week",
    range: [
      startOfWeek(subWeeks(new Date(), 1)),
      endOfWeek(subWeeks(new Date(), 1)),
    ],
    slot: "last_week",
  },
  {
    label: "This month",
    range: [startOfMonth(new Date()), endOfMonth(new Date())],
    slot: "this_month",
  },
  {
    label: "Last month",
    range: [
      startOfMonth(subMonths(new Date(), 1)),
      endOfMonth(subMonths(new Date(), 1)),
    ],
    slot: "last_month",
  },
  {
    label: "Last 30 days",
    range: [subDays(new Date(), 30), new Date()],
    slot: "last_30_days",
  },
  {
    label: "Last Year",
    range: [
      startOfYear(subYears(new Date(), 1)),
      endOfYear(subYears(new Date(), 1)),
    ],
    slot: "last_year",
  },
  {
    label: "This year",
    range: [startOfYear(new Date()), endOfYear(new Date())],
    slot: "yearly",
  },
]);
onBeforeMount(() => {
  let getStoreCustomerId = localStorage.getItem("selectedCustomerId");
  getStoreCustomerId
    ? (sepaPaymentsFilter.customer = +getStoreCustomerId)
    : null;
});
onMounted(async () => {
  await getCustomersOptions().then((res) => {
    customersList.value = res;
  });
});
</script>
