<template>
  <div class="customer view-customer">
    <div class="generic-popup" v-if="isPopup">
      <div class="popup-wrapper">
        <div class="popup-header">
          <h4 class="popup-title">
            {{ $t("CustomerManagement.AddProject_53") }}
          </h4>
          <span class="popup-close" @click="closePopup">
            <i class="icon-close"></i>
          </span>
        </div>
        <div class="popup-body">
          <div class="popup-input">
            <label>{{ $t("CustomerManagement.ProjectName_43") }}</label>
            <input
              v-model.trim="$v.name.$model"
              :class="{ error: $v.name.$error }"
              type="text"
              placeholder="Type Name"
            />
            <span v-if="$v.name.$error" class="input__error-msg">{{
              $t("CustomerManagement.ProjectNameRequired_49")
            }}</span>
          </div>
          <div class="popup-input">
            <label>{{ $t("CustomerManagement.SetProjectLimit_44") }}</label>
            <CurrencyInput
              v-model.trim="$v.project_limit.$model"
              :errorClass="{
                error: $v.project_limit.$error,
              }"
              :placeholder="$t('CustomerManagement.TypeLimit_16')"
            />
            <span v-if="$v.project_limit.$error" class="input__error-msg">{{
              $t("CustomerManagement.ProjectLimitRequired_50")
            }}</span>
          </div>
          <div class="popup-input">
            <label>{{
              $t("CustomerManagement.EnterAutomaticApproval_45")
            }}</label>
            <CurrencyInput
              v-model.trim="$v.auto_approval_limit.$model"
              :errorClass="{
                error: $v.auto_approval_limit.$error,
              }"
              :placeholder="$t('CustomerManagement.TypeLimit_16')"
            />
            <span
              v-if="$v.auto_approval_limit.$error"
              class="input__error-msg"
              >{{
                $t("CustomerManagement.AutomaticApprovalLimitRequired_51")
              }}</span
            >
          </div>
          <div class="popup-input">
            <label>{{
              $t("CustomerManagement.ProjectLimitFrequency_46")
            }}</label>
            <select
              v-model="formData.project_limit_frequency"
              class="form-select"
            >
              <option value="" selected disabled>
                {{ $t("CustomerManagement.SelectLimit_52") }}
              </option>
              <option value="NONE">
                {{ $t("CustomerManagement.None_40") }}
              </option>
              <option value="WEEKLY">
                {{ $t("CustomerManagement.Weekly_21") }}
              </option>
              <option value="MONTHLY">
                {{ $t("CustomerManagement.Monthly_22") }}
              </option>
              <option value="YEARLY">
                {{ $t("CustomerManagement.Yearly_23") }}
              </option>
              <option value="ALL_TIME">
                {{ $t("CustomerManagement.AllTime_24") }}
              </option>
            </select>
          </div>
          <div v-if="isCreatedSuccessfully != null">
            <div
              v-if="isCreatedSuccessfully"
              class="alert alert-success"
              role="alert"
            >
              {{ $t("CustomerManagement.ProjectCreatedSuccessfully_83") }}
            </div>
            <div v-else class="alert alert-danger" role="alert">
              {{ errorMessage }}
            </div>
          </div>
          <div class="options">
            <button class="save" @click="addProject">
              {{ $t("CustomerManagement.Save_89") }}
            </button>
            <button class="cancel" @click="closePopup">
              {{ $t("CustomerManagement.Cancel_113") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- <div>{{ chartOptions.xaxis.categories }}</div> -->
    <div class="row">
      <div class="col-12">
        <div class="row justify-content-between">
          <div class="col-md-6">
            <h1 class="page-title" style="margin-bottom: 35px">
              {{ $t("CustomerManagement.CustomerInfoPage_103") }}
            </h1>
          </div>
          <div class="col-md-6">
            <DatePickerRange
              @selectDate="selectDate($event)"
              :getChartData="getChartData"
            >
            </DatePickerRange>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-12 mb-4">
            <div class="customer-main-info">
              <div class="main-info-start">
                <h2 class="main-info-title">
                  {{ $t("CustomerManagement.MainInfo_124") }}
                </h2>
                <div class="main-info-data">
                  <div class="img-wrapper">
                    <img
                      v-if="receivedData.avatar == null"
                      src="@/assets/images/user-avatar.jpg"
                    />
                    <img v-else :src="receivedData.avatar" />
                  </div>
                  <div class="content">
                    <span class="name">{{ receivedData.name }}</span>
                    <span class="title">{{ receivedData.legal_name }}</span>
                    <span class="email">{{ receivedData.email }}</span>
                  </div>
                </div>
              </div>
              <div class="main-info-end">
                <div @click="editCustomer()" class="edit-client">
                  <i class="icon-edit"></i>
                  <span>{{ $t("CustomerManagement.EditClient_125") }}</span>
                </div>
                <div class="add-project" @click="isPopup = true">
                  <i class="icon-add-new"></i>
                  <span>{{ $t("CustomerManagement.AddProject_53") }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4 mb-4">
            <div class="customer-info-card">
              <h2 class="card-title">
                {{ $t("CustomerManagement.Orders_126") }}
              </h2>
              <div class="card-count">
                <span class="count">{{ receivedData.orders_count }}</span>
                <i class="icon-requests"></i>
              </div>
              <div class="card-percentage">
                <span class="increase">
                  {{ receivedData.order_percentage }}%</span
                >
                <span class="title">{{
                  $t("CustomerManagement.NewOrders_127")
                }}</span>
              </div>
            </div>
          </div>
          <div class="col-4 mb-4">
            <div class="customer-info-card">
              <h2 class="card-title">
                {{ $t("CustomerManagement.ActiveProjects_128") }}
              </h2>
              <div class="card-count">
                <span class="count">{{
                  receivedData.active_projects_count
                }}</span>
                <i class="icon-versions"></i>
              </div>
              <div class="card-percentage">
                <span class="increase decrease"
                  >{{ receivedData.active_projects_percentage }}%</span
                >
                <span class="title"
                  >New {{ $t("CustomerManagement.ActiveProjects_128") }}</span
                >
              </div>
            </div>
          </div>
          <div class="col-4 mb-4">
            <div class="customer-info-card">
              <h2 class="card-title">
                {{ $t("CustomerManagement.TeamMembers_129") }}
              </h2>
              <div class="card-count">
                <span class="count">{{ receivedData.team_members_count }}</span>
                <i class="icon-users"></i>
              </div>
              <div class="card-percentage">
                <span class="increase"
                  >{{ receivedData.team_members_percentage }}%</span
                >
                <span class="title">{{
                  $t("CustomerManagement.TeamMembers_129")
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-12">
            <div class="chart-card">
              <div class="card-header">
                <h4 class="card-header-title">
                  {{ $t("CustomerManagement.AverageSpendingForCustomer_130") }}
                </h4>
              </div>
              <div class="chart-labels">
                <div class="label-item">
                  <label>
                    <i></i>
                    <span>{{
                      $t("CustomerManagement.AvailableLimit_131")
                    }}</span>
                  </label>
                  <span class="value">{{ receivedData.customer_limit }}</span>
                </div>
                <div class="label-item">
                  <label>
                    <i></i>
                    <span>{{
                      $t("CustomerManagement.AutoApprovalLimit_132")
                    }}</span>
                  </label>
                  <span class="value">{{
                    receivedData.auto_approval_limit
                  }}</span>
                </div>
              </div>
              <div class="card-body">
                <apexchart
                  :options="chartOptions"
                  :series="series"
                  height="320"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="tabs-section">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="about-tab"
                data-bs-toggle="tab"
                data-bs-target="#about-tab-pane"
                type="button"
                role="tab"
                aria-controls="about-tab-pane"
                aria-selected="true"
              >
                {{ $t("CustomerManagement.About_133") }}
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="team-tab"
                data-bs-toggle="tab"
                data-bs-target="#team-tab-pane"
                type="button"
                role="tab"
                aria-controls="team-tab-pane"
                aria-selected="false"
              >
                {{ $t("CustomerManagement.Team_134") }}
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="forms-tab"
                data-bs-toggle="tab"
                data-bs-target="#forms-tab-pane"
                type="button"
                role="tab"
                aria-controls="forms-tab-pane"
                aria-selected="false"
              >
                {{ $t("CustomerManagement.Forms_135") }}
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="projects-tab"
                data-bs-toggle="tab"
                data-bs-target="#projects-tab-pane"
                type="button"
                role="tab"
                aria-controls="projects-tab-pane"
                aria-selected="false"
              >
                {{ $t("CustomerManagement.Projects_136") }}
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="legals-tab"
                data-bs-toggle="tab"
                data-bs-target="#legals-tab-pane"
                type="button"
                role="tab"
                aria-controls="legals-tab-pane"
                aria-selected="false"
              >
                {{ $t("CustomerManagement.Legals_136") }}
              </button>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="about-tab-pane"
              role="tabpanel"
              aria-labelledby="about-tab"
            >
              <div class="about-tab">
                <h4 class="about-title">
                  {{ $t("CustomerManagement.CustomerInformation_137") }}
                </h4>
                <div class="row">
                  <div class="col-6">
                    <div class="row">
                      <div class="col-4">
                        <label class="label">{{
                          $t("CustomerManagement.LegalName_12")
                        }}</label>
                      </div>
                      <div class="col-8">
                        <span class="value">{{
                          receivedData.legal_name != null &&
                          receivedData.legal_name != ""
                            ? receivedData.legal_name
                            : "--"
                        }}</span>
                      </div>
                      <div class="col-4">
                        <label class="label">{{
                          $t("CustomerManagement.Phone_108")
                        }}</label>
                      </div>
                      <div class="col-8">
                        <span class="value">{{
                          receivedData.phone_number != null &&
                          receivedData.phone_number != ""
                            ? receivedData.phone_number
                            : "--"
                        }}</span>
                      </div>
                      <div class="col-4">
                        <label class="label">{{
                          $t("CustomerManagement.Address_104")
                        }}</label>
                      </div>
                      <div class="col-8">
                        <span class="value">{{
                          receivedData.address != null &&
                          receivedData.address != ""
                            ? receivedData.address
                            : "--"
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="row">
                      <div class="col-4">
                        <label class="label">{{
                          $t("CustomerManagement.Email_106")
                        }}</label>
                      </div>
                      <div class="col-8">
                        <span class="value">{{
                          receivedData.email != null && receivedData.email != ""
                            ? receivedData.email
                            : "--"
                        }}</span>
                      </div>
                      <div class="col-4">
                        <!-- <label class="label">{{
                          $t("CustomerManagement.Address_104")
                        }}</label> -->
                        <label class="label">Created On</label>
                      </div>
                      <div class="col-8">
                        <span class="value">{{
                          receivedData.created_on != null &&
                          receivedData.created_on != ""
                            ? moment(receivedData.created_on).format(
                                "DD.MM.YYYY"
                              )
                            : "--"
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="team-tab-pane"
              role="tabpanel"
              aria-labelledby="team-tab"
            >
              <TeamTable :CustomerId="customerId" />
            </div>
            <div
              class="tab-pane fade"
              id="forms-tab-pane"
              role="tabpanel"
              aria-labelledby="forms-tab"
            >
              <div class="team-tab">
                <h4>{{ $t("CustomerManagement.OrderForm_140") }}</h4>
                <FormsTable :CustomerId="customerId" />
                <h4>{{ $t("CustomerManagement.PaymentForm_141") }}</h4>
                <PaymentTable :CustomerId="customerId" />
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="projects-tab-pane"
              role="tabpanel"
              aria-labelledby="projects-tab"
            >
              <ProjectTable
                :CustomerId="customerId"
                :ProjectData="ProjectData"
              />
            </div>
            <div
              class="tab-pane fade"
              id="legals-tab-pane"
              role="tabpanel"
              aria-labelledby="legals-tab"
            >
              <div class="legals-tab">
                <div class="row justify-content-between">
                  <div class="col-md-5">
                    <h4 class="legals-title">
                      {{ $t("CustomerManagement.LegalsNotes_142") }}
                    </h4>
                    <p>
                      {{
                        receivedData.legal_notes != null
                          ? receivedData.legal_notes
                          : "--"
                      }}
                    </p>
                  </div>
                  <div class="col-md-5">
                    <h4 class="legals-title">
                      {{ $t("CustomerManagement.TermsConditions_143") }}
                    </h4>
                    <p>
                      {{
                        receivedData.terms_and_conditions != null
                          ? receivedData.terms_and_conditions
                          : "--"
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import CurrencyInput from "@/components/shared/currency-input/CurrencyInput.vue";
import TeamTable from "@/components/shared/tables/customer-team-table/Table.vue";
import FormsTable from "@/components/shared/tables/customer-order-table/Table.vue";
import PaymentTable from "@/components/shared/tables/customer-payment-table/Table.vue";
import ProjectTable from "@/components/shared/tables/customer-project-table/Table.vue";
import DatePickerRange from "@/components/shared/date-pickers/date-picker-range/DatePickerRange.vue";
import moment from "moment";
import { useI18n } from "vue-i18n";

import { ref, reactive, onMounted, type Ref } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useToast } from "vue-toast-notification";
import { useDateRange } from "@/_helpers/composables/date-range.composable";

import { useCustomerStore } from "@/stores/customer.store";
import { useProjectStore } from "@/stores/project.store";

const { n } = useI18n();

const $toast = useToast();

const {
  lastWeekDates,
  thisMonthDates,
  lastMonthDates,
  last30DaysDates,
  lastYearDates,
  thisYearDates,
} = useDateRange();

const { createProject } = useProjectStore();
const { getMainDataById, getAverageSpendingChart, getCustomers } =
  useCustomerStore();

const route = useRoute();
const customerId: any = ref(route.params.id);

let isPopup = ref(false);
let ProjectData: any = ref("");

const isCreatedSuccessfully: Ref<boolean | null> = ref(null);
let errorMessage: any = ref("");

const formData: any = reactive({
  customer: customerId.value,
  name: "",
  project_limit: null,
  auto_approval_limit: null,
  project_limit_frequency: "ALL_TIME",
});

const receivedData: any = reactive({
  name: "",
  legal_name: "",
  email: "",
  phone_number: "",
  address: "",
  avatar: "",
  active_projects_count: "",
  active_projects_percentage: "",
  orders_count: "",
  orders_percentage: "",
  team_members_count: "",
  team_members_percentage: "",
  legal_notes: "",
  terms_and_conditions: "",
});

let chartOptions: any = ref({
  chart: {
    id: "vuechart-example",
    type: "line",
    background: "#fff",
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  grid: {
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
  colors: ["#22CF98"],
  xaxis: {
    type: "category",
    categories: [],
  },
  tooltip: {
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      let value = n(series[seriesIndex][dataPointIndex], "currency");
      return `<div class="custom-tooltip">
        <span class="tooltip-title">Spending Average</span>
          <span class="tooltip-value">
          ${value}
          </span>
        </div>`;
    },
  },
});

let series = ref([
  {
    data: [],
  },
]);

const rules = {
  customer: {
    required,
  },
  name: {
    required,
  },
  project_limit: {
    required,
  },
  auto_approval_limit: {
    required,
  },
};

const $v = useVuelidate(rules, formData);

const addProject = async () => {
  $v.value.$touch();
  if (!$v.value.$invalid) {
    try {
      ProjectData.value = await createProject(formData);
      isCreatedSuccessfully.value = true;
      closePopup();

      $toast.open({
        message: `Project (${formData.name}) created successfully.`,
        type: "success",
      });

      getCustomerDataById();

      resetProject();
    } catch (error: any) {
      isCreatedSuccessfully.value = false;
      errorMessage.value = error.response.data.errors[0].detail;
    }
  }
};

const closePopup = () => {
  isCreatedSuccessfully.value = null;
  isPopup.value = false;
  $v.value.$reset();
};

const resetProject = () => {
  formData.name = "";
  formData.project_limit = null;
  formData.auto_approval_limit = null;
  formData.project_limit_frequency = "ALL_TIME";
  $v.value.$reset();
};

const selectDate = async (date: any) => {
  await getChartData(date);
};

const getChartData = async (date: any) => {
  let startDate = date.startDate;
  let endDate = date.endDate;

  if (new Date(endDate) > new Date()) {
    endDate = new Date();
    endDate = moment(endDate).format("YYYY-MM-DD");
  }

  let chartData: any = await getAverageSpendingChart(
    startDate,
    endDate,
    customerId.value
  );

  let newCategories: any = [];
  let newSeries: any = [];

  chartData["X-axis"].forEach((item: any) => {
    newCategories.push(item);
  });

  chartOptions.value = {
    xaxis: {
      categories: [...newCategories],
    },
  };

  chartData["Y-axis"].forEach((item: any) => {
    newSeries.push(item);
  });

  series.value[0].data = newSeries;
};

const getCustomerDataById = async () => {
  try {
    let data = await getMainDataById(customerId.value);
    Object.keys(receivedData).forEach((receivedDataItem) => {
      Object.keys(data).forEach((responseItem) => {
        if (receivedDataItem == responseItem) {
          receivedData[receivedDataItem] = data[responseItem];
        }
      });
    });
    receivedData.created_on = new Date(data.created_on).toLocaleString();
    receivedData.customer_limit = n(+data.customer_limit, "currency");
    receivedData.auto_approval_limit = n(+data.auto_approval_limit, "currency");
  } catch (e) {
    console.log(e);
  }
};

const editCustomer = (id: any = customerId.value, boolean: any = true) => {
  localStorage.setItem("isEditCustomer", boolean);
  localStorage.setItem("customerId", id);
  router.push({ name: "create-customer" });
};

onMounted(async () => {
  await getChartData(thisMonthDates);
  await getCustomerDataById();
});
</script>
