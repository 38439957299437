<template>
  <VueDatePicker
    v-model="date"
    range
    input-class-name="date-range-input"
    menu-class-name="date-range-menu"
    :preset-ranges="presetRanges"
    :placeholder="$t('Global.SelectDate_9')"
    :max-date="new Date()"
    :format="formatDate"
    :month-change-on-scroll="false"
    auto-apply
  >
  </VueDatePicker>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
import {
  endOfMonth,
  endOfYear,
  endOfWeek,
  startOfMonth,
  startOfYear,
  startOfWeek,
  subMonths,
  subYears,
  subWeeks,
  subDays,
} from "date-fns";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const emit = defineEmits(["selectDate"]);

const props = defineProps<{
  initialDate?: [Date, Date];
  clearSelectsEmptyRange?: boolean;
}>();
const date = ref(props.initialDate);

const formatDate = (date: Date) => {
  const startDate = moment(date[0]).format("DD.MM.YYYY");
  let endDate = moment(date[1]).format("DD.MM.YYYY");

  if (new Date(date[1]) > new Date()) {
    endDate = moment(new Date()).format("DD.MM.YYYY");
  }

  return `${startDate} To ${endDate}`;
};

const presetRanges = ref([
  {
    label: t("Global.LastWeek_10"),
    range: [
      startOfWeek(subWeeks(new Date(), 1)),
      endOfWeek(subWeeks(new Date(), 1)),
    ],
  },
  {
    label: t("Global.Last30Days_11"),
    range: [subDays(new Date(), 30), new Date()],
  },
  {
    label: t("Global.ThisMonth_12"),
    range: [startOfMonth(new Date()), new Date()],
  },
  {
    label: t("Global.LastMonth_13"),
    range: [
      startOfMonth(subMonths(new Date(), 1)),
      endOfMonth(subMonths(new Date(), 1)),
    ],
  },
  {
    label: t("Global.ThisYear_14"),
    range: [startOfYear(new Date()), new Date()],
  },
  {
    label: t("Global.LastYear_15"),
    range: [
      startOfYear(subYears(new Date(), 1)),
      endOfYear(subYears(new Date(), 1)),
    ],
  },
]);

watch(
  date,
  (dateValue) => {
    if (props.clearSelectsEmptyRange && !dateValue) {
      emit("selectDate", {
        startDate: "",
        endDate: "",
      });
      return;
    }
    const selectedDate = !dateValue ? [new Date(), new Date()] : dateValue;

    const dateRange = {
      startDate: moment(selectedDate[0]).format("YYYY-MM-DD"),
      endDate: moment(selectedDate[1]).format("YYYY-MM-DD"),
    };

    emit("selectDate", dateRange);
  },
  {}
);
</script>

<style lang="scss">
@import "./date-range.scss";
</style>
