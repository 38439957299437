<template>
  <div class="create-page">
    <h4 class="page-header">{{ $t("Users.CreateUser_1") }}</h4>

    <div class="create-page-content">
      <div class="item-data-header">{{ $t("Users.UserData_2") }}</div>

      <form
        @submit="onSubmitUserForm"
        class="item-data-form container-fluid d-flex flex-column"
      >
        <div class="row justify-content-between">
          <div class="form-group col-lg-5">
            <div class="form-label">
              {{ $t("Users.FirstName_3") }} <span class="text-danger">*</span>
            </div>

            <input
              v-model="firstName"
              name="firstName"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.firstName }"
              :placeholder="$t('Users.TypeNameHere_4') + '...'"
            />

            <div class="invalid-feedback">{{ errors.firstName }}</div>
          </div>

          <div class="form-group col-lg-5">
            <div class="form-label">
              {{ $t("Users.LastName_12") }} <span class="text-danger">*</span>
            </div>

            <input
              v-model="lastName"
              name="lastName"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.lastName }"
              :placeholder="$t('Users.TypeNameHere_4') + '...'"
            />

            <div class="invalid-feedback">{{ errors.lastName }}</div>
          </div>
        </div>

        <div class="row justify-content-between mt-4">
          <div class="form-group col-lg-5">
            <div class="form-label">
              {{ $t("Global.Email_8") }} <span class="text-danger">*</span>
            </div>

            <input
              v-model="userEmail"
              name="userEmail"
              type="email"
              class="form-control"
              :class="{ 'is-invalid': errors.userEmail }"
              :placeholder="$t('Users.TypeEmailHere_5') + '...'"
            />

            <div class="invalid-feedback">{{ errors.userEmail }}</div>
          </div>
        </div>

        <div class="row justify-content-between mt-4">
          <div class="form-group col-lg-5">
            <div class="form-label">{{ $t("Users.Role_6") }}</div>

            <div class="form-check form-switch mb-2">
              <input
                v-model="isAdmin"
                id="isAdminCheckInput"
                class="form-check-input"
                type="checkbox"
                role="switch"
              />
              <label class="form-check-label" for="isAdminCheckInput">
                {{ $t("Users.IsAdmin_46") }}
              </label>
            </div>

            <LegacyMultiSelect
              v-model="userRoles"
              name="userRoles"
              mode="tags"
              :options="userRolesOptions"
              :placeholder="$t('Users.SelectRolesRoles_8')"
              :closeOnSelect="false"
            />
          </div>

          <div class="form-group col-lg-5">
            <div class="form-label">{{ $t("Users.ChooseCustomer_7") }}</div>

            <LegacyMultiSelect
              v-model="selectedCustomer"
              name="selectedCustomer"
              mode="single"
              :options="filterCustomersList"
              :filter-results="false"
              :resolve-on-load="true"
              :delay="200"
              :min-chars="0"
              :searchable="true"
              valueProp="id"
              label="name"
              :placeholder="$t('Users.ChooseCustomer_7')"
              :disabled="isCustomerListDisabled"
              :clearOnBlur="false"
              :class="{ 'is-invalid': errors.selectedCustomer }"
            />

            <div class="invalid-feedback">{{ errors.selectedCustomer }}</div>
          </div>
        </div>

        <div
          v-if="userCreatedSuccessfully != null"
          class="result-alert mt-auto"
        >
          <div
            v-if="userCreatedSuccessfully && !meta.dirty"
            class="col-lg-7 alert alert-success"
            role="alert"
          >
            {{ $t("Users.UserCreatedSuccessfully_9") }}
          </div>
          <div
            v-if="!userCreatedSuccessfully"
            class="col-lg-7 alert alert-danger"
            role="alert"
          >
            {{ errorMessage }}
          </div>
        </div>

        <div
          class="create-item-footer"
          :class="{
            'mt-auto': userCreatedSuccessfully === null,
          }"
        >
          <button
            type="submit"
            class="btn btn-primary add-user"
            :disabled="isSubmitting"
          >
            {{ $t("Users.AddUser_10") }}
          </button>
          <button
            type="button"
            class="btn btn-grey discard-user"
            @click="backToUsersList"
            :disabled="isSubmitting"
          >
            {{ $t("Users.Discard_11") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import Multiselect from "@vueform/multiselect";
import { UserRoles } from "@/_helpers/interfaces/users/current-user.interface";
import { useUserStore } from "@/stores/user.store";
import { useCustomerStore } from "@/stores/customer.store";
import { ref, watch, type Ref } from "vue";
import router from "@/router";

import { useField, useForm } from "vee-validate";
import { array, boolean, object, string } from "yup";

const userRolesOptions: any = { ...UserRoles };
delete userRolesOptions.ADMIN; // Admin assignation is a switcher

const validationSchema = object({
  firstName: string().required().label("First name"),
  lastName: string().required().label("Last name"),
  userEmail: string().required().email().label("User email"),
  isAdmin: boolean(),
  userRoles: array().of(string()),
  selectedCustomer: string()
    .nullable()
    .label("Selected customer")
    .when("userRoles", (userRoles, field) => {
      return userRoles.includes("ADMIN") || !userRoles.length
        ? field
        : field.required();
    }),
});

const { errors, handleSubmit, isSubmitting, meta, resetForm } = useForm({
  validationSchema,
});
const { value: firstName } = useField("firstName");
const { value: lastName } = useField("lastName");
const { value: userEmail } = useField("userEmail");
const { value: isAdmin } = useField("isAdmin");
const { value: userRoles } = useField("userRoles", undefined, {
  initialValue: [],
});
const { value: selectedCustomer } = useField("selectedCustomer", undefined, {
  initialValue: null,
});

const getAddUserPayLoad = (formValues: any) => {
  const payload: any = {
    first_name: formValues.firstName,
    last_name: formValues.lastName,
    email: formValues.userEmail,
    is_admin: formValues.isAdmin,
  };

  if (formValues.userRoles.length) {
    payload.roles = [...formValues.userRoles];
  }

  if (formValues.selectedCustomer) {
    payload.customer = formValues.selectedCustomer;
  }

  return payload;
};

const userCreatedSuccessfully: Ref<boolean | null> = ref(null);
const errorMessage = ref("");
const onSubmitUserForm = handleSubmit(async (values) => {
  try {
    const addUserPayload = getAddUserPayLoad(values);
    await addUser(addUserPayload);

    userCreatedSuccessfully.value = true;

    resetForm();
  } catch (error: any) {
    userCreatedSuccessfully.value = false;
    errorMessage.value = error.response.data.errors[0].detail;
  }
});

const isCustomerListDisabled = ref(true);
watch(userRoles, (roles) => {
  if (
    (roles as Array<string>).includes("ADMIN") ||
    !(roles as Array<string>).length
  ) {
    selectedCustomer.value = null;
    isCustomerListDisabled.value = true;
  } else {
    isCustomerListDisabled.value = false;
  }
});

const { addUser } = useUserStore();
const { getCustomersList } = useCustomerStore();

const filterCustomersList = async (query: string) => {
  const { results } = await getCustomersList({
    name: query,
    page_size: 200,
  });

  return results;
};

const backToUsersList = () => {
  router.push({ name: "list-users" });
};
</script>

<style scoped lang="scss"></style>
