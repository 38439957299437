<template>
  <form
    class="generic-form"
    @submit.prevent="onSubmit"
    encoding-type="multipart/form-data"
  >
    <div class="generic-popup" v-if="isCancelPopup">
      <div class="popup-wrapper">
        <div class="popup-header">
          <h4 class="popup-title">
            {{ $t("CustomerManagement.Confirmation_156") }}
          </h4>
          <span class="popup-close" @click="closePopup">
            <i class="icon-close"></i>
          </span>
        </div>
        <div class="popup-body">
          <div v-if="formData.is_order_and_payment_restrict">
            <h5>{{ $t("CustomerManagement.RestrictOn_Msg_149") }}</h5>
            <h5>{{ $t("CustomerManagement.RestrictOn_Msg_150") }}</h5>
          </div>

          <div v-if="!formData.is_order_and_payment_restrict">
            <h5>{{ $t("CustomerManagement.RestrictOff_Msg_151") }}</h5>
            <h5>{{ $t("CustomerManagement.RestrictOff_Msg_153") }}</h5>
            <h5>{{ $t("CustomerManagement.RestrictOff_Msg_154") }}</h5>
          </div>
          <div class="options">
            <button class="save" @click="onConfirmRestrictModeChange">
              {{ $t("CustomerManagement.Confirm_155") }}
            </button>
            <button class="cancel" @click="closePopup">
              {{ $t("Global.Cancel_17") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-between">
      <div class="col-lg-5 col-md-6" v-if="isMainDate">
        <div class="form-input">
          <label class="form-label">{{
            $t("CustomerManagement.CustomerName_10")
          }}</label>
          <input
            v-model.trim="$v.name.$model"
            :class="{ error: $v.name.$error }"
            type="text"
            :placeholder="$t('CustomerManagement.TypeName_15')"
          />
          <span v-if="$v.name.$error" class="form__error-msg">{{
            $t("CustomerManagement.NameRequired_17")
          }}</span>
        </div>
      </div>
      <div class="col-lg-5 col-md-6" v-if="isMainDate">
        <div class="form-input">
          <label class="form-label">{{
            $t("CustomerManagement.LegalName_11")
          }}</label>
          <input
            v-model.trim="$v.legal_name.$model"
            :class="{ error: $v.legal_name.$error }"
            type="text"
            :placeholder="$t('CustomerManagement.TypeName_15')"
          />
          <span v-if="$v.legal_name.$error" class="form__error-msg">{{
            $t("CustomerManagement.LegalNameRequired_18")
          }}</span>
        </div>
      </div>
      <div class="col-lg-5 col-md-6">
        <div class="form-input">
          <label class="form-label">{{
            $t("CustomerManagement.CustomerLimit_12")
          }}</label>
          <CurrencyInput
            v-model.trim="$v.customer_limit.$model"
            :errorClass="{
              error: $v.customer_limit.$error,
            }"
            :placeholder="$t('CustomerManagement.TypeLimit_16')"
          />
          <span v-if="$v.customer_limit.$error" class="form__error-msg">{{
            $t("CustomerManagement.CustomerLimitRequired_19")
          }}</span>
        </div>
      </div>
      <div class="col-lg-5 col-md-6">
        <div class="form-input">
          <label class="form-label">{{
            $t("CustomerManagement.AutomaticApprovalLimit_13")
          }}</label>
          <CurrencyInput
            v-model.trim="$v.auto_approval_limit.$model"
            :errorClass="{
              error: $v.auto_approval_limit.$error,
            }"
            :placeholder="$t('CustomerManagement.TypeLimit_16')"
          />
          <span v-if="$v.auto_approval_limit.$error" class="form__error-msg">{{
            $t("CustomerManagement.AutoApprovalLimitRequired_20")
          }}</span>
        </div>
      </div>
      <div class="col-lg-5 col-md-6">
        <div class="form-input">
          <label class="form-label">{{
            $t("CustomerManagement.FinancialLimitFrequency_14")
          }}</label>
          <select
            v-model="formData.financial_limit_frequency"
            class="form-select"
          >
            <option value="WEEKLY">
              {{ $t("CustomerManagement.Weekly_21") }}
            </option>
            <option value="MONTHLY">
              {{ $t("CustomerManagement.Monthly_22") }}
            </option>
            <option value="YEARLY">
              {{ $t("CustomerManagement.Yearly_23") }}
            </option>
            <option value="ALL_TIME">
              {{ $t("CustomerManagement.AllTime_24") }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-lg-5 col-md-6" v-if="isMainDate">
        <div class="form-input">
          <label class="form-label">{{
            $t("CustomerManagement.Address_104")
          }}</label>
          <input
            v-model.trim="formData.address"
            type="text"
            :placeholder="$t('CustomerManagement.TypeAddress_105')"
          />
        </div>
      </div>
      <div class="col-lg-5 col-md-6" v-if="isMainDate">
        <div class="form-input">
          <label class="form-label">{{
            $t("CustomerManagement.Email_106")
          }}</label>
          <input
            v-model.trim="formData.email"
            type="email"
            :placeholder="$t('CustomerManagement.TypeEmail_107')"
          />
        </div>
      </div>
      <div class="col-lg-5 col-md-6" v-if="isMainDate">
        <div class="form-group m-0">
          <div class="form-label">{{ $t("Suppliers.Phone_13") }}</div>
          <PhoneNumberInput
            v-model="formData.phone_number"
            name="phone"
            size="sm"
            show-code-on-list
            defaultCountryCode="DE"
            :preferredCountries="['DE', 'EG']"
          />
        </div>
      </div>
      <div class="col-lg-5 col-md-6" v-if="isMainDate">
        <div class="form-input">
          <label class="form-label">{{
            $t("CustomerManagement.CompanyLogo_110")
          }}</label>
          <div class="img-upload">
            <input
              type="file"
              id="img-btn"
              accept="image/*"
              @change="onImgChange($event)"
              hidden
            />
            <label for="img-btn">{{
              $t("CustomerManagement.ChooseLogo_111")
            }}</label>
            <span>
              {{ imgName }}
              <i
                class="icon-close"
                v-if="formData.avatar != ''"
                @click="removeImg"
              ></i
            ></span>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-md-6" v-if="isMainDate">
        <div class="form-group m-0">
          <div class="form-label">
            {{ $t("CustomerManagement.RestrictMode_148") }}
          </div>
          <MazSwitch
            v-model="formData.is_order_and_payment_restrict"
            name="restrict-mode"
            color="success"
            @update:model-value="openPopup"
          />
        </div>
      </div>
      <div v-if="isCreatedSuccessfully != null">
        <div
          v-if="isCreatedSuccessfully"
          class="col-md-5 alert alert-success"
          role="alert"
        >
          {{
            isEditMode
              ? $t("CustomerManagement.MainDataEditedSuccessfully_97")
              : $t("CustomerManagement.MainDataCreatedSuccessfully_79")
          }}
        </div>
        <div v-else class="col-md-5 alert alert-danger" role="alert">
          {{ errorMessage }}
        </div>
      </div>
      <div class="col-12">
        <div class="form-options">
          <router-link :to="{ name: 'customers' }" class="cancel-btn">{{
            $t("CustomerManagement.CancelDiscard_69")
          }}</router-link>
          <button v-if="props.isPrevious" class="submit-btn" type="submit">
            {{ $t("CustomerManagement.SaveContinue_70") }}
          </button>
          <button v-else class="submit-btn" type="submit">
            {{ $t("CustomerManagement.Save_89") }}
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
import CurrencyInput from "@/components/shared/currency-input/CurrencyInput.vue";

import { ref, reactive, watch, onMounted, type Ref } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useCustomerStore } from "@/stores/customer.store";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const { createMainData, getMainDataById, updateMainData } = useCustomerStore();

const props = defineProps<{
  isEditMode: boolean;
  isMainDate: boolean;
  customerIdEdit: number;
  isPrevious: boolean;
  isFormWizard: boolean;
}>();

const emit = defineEmits(["setCustomerID"]);

const formData: any = reactive({
  name: "",
  legal_name: "",
  customer_limit: null,
  auto_approval_limit: null,
  financial_limit_frequency: "ALL_TIME",
  address: "",
  email: "",
  phone_number: "",
  avatar: "",
  is_order_and_payment_restrict: false,
});

let imgName = ref(t("CustomerManagement.NoLogoChosen_112"));

const isCreatedSuccessfully: Ref<boolean | null> = ref(null);
const errorMessage = ref("");
const isCancelPopup = ref(false);

const closePopup = () => {
  isCancelPopup.value = false;
  formData.is_order_and_payment_restrict =
    !formData.is_order_and_payment_restrict;
};

const openPopup = () => {
  isCancelPopup.value = true;
};

const onConfirmRestrictModeChange = async () => {
  isCancelPopup.value = false;
};

const rules = {
  name: {
    required,
  },
  legal_name: {
    required,
  },
  customer_limit: {
    required,
  },
  auto_approval_limit: {
    required,
  },
};

const $v = useVuelidate(rules, formData);

const onSubmit = async () => {
  $v.value.$touch();
  if (!$v.value.$invalid) {
    try {
      let data: any;
      if (!props.isEditMode) {
        data = await createMainData(formData);
      } else {
        /* If logo is not update then avatar is send into payload */
        if (typeof formData.avatar === "string" || formData.avatar === null) {
          delete formData.avatar;
        }
        data = await updateMainData(formData, props.customerIdEdit);
      }
      emit("setCustomerID", data.id);
      isCreatedSuccessfully.value = true;
      if (props.isFormWizard) {
        let nextBtn: any = document.querySelector(".fw-footer-right button");
        nextBtn.click();
      }
    } catch (error: any) {
      isCreatedSuccessfully.value = false;
      errorMessage.value = error.response.data.errors[0].detail;
    }
  }
};

const onImgChange = (e: any) => {
  imgName.value = e.target.files[0].name;
  formData.avatar = e.target.files[0];
};

const removeImg = () => {
  imgName.value = t("CustomerManagement.NoLogoChosen_112");
  formData.avatar = "";
};

watch(
  () => props.customerIdEdit,
  () => {
    if (props.isEditMode) {
      getCustomerMainDataById();
      isCreatedSuccessfully.value = null;
    }
  }
);

const getCustomerMainDataById = async () => {
  try {
    let data = await getMainDataById(props.customerIdEdit);
    Object.keys(formData).forEach((formDataItem) => {
      Object.keys(data).forEach((responseItem) => {
        if (formDataItem == responseItem) {
          formData[formDataItem] = data[responseItem];
        }

        if (formData[formDataItem] == null) {
          formData[formDataItem] = null;
        }
      });
    });
    imgName.value = getImageNameFromURL(formData.avatar);
    imgName.value = imgName.value.substring(0, imgName.value.indexOf("?"));
  } catch (e) {
    console.log(e);
  }
};

onMounted(async () => {
  if (props.isEditMode) {
    await getCustomerMainDataById();
  }
});
const getImageNameFromURL = (url: any) => {
  let filename = url.split("/").filter((entry: any) => entry !== "");

  return filename[filename.length - 1];
};
const readImage = async (imageUrl: any) => {
  const response = await fetch(imageUrl);
  // here image is url/location of image
  const blob = await response.blob();
  const file = new File([blob], getImageNameFromURL(response.url), {
    type: blob.type,
  });

  formData.avatar = file;
  return file;
};
</script>
