<template>
  <div>
    <!--    filter popup -->
    <transition name="fade">
      <div class="generic-popup" v-if="showFilterPopup">
        <div class="popup-wrapper popup-wrapper-lg">
          <div class="popup-header">
            <h4 class="popup-title">
              {{ $t("Order.SearchAndFilter_155") }}
            </h4>
            <span class="popup-close" @click="closePopup">
              <i class="icon-close"></i>
            </span>
          </div>
          <div class="popup-body">
            <OrderFilter
              @apply="filterOrders"
              :hidden-fields="['service_date', 'hide_zero_balance']"
            />
          </div>
        </div>
      </div>
    </transition>

    <!--  Bulk export popup -->
    <transition name="fade">
      <div class="generic-popup" v-if="showBulkExportPopup">
        <div class="popup-wrapper popup-wrapper-lg">
          <div class="popup-header">
            <h4 class="popup-title">
              {{ $t("Order.Export_85") }}
            </h4>
            <span class="popup-close" @click="closePopup">
              <i class="icon-close"></i>
            </span>
          </div>
          <div class="popup-body">
            <div class="export-popup">
              <export-form
                :fields="exportFields"
                @export="exportAllOrders"
              ></export-form>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- // Bulk export popup   -->

    <div class="generic-popup" v-if="isPopup">
      <div class="popup-wrapper">
        <div class="popup-header">
          <h4 class="popup-title">{{ $t("Order.DeleteOrder_74") }}</h4>
          <span class="popup-close" @click="closePopup">
            <i class="icon-close"></i>
          </span>
        </div>
        <div class="popup-body">
          <h5>
            {{ $t("Order.DeletePopupTitle_81") }}
            {{ OrderNo }} ?
          </h5>
          <div class="options">
            <button class="save" @click="onChangeOrderDelete">
              {{ $t("Order.DeletePopup_83") }}
            </button>
            <button class="cancel" @click="closePopup">
              {{ $t("Order.CancelPopup_82") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="generic-popup" v-if="confirmBulkApprove">
      <div class="popup-wrapper">
        <div class="popup-header">
          <h4 class="popup-title">
            {{ $t("Payments.PaymentConfirmation_32") }}
          </h4>
          <span class="popup-close" @click="closePopup">
            <i class="icon-close"></i>
          </span>
        </div>
        <div class="popup-body">
          <h5>
            {{ $t("Order.OrdersComfirmApproved_132") }}
          </h5>
          <div class="options">
            <button class="save" @click="bulkAction('bulk_approve')">
              {{ $t("Payments.PaymentConfirm_33") }}
            </button>
            <button class="cancel" @click="closePopup">
              {{ $t("Order.CancelPopup_82") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="generic-popup" v-if="confirmBulkReject">
      <div class="popup-wrapper">
        <div class="popup-header">
          <h4 class="popup-title">
            {{ $t("Payments.PaymentConfirmation_32") }}
          </h4>
          <span class="popup-close" @click="closePopup">
            <i class="icon-close"></i>
          </span>
        </div>
        <div class="popup-body">
          <h5>
            {{ $t("Order.OrdersComfirmRejected_133") }}
          </h5>
          <div class="options">
            <button class="save" @click="bulkAction('bulk_reject')">
              {{ $t("Payments.PaymentConfirm_33") }}
            </button>
            <button class="cancel" @click="closePopup">
              {{ $t("Order.CancelPopup_82") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="generic-popup" v-if="confirmBulkClarification">
      <div class="popup-wrapper">
        <div class="popup-header">
          <h4 class="popup-title">
            {{ $t("Payments.PaymentConfirmation_32") }}
          </h4>
          <span class="popup-close" @click="closePopup">
            <i class="icon-close"></i>
          </span>
        </div>
        <div class="popup-body">
          <h5>
            {{ $t("Order.OrdersConfirmClarification_134") }}
          </h5>
          <div class="textarea-wrapper mb-4">
            <textarea
              v-model="clarificationComment"
              class="border w-100 px-2 py-3 rounded"
              :placeholder="$t('Order.TypeYourMessageHere_128')"
              rows="6"
            ></textarea>
          </div>
          <div class="options">
            <button
              class="save"
              style="max-width: 250px; width: 100%"
              @click="clarificationOrder(true)"
            >
              {{ $t("Global.SendClarification_19") }}
            </button>
            <button class="cancel" @click="closePopup">
              {{ $t("Order.CancelPopup_82") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="generic-popup" v-if="confirmSingleApprove">
      <div class="popup-wrapper">
        <div class="popup-header">
          <h4 class="popup-title">
            {{ $t("Payments.PaymentConfirmation_32") }}
          </h4>
          <span class="popup-close" @click="closePopup">
            <i class="icon-close"></i>
          </span>
        </div>
        <div class="popup-body">
          <h5>
            {{ $t("Order.OrderComfirmApproved_135") }}
          </h5>
          <div class="options">
            <button class="save" @click="approvedOrder()">
              {{ $t("Payments.PaymentConfirm_33") }}
            </button>
            <button class="cancel" @click="closePopup">
              {{ $t("Order.CancelPopup_82") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="generic-popup" v-if="confirmSingleReject">
      <div class="popup-wrapper">
        <div class="popup-header">
          <h4 class="popup-title">
            {{ $t("Payments.PaymentConfirmation_32") }}
          </h4>
          <span class="popup-close" @click="closePopup">
            <i class="icon-close"></i>
          </span>
        </div>
        <div class="popup-body">
          <h5>
            {{ $t("Order.OrderComfirmRejected_136") }}
          </h5>
          <div class="options">
            <button class="save" @click="rejectOrder()">
              {{ $t("Order.OrderComfirmRejected_136") }}
            </button>
            <button class="cancel" @click="closePopup">
              {{ $t("Order.CancelPopup_82") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="generic-popup" v-if="confirmSingleClarification">
      <div class="popup-wrapper">
        <div class="popup-header">
          <h4 class="popup-title">
            {{ $t("Payments.PaymentConfirmation_32") }}
          </h4>
          <span class="popup-close" @click="closePopup">
            <i class="icon-close"></i>
          </span>
        </div>
        <div class="popup-body">
          <h5>
            {{ $t("Order.OrderComfirmClarification_137") }}
          </h5>
          <div class="textarea-wrapper mb-4">
            <textarea
              v-model="clarificationComment"
              class="border w-100 px-2 py-3 rounded"
              :placeholder="$t('Order.TypeYourMessageHere_128')"
              rows="6"
            ></textarea>
          </div>
          <div class="options">
            <button
              class="save"
              style="max-width: 250px; width: 100%"
              @click="clarificationOrder()"
            >
              {{ $t("Global.SendClarification_19") }}
            </button>
            <button class="cancel" @click="closePopup">
              {{ $t("Order.CancelPopup_82") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="generic-popup" v-if="confirmSingleForward">
      <div class="popup-wrapper">
        <div class="popup-header">
          <h4 class="popup-title">
            {{ $t("Payments.PaymentConfirmation_32") }}
          </h4>
          <span class="popup-close" @click="closePopup">
            <i class="icon-close"></i>
          </span>
        </div>
        <div class="popup-body">
          <h5>
            {{ $t("Order.OrderComfirmForward_138") }}
          </h5>
          <div class="options">
            <button class="save" @click="submitOrder()">
              {{ $t("Order.OrderComfirmForward_138") }}
            </button>
            <button class="cancel" @click="closePopup">
              {{ $t("Order.CancelPopup_82") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="list-header">
      <div class="list-header__text">
        <h5>{{ $t("Order.Requests_84") }}</h5>
      </div>

      <div class="list-header__actions">
        <div class="d-flex gap-3 align-items-center">
          <div class="bulk-actions" v-if="showBulkSubmitDeleteBtn()">
            <button class="btn btn-primary" @click="onChangeBulkOrdersDelete()">
              {{ $t("Order.DeleteOrder_74") }}
            </button>
          </div>

          <div class="bulk-actions" v-if="showBulkSubmitDeleteBtn()">
            <button
              class="btn btn-secondary"
              @click="bulkAction('bulk_submit')"
            >
              <i class="icon-forward"></i>
              Submit
            </button>
          </div>

          <div class="bulk-actions" v-if="selectedOrders.length">
            <button
              class="btn btn-secondary"
              v-if="showBulkRequestClarificationBtn()"
              @click="confirmClarificationPopup()"
            >
              <i class="icon-document-clarification"></i>
              Request Clarifications
            </button>
          </div>

          <div class="bulk-actions" v-if="showBulkApproveBtn()">
            <button class="btn btn-secondary" @click="confirmApprovedPopup()">
              <i class="icon-document-approve"></i>
              Approve
            </button>
          </div>

          <div class="bulk-actions" v-if="showBulkRejectBtn()">
            <button class="btn btn-secondary" @click="confirmRejectedPopup()">
              <i class="icon-document-reject"></i>
              Reject
            </button>
          </div>
        </div>
        <div class="export-suppliers" v-if="showBulkExport">
          <button
            class="btn btn-secondary"
            type="button"
            @click="showBulkExportPopup = true"
          >
            <i class="icon-export-line"></i>
            <span>{{ $t("Order.Export_85") }}</span>
          </button>
        </div>

        <button
          type="button"
          class="btn btn-warning"
          v-if="filterCount()"
          @click="resetFilters()"
        >
          {{ $t("Order.ResetFilters_156") }}
        </button>
        <button
          type="button"
          class="btn btn-secondary position-relative"
          @click="showFilterPopup = true"
        >
          {{ $t("Order.Filters_154") }}
          <span
            class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
            v-if="filterCount()"
          >
            {{ filterCount() }}
          </span>
        </button>

        <a
          @click="changeEditMode()"
          class="btn btn-primary"
          v-if="
            currentUser?.user_role.includes('Admin') ||
            currentUser?.user_role.includes('Order Clearer') ||
            currentUser?.user_role.includes('Approver') ||
            currentUser?.user_role.includes('Purchaser')
          "
        >
          <i class="icon-add-new"></i>
          <span>{{ $t("Order.OrderNew_73") }}</span>
        </a>
      </div>
    </div>
    <InocuDataTable
      v-model:items-selected="selectedOrders"
      v-model:server-options="serverOptions"
      :server-items-length="ordersListTotalCount"
      :loading="loading"
      :headers="ordersTableHeaders"
      :items="orderList"
      :rows-items="[10, 25, 50, 100]"
      table-class-name="listing-custom-table"
      alternating
      buttons-pagination
      no-hover
      must-sort
      :body-row-class-name="bodyRowClassNameFunction"
    >
      <!-- Filtrable header cells -->
      <template #header-order_number_without_prefix="header">
        <div class="filter-column" @click.stop>
          <i
            class="icon-filter filter-icon"
            @click.stop="showNameFilter = !showNameFilter"
          ></i>
          {{ header.text }}
          <div class="filter-menu" v-if="showNameFilter">
            <input
              v-model="ordersListSearchTerm"
              type="text"
              class="form-control"
              @input="debounceSearchOrders"
            />
          </div>
        </div>
      </template>
      <template #item-order_number_without_prefix="order">
        <router-link
          v-if="order.status !== 'DRAFT'"
          :to="{ name: 'order', params: { id: order.id } }"
          class="dropdown-item"
        >
          <div :class="getIndicatorClass(order)"></div>
          <MazIcon
            name="recurring-icon"
            size="1.5rem"
            path="/icons"
            v-if="order?.recurring_instruction"
            style="margin-left: 8px"
          >
          </MazIcon>
          <span style="margin-left: 8px">
            {{ order.order_number }}
          </span>
        </router-link>
      </template>
      <template #item-amount="order">
        {{ order.amount ? $n(+order.amount, "currency") : "-" }}
      </template>
      <template #item-balance="order">
        {{ order.balance ? $n(+order.balance, "currency") : "-" }}
      </template>
      <template #item-project_no="order">
        {{ order.project_no ? order.project_no : "-" }}
      </template>
      <template #item-document_no_internal="order">
        {{ order.document_no_internal ? order.document_no_internal : "-" }}
      </template>
      <template #item-released_by_name="order">
        {{
          (order.status === "REJECTED" || order.status === "APPROVED") &&
          order.released_by_name
            ? order.released_by_name
            : "N/A"
        }}
      </template>
      <template #item-created_on="order">
        {{
          order.created_on === null || order.created_on === ""
            ? ""
            : moment(order.created_on).format("DD.MM.YYYY")
        }}
      </template>
      <template #item-status="order">
        <span
          class="order-badge reject-badge"
          v-if="order.status_display === 'REJECTED'"
        >
          {{ $t("Order.Rejected_65") }}
        </span>
        <span
          class="order-badge approved-badge"
          v-if="order.status_display === 'APPROVED'"
        >
          {{ $t("Order.Approved_62") }}
        </span>
        <span
          class="order-badge pending-badge"
          v-if="order.status_display === 'PENDING_APPROVAL'"
        >
          {{ $t("Order.Pending_90") }}
        </span>
        <span
          class="order-badge draft-badge"
          v-if="order.status_display === 'DRAFT'"
        >
          {{ $t("Order.Draft_66") }}
        </span>
        <span
          class="order-badge clearing-badge"
          v-if="order.status_display === 'CLEARING' && order.is_restrict"
        >
          {{ $t("Order.Clearing_141") }}
          {{
            `(${order.total_clearer_approved || 0}/${order.total_clearer || 0})`
          }}
        </span>
        <span
          class="order-badge clearing-badge"
          v-else-if="order.status_display === 'CLEARING'"
        >
          {{ $t("Order.Clearing_141") }}
        </span>
      </template>

      <template #item-is_clarification_requested="order">
        <div class="actions" v-if="order.is_clarification_requested">
          <VueCustomTooltip :label="$t('Order.Clarification_requested_143')">
            <MazIcon
              name="question-mark-circle"
              size="1.5rem"
              path="/icons"
              class="clarification-icon"
            ></MazIcon>
          </VueCustomTooltip>
        </div>
      </template>
      <template #item-actions="order">
        <div class="actions" v-if="!order?.recurring_instruction">
          <span
            @click="forwardSingleOrder(order.id)"
            title="Submit"
            v-if="
              order.status === 'DRAFT' &&
              (currentUser?.user_role.includes('Admin') ||
                currentUser?.user_role.includes('Purchaser'))
            "
          >
            <i class="icon-forward"></i>
          </span>
          <span
            @click="approvedSingleOrder(order.id)"
            title="Approve"
            v-if="showApproveOrRejectAction(order)"
          >
            <i class="icon-document-approve"></i>
          </span>
          <span
            @click="clarificationSingleOrder(order.id)"
            title="Clarification"
            v-if="showRequestClarificationAction(order)"
          >
            <i class="icon-document-clarification"></i>
          </span>
          <span
            @click="rejectedSingleOrder(order.id)"
            title="Reject"
            v-if="showApproveOrRejectAction(order)"
          >
            <i class="icon-document-reject"></i>
          </span>
          <span
            @click="editOrder(order.id)"
            v-if="showEditOrderAction(order)"
            title="Edit"
          >
            <i class="icon-edit"></i>
          </span>
          <span
            v-if="
              order.status === 'DRAFT' &&
              order.released_by_id === currentUser?.id
            "
            @click="openDeletePopup(order.id)"
            title="Delete"
          >
            <i class="icon-delete"></i>
          </span>
        </div>
      </template>
    </InocuDataTable>
    <div v-if="isError" class="alert alert-danger w-50 mt-3" role="alert">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup lang="ts">
import router from "@/router";
import { onMounted, ref, type Ref, watch, computed } from "vue";
import type {
  Header,
  ServerOptions,
  Item,
  BodyRowClassNameFunction,
} from "vue3-easy-data-table";
import { useToast } from "vue-toast-notification";
import { useI18n } from "vue-i18n";
import { useOrderStore } from "@/stores/order.store";
import debounce from "lodash/debounce";
import { useUserStore } from "@/stores/user.store";
import { storeToRefs } from "pinia";
import moment from "moment";
import MazIcon from "maz-ui/components/MazIcon";
import { VueCustomTooltip } from "@adamdehaven/vue-custom-tooltip";
import OrderFilter from "@/components/orders/OrderFilter.vue";
import ExportForm from "@/components/shared/export/export-form.vue";

const {
  getOrders,
  deleteOrder,
  ordersBulkAction,
  exportOrders,
  createOrderComments,
  OrdersOptions,
  resetOptions,
  filterCount,
} = useOrderStore();

const { currentUser } = storeToRefs(useUserStore());

const props = defineProps({
  status: {
    default: "",
    type: [String],
  },
  customer: {
    default: -1,
    type: [Number],
  },
  project: {
    default: -1,
    type: [Number],
  },
  date: {
    default: null,
    type: [Array],
  },
  isdashbaord: {
    default: false,
    type: [Boolean],
  },
});

const { t } = useI18n();
const $toast = useToast();

const showNameFilter = ref(false);
const ordersListSearchTerm: Ref<string> = ref("");

const orderList: any = ref([]);

const loading = ref(false);

const ordersListTotalCount = ref(0);

const selectedOrders: any = ref([]);

const serverOptions = ref<ServerOptions>({
  page: 1,
  rowsPerPage: 10,
  sortBy: "order_number_without_prefix",
  sortType: "desc",
});

const bodyRowClassNameFunction: BodyRowClassNameFunction = (
  item: Item
): string => {
  if (item.submitted === 1 && item.status === "APPROVED")
    return "traffic-light-green";
  if (item.submitted === 0 && item.status === "PENDING_APPROVAL")
    return "traffic-light-default";
  return "";
};

const isPopup = ref(false);

const showFilterPopup = ref(false);

const showBulkExportPopup = ref(false);

const isBulk = ref(false);

let OrderNo = ref(null);

const selectedOrdersIds = ref([]);

const isError = ref(false);

let errorMessage = ref("");

const confirmBulkApprove = ref(false);

let currentOrder = ref(null);

let confirmSingleApprove = ref(false);

const confirmBulkReject = ref(false);

const confirmBulkClarification = ref(false);

let confirmSingleReject = ref(false);

let confirmSingleClarification = ref(false);

let confirmSingleForward = ref(false);

const clarificationComment = ref("");

const ordersTableHeaders: Ref<Header[]> = ref([
  {
    text: t("Order.OrderNo_75"),
    value: "order_number_without_prefix",
    sortable: true,
  },
  {
    text: t("Order.Supplier_31"),
    value: "supplier_name",
  },
  {
    text: t("Order.DocumentNo_112"),
    value: "document_no_internal",
  },
  {
    text: t("Order.ProjectNo_146"),
    value: "project_no",
  },
  {
    text: t("Order.CurrentBalance_153"),
    value: "balance",
  },
  {
    text: t("Order.GrossEuro_38"),
    value: "amount",
  },
  {
    text: t("Order.CreatedOn_139"),
    value: "created_on",
  },
  {
    text: t("Order.CreatedBy_140"),
    value: "created_by_name",
  },
  {
    text: t("Order.ReleasedBy_78"),
    value: "released_by_name",
  },
  {
    text: t("Order.Status_80"),
    value: "status",
  },
  {
    text: "",
    value: "actions",
  },
]);

const exportFields = [
  { label: t("Order.OrderNo_75"), value: "order_number" },
  { label: t("Order.Supplier_31"), value: "supplier_name" },
  { label: t("Order.OrderSupplierNo_98"), value: "supplier_number" },
  { label: t("Order.DocumentNo_112"), value: "document_no_internal" },
  { label: t("Order.ProjectNo_146"), value: "project_no" },
  { label: t("Order.CurrentBalance_153"), value: "balance" },
  { label: t("Order.GrossEuro_38"), value: "amount" },
  { label: t("Order.CreatedOn_139"), value: "created_on" },
  { label: t("Order.CreatedBy_140"), value: "created_by" },
  { label: t("Order.ReleasedBy_78"), value: "released_by" },
];

const showBulkExport = computed(() => {
  let flag = true;
  selectedOrders.value.map((order: any) => {
    if (order.status !== "APPROVED") flag = false;
  });
  return flag && selectedOrders.value.length > 0;
});

const onChangeBulkOrdersDelete = async () => {
  isPopup.value = true;
  selectedOrdersIds.value = selectedOrders.value.map((order: any) => order.id);
  isBulk.value = true;
};

const submitOrder = async () => {
  let ids = [currentOrder.value];
  await ordersBulkAction("bulk_submit", ids)
    .then(async () => {
      closePopup();
      await getOrdersList(
        props.status,
        props.customer,
        props.project,
        props.date
      );
      $toast.open({
        message: `All selected Order have been submitted.`,
        type: "success",
      });
    })
    .catch((error: any) => {
      $toast.open({
        message: error.response.data.errors[0].detail,
        type: "error",
      });
      closePopup();
    });
};

const clarificationOrder = async (bulk = false) => {
  let ids = [currentOrder.value];

  if (bulk) {
    selectedOrdersIds.value = selectedOrders.value.map(
      (order: any) => order.id
    );
    ids = selectedOrdersIds.value;
  }

  let obj = {
    order: null,
    text: clarificationComment.value,
  };

  await ordersBulkAction("bulk_request_clarification", ids)
    .then(async () => {
      if (clarificationComment.value !== "") {
        ids.forEach(async (id) => {
          obj.order = id;
          await createOrderComments(obj).catch((error) => {
            $toast.open({
              message: error.response.data.errors[0].detail,
              type: "error",
            });
          });
        });
      }
      closePopup();
      await getOrdersList(
        props.status,
        props.customer,
        props.project,
        props.date
      );
      clarificationComment.value = "";
      $toast.open({
        message: `All selected Order have been clarified.`,
        type: "success",
      });
    })
    .catch((error: any) => {
      $toast.open({
        message: error.response.data.errors[0].detail,
        type: "error",
      });
      closePopup();
    });
};

const approvedOrder = async () => {
  let ids = [currentOrder.value];
  await ordersBulkAction("bulk_approve", ids)
    .then(async () => {
      closePopup();
      await getOrdersList(
        props.status,
        props.customer,
        props.project,
        props.date
      );
      $toast.open({
        message: `All selected Order have been approved.`,
        type: "success",
      });
    })
    .catch((error: any) => {
      $toast.open({
        message: error.response.data.errors[0].detail,
        type: "error",
      });
      closePopup();
    });
};

const forwardSingleOrder = (id: any) => {
  currentOrder.value = id;
  confirmSingleForward.value = true;
};

const approvedSingleOrder = (id: any) => {
  currentOrder.value = id;
  confirmSingleApprove.value = true;
};

const clarificationSingleOrder = (id: any) => {
  currentOrder.value = id;
  confirmSingleClarification.value = true;
};

const rejectedSingleOrder = (id: any) => {
  currentOrder.value = id;
  confirmSingleReject.value = true;
};

const bulkAction = async (actionName: any) => {
  selectedOrdersIds.value = selectedOrders.value.map((order: any) => order.id);
  let toastName =
    actionName === "bulk_approve"
      ? "Approve"
      : actionName === "bulk_reject"
      ? "Reject"
      : actionName === "bulk_request_clarification"
      ? "Request Clarification"
      : actionName === "bulk_delete"
      ? "Deleted"
      : actionName === "bulk_submit"
      ? "Submitted"
      : actionName;

  await ordersBulkAction(actionName, selectedOrdersIds.value)
    .then(async () => {
      closePopup();
      selectedOrders.value = [];
      await getOrdersList(
        props.status,
        props.customer,
        props.project,
        props.date
      );
      $toast.open({
        message: `All selected Order have been ${toastName}.`,
        type: "success",
      });
    })
    .catch((error: any) => {
      $toast.open({
        message: error.response.data.errors[0].detail,
        type: "error",
      });
    });
};

const rejectOrder = async () => {
  let ids = [currentOrder.value];
  await ordersBulkAction("bulk_reject", ids)
    .then(async () => {
      closePopup();
      await getOrdersList(
        props.status,
        props.customer,
        props.project,
        props.date
      );
      $toast.open({
        message: `All selected Order have been rejected.`,
        type: "success",
      });
    })
    .catch((error: any) => {
      $toast.open({
        message: error.response.data.errors[0].detail,
        type: "error",
      });
      closePopup();
    });
};

const changeEditMode = () => {
  localStorage.setItem("isEditOrder", "false");
  router.push({ name: "create-order" });
};

const filters = ref({
  ...OrdersOptions.value,
});

const filterOrders = async (filter: any) => {
  filters.value = filter;
  serverOptions.value.page = 1;
  closePopup();
  await getOrdersList(props.status, props.customer, props.project, props.date);
};

const debounceSearchOrders = debounce(async function (event) {
  ordersListSearchTerm.value = event.target.value;
  await getOrdersList(props.status, props.customer, props.project, props.date);
}, 300);

const getIndicatorClass = (order: any) => {
  return [
    "status-indicator",
    `status-indicator--${order.status_display?.toLowerCase()}`,
  ];
};

watch(
  serverOptions,
  async () => {
    await getOrdersList(
      props.status,
      props.customer,
      props.project,
      props.date
    );
  },
  {
    deep: true,
  }
);

const getOrdersList = async (
  status: string,
  customer: number,
  project: number,
  date: any
) => {
  if (props.isdashbaord && customer === -1) {
    return;
  }

  loading.value = true;

  const sortBy = serverOptions.value.sortBy;
  const sortDirection = serverOptions.value.sortType === "asc" ? "" : "-";
  const customerId = customer !== -1 ? customer : null;
  const projectId = project !== -1 ? project : null;
  const orderStatus = status !== "" ? status : null;

  let startDate =
    date === null || date.length === 0
      ? null
      : moment(date[0]).format("YYYY-MM-DD");
  let endDate =
    date === null || date.length === 0
      ? null
      : moment(date[1]).format("YYYY-MM-DD");

  try {
    let response = await getOrders({
      ...filters.value,
      page: serverOptions.value.page,
      page_size: serverOptions.value.rowsPerPage,
      ordering: sortDirection + sortBy,
      created_after: filters.value.created_after || startDate,
      created_before: filters.value.created_before || endDate,
      customer: customerId,
      project_id: projectId,
      status: orderStatus || filters.value.status,
    });
    // clear existing data
    orderList.value.splice(0, orderList.value.length);
    orderList.value = response.results;
    ordersListTotalCount.value = response.count;
    loading.value = false;

    return orderList.value;
  } catch (error: any) {
    console.log(error);

    isError.value = true;
    loading.value = false;
    errorMessage.value = error.response.data.errors[0].detail;
    setTimeout(() => {
      isError.value = false;
    }, 3000);
  }
};

const confirmApprovedPopup = () => {
  confirmBulkApprove.value = true;
};

const confirmRejectedPopup = () => {
  confirmBulkReject.value = true;
};

const confirmClarificationPopup = () => {
  confirmBulkClarification.value = true;
};

const openDeletePopup = (orderNo: any) => {
  isPopup.value = true;
  OrderNo.value = orderNo;
};

const editOrder = (id: any) => {
  localStorage.setItem("isEditOrder", "true");
  localStorage.setItem("orderId", id);
  router.push({ name: "create-order", query: { order: id } });
};

const closePopup = () => {
  isPopup.value = false;
  confirmBulkApprove.value = false;
  confirmBulkReject.value = false;
  confirmBulkClarification.value = false;
  confirmSingleApprove.value = false;
  confirmSingleReject.value = false;
  confirmSingleClarification.value = false;
  confirmSingleForward.value = false;
  showFilterPopup.value = false;
  showBulkExportPopup.value = false;
};

const onChangeOrderDelete = async () => {
  closePopup();

  if (isBulk.value) {
    await bulkAction("bulk_delete");
    isBulk.value = false;
    selectedOrders.value = [];
  } else {
    await deleteOrder(OrderNo.value);
    $toast.open({
      message: `Order (${OrderNo.value}) deleted successfully.`,
      type: "success",
    });
  }

  await getOrdersList(props.status, props.customer, props.project, props.date);
};

const exportAllOrders = async (data: any) => {
  closePopup();
  let fileFormat = data.exportFormat;
  let fields = data.fields;
  selectedOrdersIds.value = selectedOrders.value.map((order: any) => order.id);
  if (selectedOrdersIds.value.length === 0) {
    $toast.open({
      message: `Please select at least one order.`,
      type: "error",
    });
    return;
  }
  try {
    const data = await exportOrders(
      selectedOrdersIds.value,
      fileFormat,
      fields
    );
    $toast.open({
      message: `All Orders are exported successfully.`,
      type: "success",
    });
    // open file url in new tab
    window.open(data.file_url, "_blank");
  } catch (error: any) {
    $toast.open({
      message: error.response.data.errors[0].detail,
      type: "error",
    });
  }
};

const showEditOrderAction = (order: any) => {
  return (
    (order.status === "DRAFT" || order.is_clarification_requested) &&
    order.created_by === currentUser.value?.id
  );
};

const showBulkSubmitDeleteBtn = () => {
  // make sure all selected orders are drafts
  return (
    selectedOrders.value.length &&
    selectedOrders.value.filter((item: any) => {
      return item.status === "DRAFT";
    }).length == selectedOrders.value.length
  );
};

const showBulkRequestClarificationBtn = () => {
  return (
    selectedOrders.value.length &&
    selectedOrders.value.filter((item: any) => {
      return (
        ["PENDING_APPROVAL", "CLEARING"].includes(item.status_display) &&
        !item.is_clarification_requested &&
        (currentUser.value?.user_role.includes("Order Clearer") ||
          currentUser.value?.user_role.includes("Approver") ||
          currentUser.value?.user_role.includes("Admin"))
      );
    }).length == selectedOrders.value.length
  );
};

const showBulkApproveBtn = () => {
  if (currentUser.value?.user_role.includes("Admin")) {
    return (
      selectedOrders.value.length &&
      selectedOrders.value.filter((item: any) => {
        return item.status_display === "CLEARING";
      }).length === selectedOrders.value.length
    );
  }
  if (currentUser.value?.user_role.includes("Approver")) {
    return (
      selectedOrders.value.length &&
      selectedOrders.value.filter((item: any) => {
        // this only happens when all clearers have cleared the order, or at least
        // one has cleared and the order is not in strict mode
        return item.status_display === "PENDING_APPROVAL";
      }).length == selectedOrders.value.length
    );
  }
  if (currentUser.value?.user_role.includes("Order Clearer")) {
    return (
      selectedOrders.value.length &&
      selectedOrders.value.filter((item: any) => {
        return item.status_display === "PENDING_APPROVAL";
      }).length == selectedOrders.value.length
    );
  }
  return false;
};

const showBulkRejectBtn = () => {
  return (
    (currentUser.value?.user_role.includes("Admin") ||
      currentUser.value?.user_role.includes("Approver") ||
      currentUser.value?.user_role.includes("Order Clearer")) &&
    selectedOrders.value.length &&
    selectedOrders.value.filter((item: any) => {
      return (
        item.status_display == "CLEARING" ||
        item.status_display == "PENDING_APPROVAL"
      );
    }).length === selectedOrders.value.length
  );
};

const showApproveOrRejectAction = (order) => {
  if (currentUser.value?.user_role.includes("Admin")) {
    return ["PENDING_APPROVAL", "CLEARING", "CLARIFICATION_REQUESTED"].includes(
      order?.status_display
    );
  }
  if (currentUser.value?.user_role.includes("Approver")) {
    return order?.status_display === "PENDING_APPROVAL";
  }
  if (currentUser.value?.user_role.includes("Order Clearer")) {
    return ["PENDING_APPROVAL", "CLARIFICATION_REQUESTED"].includes(
      order?.status_display
    );
  }
  return false;
};

const showRequestClarificationAction = (order) => {
  if (
    currentUser.value?.user_role.includes("Admin") ||
    currentUser.value?.user_role.includes("Approver") ||
    currentUser.value?.user_role.includes("Order Clearer")
  ) {
    return (
      ["PENDING_APPROVAL", "CLEARING"].includes(order?.status_display) &&
      !order?.is_clarification_requested
    );
  }
  return false;
};

const updateHeaders = (status: string) => {
  if (status === "CLEARING" || status === "PENDING_APPROVAL" || status == "") {
    //   make sure is_clarification_requested header exists before the last header
    if (
      !ordersTableHeaders.value.find(
        (header) => header.value === "is_clarification_requested"
      )
    ) {
      ordersTableHeaders.value.splice(ordersTableHeaders.value.length - 1, 0, {
        text: "",
        value: "is_clarification_requested",
      });
    }
  } else {
    //   remove is_clarification_requested header
    ordersTableHeaders.value = ordersTableHeaders.value.filter(
      (header) => header.value !== "is_clarification_requested"
    );
  }
};

const resetFilters = () => {
  filterOrders(resetOptions());
};

onMounted(async () => {
  await getOrdersList(props.status, props.customer, props.project, props.date);
  updateHeaders(props.status);
});

watch(props, async (newVal) => {
  serverOptions.value.page = 1;
  selectedOrders.value = [];
  updateHeaders(newVal.status);
  await getOrdersList(
    newVal.status,
    newVal.customer,
    newVal.project,
    newVal.date
  );
});
</script>

<style>
.clarification-icon {
  display: inline-block;
  color: #f8bd53;
  margin-left: 5px;
}
.flex-container {
  display: flex;
  justify-content: start;
}

.export-option {
  margin-left: 10px;
  font-size: 16px;
}
</style>
