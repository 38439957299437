<template>
  <div class="create-page">
    <h4 class="page-header">{{ $t("Suppliers.EditMasterPage_32") }}</h4>

    <div class="create-page-content">
      <div class="item-data-header">{{ $t("Suppliers.BankAccount_43") }}</div>

      <form
        @submit="onSubmitBankAccountForm"
        class="item-data-form container-fluid d-flex flex-column"
      >
        <!-- Owner and Bank Name -->
        <div class="row justify-content-between">
          <div class="form-group col-lg-5">
            <div class="form-label">
              {{ $t("Suppliers.AccountOwner_44") }}
              <span class="text-danger">*</span>
            </div>

            <input
              v-model="ownerName"
              name="ownerName"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.ownerName }"
              :placeholder="$t('Suppliers.TypeNameHere_4') + '...'"
            />

            <div class="invalid-feedback">{{ errors.ownerName }}</div>
          </div>

          <div class="form-group col-lg-5">
            <div class="form-label">
              {{ $t("Suppliers.BankName_45") }}
              <span class="text-danger">*</span>
            </div>

            <input
              v-model="bankName"
              name="bankName"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.bankName }"
              :placeholder="$t('Suppliers.TypeNameHere_4') + '...'"
            />

            <div class="invalid-feedback">{{ errors.bankName }}</div>
          </div>
        </div>

        <!-- SWIFT Code and IBAN -->
        <div class="row justify-content-between mt-4">
          <div class="form-group col-lg-5">
            <div class="form-label">
              {{ $t("Suppliers.SwiftCode_46") }}
              <span class="text-danger">*</span>
            </div>

            <input
              v-model="swiftCode"
              name="swiftCode"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.swiftCode }"
              :placeholder="$t('Suppliers.TypeSwiftCodeHere_47') + '...'"
            />

            <div class="invalid-feedback">{{ errors.swiftCode }}</div>
          </div>

          <div class="form-group col-lg-5">
            <div class="form-label">
              {{ $t("Suppliers.IBAN_48") }}
              <span class="text-danger">*</span>
            </div>

            <input
              v-model="iban"
              name="iban"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.iban }"
              :placeholder="$t('Suppliers.TypeIbanHere_49') + '...'"
            />

            <div class="invalid-feedback">{{ errors.iban }}</div>
          </div>
        </div>

        <!-- customer or supplier -->
        <div class="row justify-content-between mt-4">
          <div v-if="!hideCustomerSelection" class="form-group col-lg-5">
            <div class="form-label">
              {{ $t("Suppliers.Customer_53") }}
              <span class="text-danger">*</span>
            </div>

            <LegacyMultiSelect
              :key="customerId"
              v-model="customerId"
              name="customerId"
              mode="single"
              :loading="customerListLoading"
              :options="customerList"
              @search-change="filterCustomersList"
              @clear="filterCustomersList"
              :resolve-on-load="true"
              :min-chars="0"
              :searchable="true"
              valueProp="id"
              label="name"
              :placeholder="$t('Suppliers.ChooseCustomer_55')"
              :clearOnBlur="false"
              :clearable="false"
              :class="{ 'is-invalid': errors.customerId }"
            />
            <div class="invalid-feedback">
              {{ errors.customerId }}
            </div>
          </div>
          <div
            class="form-group col-lg-5"
            v-if="associatedPersona === 'supplier'"
          >
            <div class="form-label">
              {{ $t("Suppliers.Supplier_54") }}
              <span class="text-danger">*</span>
            </div>
            <LegacyMultiSelect
              ref="supplierSelectRef"
              :disabled="!customerId"
              v-model="supplierId"
              name="supplierId"
              mode="single"
              :loading="supplierListLoading"
              @search-change="filterSuppliersList"
              @clear="filterSuppliersList"
              :options="supplierList"
              :resolve-on-load="true"
              :min-chars="0"
              :searchable="true"
              valueProp="id"
              label="display_name"
              :placeholder="$t('Suppliers.ChooseSupplier_56')"
              :clearOnBlur="false"
              :clearable="false"
              :class="{ 'is-invalid': errors.supplierId }"
            />
            <div class="invalid-feedback">
              {{ errors.supplierId }}
            </div>
          </div>
        </div>

        <div
          v-if="bankAccountUpdatedSuccessfully != null"
          class="result-alert mt-auto"
        >
          <div
            v-if="bankAccountUpdatedSuccessfully"
            class="col-lg-7 alert alert-success"
            role="alert"
          >
            {{ $t("Suppliers.BankAccountUpdatedSuccessfully_66") }}
          </div>
          <div
            v-if="!bankAccountUpdatedSuccessfully"
            class="col-lg-7 alert alert-danger"
            role="alert"
          >
            {{ errorMessage }}
          </div>
        </div>

        <div
          class="create-item-footer"
          :class="{
            'mt-auto': bankAccountUpdatedSuccessfully === null,
          }"
        >
          <button type="submit" class="btn btn-purple" :disabled="isSubmitting">
            {{ $t("Suppliers.EditBankAccount_67") }}
          </button>
          <button
            type="button"
            class="btn btn-grey"
            @click="backToBankAccountsList"
            :disabled="isSubmitting"
          >
            {{ $t("Suppliers.Discard_19") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, type Ref, watch } from "vue";

import { useBankAccountForm } from "@/_helpers/composables/master-data/bank-account-changing.composable";
import { useCustomerStore } from "@/stores/customer.store";
import { useSupplierStore } from "@/stores/supplier.store";
import { useBankAccountStore } from "@/stores/bank-account.store";
import type { BankAccount } from "@/_helpers/interfaces/bank-accounts/bank-account.interface";
import router from "@/router";
import { debounce } from "lodash";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user.store";
import { useI18n } from "vue-i18n";
import { useToast } from "vue-toast-notification";

const $toast = useToast();
const { t } = useI18n();

const props = defineProps({
  bankAccountId: String, // Route param
});

const customerList: Ref<Array<any>> = ref([]);
const supplierList: Ref<Array<any>> = ref([]);
const customerListLoading: Ref<Boolean> = ref(false);
const supplierListLoading: Ref<Boolean> = ref(false);

const {
  errors,
  handleSubmit,
  isSubmitting,
  setValues,
  setFieldValue,
  resetField,
  ownerName,
  bankName,
  swiftCode,
  iban,
  associatedPersona,
  customerId,
  supplierId,
  backToBankAccountsList,
} = useBankAccountForm();

const { currentUser } = storeToRefs(useUserStore());

const { editBankAccount, getBankAccountById } = useBankAccountStore();

const currentBankAccount: Ref<BankAccount | null> = ref(null);

const bankAccountUpdatedSuccessfully: Ref<boolean | null> = ref(null);
const errorMessage = ref("");

const onSubmitBankAccountForm = handleSubmit(async (values) => {
  try {
    await editBankAccount(+props.bankAccountId!, {
      owner_name: values.ownerName,
      bank_name: values.bankName,
      swift_code: values.swiftCode,
      iban: values.iban,
      customer: values.customerId,
      supplier:
        associatedPersona.value === "supplier" ? values.supplierId : null,
    });

    $toast.open({
      type: "success",
      message: t("Suppliers.BankAccountUpdatedSuccessfully_84"),
    });

    backToBankAccountsList();
  } catch (error: any) {
    bankAccountUpdatedSuccessfully.value = false;
    errorMessage.value = error.response.data.errors[0].detail;
  }
});
const hideCustomerSelection = ref(false);

onMounted(async () => {
  const { value: user } = currentUser;
  const userIsAdmin = user?.user_role.includes("Admin");
  hideCustomerSelection.value = !userIsAdmin;
  validateRouteParam();

  currentBankAccount.value = await getBankAccountById(+props.bankAccountId!);

  const formValues = {
    ownerName: currentBankAccount.value.owner_name,
    bankName: currentBankAccount.value.bank_name,
    swiftCode: currentBankAccount.value.swift_code,
    iban: currentBankAccount.value.iban,
  };

  setFieldValue("customerId", currentBankAccount.value.customer);
  await loadCustomerList(<string>currentBankAccount.value?.customer_name);

  if (currentBankAccount.value.supplier) {
    formValues["associatedPersona"] = "supplier";
    formValues["supplierId"] = currentBankAccount.value.supplier;
  } else {
    formValues["associatedPersona"] = "customer";
  }
  formValues["customerId"] = currentBankAccount.value.customer;
  setValues(formValues);
});

const validateRouteParam = () => {
  if (isNaN(+props.bankAccountId!)) {
    // TODO: navigate to not found page
    router.push({ name: "list-bank-accounts" });
  }
};

const { getCustomersList } = useCustomerStore();
const { getSuppliersList } = useSupplierStore();

const loadSupplierList = async (query: string) => {
  if (typeof query == "object") query = "";
  supplierListLoading.value = true;
  const { results } = await getSuppliersList({
    search: query,
    page_size: 200,
    customer: customerId.value,
  });
  supplierList.value = [...results];
  supplierList.value.map((supplier: any) => {
    supplier.display_name = supplier.name + " - " + supplier.supplier_number;
  });
  supplierListLoading.value = false;

  return supplierList.value;
};

const loadCustomerList = async (query: string) => {
  customerListLoading.value = true;
  const { results } = await getCustomersList({
    name: query,
    page_size: 200,
  });
  customerList.value = [...results];
  customerListLoading.value = false;
  console.log(customerList.value);
  console.log(customerId.value);
  return customerList.value;
};

const filterSuppliersList = debounce(loadSupplierList, 500);
const filterCustomersList = debounce(loadCustomerList, 500);

// watch customerId and load suppliers list
const supplierSelectRef: Ref<any> = ref(null);
watch(customerId, async (value, oldValue) => {
  if (value) {
    await filterSuppliersList("");
  }
  if (supplierId.value && oldValue) {
    supplierSelectRef.value?.clear();
    resetField("supplierId");
  }
});
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables/_variables.scss";

.associated-persona-label {
  color: $base-dark-gray;
}
</style>
