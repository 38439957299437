<template>
  <div class="generic-list-page">
    <div class="row">
      <div
        :class="
          !(
            currentUser?.user_role.includes('Purchaser') ||
            currentUser?.user_role.includes('Payer') ||
            currentUser?.user_role.includes('Order Clearer') ||
            currentUser?.user_role.includes('Payment Clearer') ||
            currentUser?.user_role.includes('Approver')
          )
            ? 'col-lg-3 col-md-6 col-12'
            : 'col-lg-6 col-md-6 col-12'
        "
      >
        <h1 class="page-title">{{ $t("Order.Payments_88") }}</h1>
      </div>
      <div
        class="col-lg-3 col-md-6 col-12"
        v-if="
          !(
            currentUser?.user_role.includes('Purchaser') ||
            currentUser?.user_role.includes('Payer') ||
            currentUser?.user_role.includes('Order Clearer') ||
            currentUser?.user_role.includes('Payment Clearer') ||
            currentUser?.user_role.includes('Approver')
          )
        "
      >
        <div class="form-input mb-lg-0">
          <LegacyMultiSelect
            v-model="paymentFilter.customer"
            name="customers"
            :object="false"
            :options="customersList"
            :resolve-on-load="true"
            :min-chars="0"
            :placeholder="$t('Order.SelectCustomer_2')"
            :closeOnSelect="true"
            :clearOnBlur="false"
            :searchable="true"
            valueProp="id"
            label="name"
          />
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <div class="form-input mb-lg-0">
          <LegacyMultiSelect
            v-model="paymentFilter.project"
            name="projects"
            :object="false"
            :options="projectsList"
            :resolve-on-load="true"
            :min-chars="0"
            :placeholder="$t('Order.SelectProject_5')"
            :closeOnSelect="true"
            :clearOnBlur="false"
            :searchable="true"
            valueProp="id"
            label="name"
          />
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-12 ms-auto text-right">
        <VueDatePicker
          v-model="paymentFilter.date"
          :placeholder="$t('Order.SelectDate_49')"
          :enable-time-picker="false"
          range
          :select-text="$t('Order.Apply_50')"
          auto-apply
          :max-date="new Date()"
          :preset-ranges="presetRanges"
          :format="formatDate"
        >
          <template #yearly="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}</span
            >
          </template>
          <template #last_year="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}</span
            >
          </template>
          <template #last_month="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}</span
            >
          </template>
          <template #this_month="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}</span
            >
          </template>
          <template #last_week="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}</span
            >
          </template>
          <template #last_30_days="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}</span
            >
          </template>
        </VueDatePicker>
      </div>
    </div>
    <div class="list-table">
      <!-- Tabs -->
      <ul class="orders-tabs">
        <li :class="!$route.query.status ? 'active' : ''">
          <router-link :to="{ name: 'payments' }" class="dropdown-item">
            {{ $t("Order.AllTypes_89") }}
          </router-link>
        </li>
        <li
          v-if="showPendingTab()"
          :class="$route.query.status === 'PENDING_APPROVAL' ? 'active' : ''"
        >
          <router-link
            :to="{ name: 'payments', query: { status: 'PENDING_APPROVAL' } }"
            class="dropdown-item"
          >
            {{ $t("Order.Pending_90") }}
            <div
              class="status-indicator status-indicator--pending_approval"
            ></div>
          </router-link>
        </li>
        <!-- Hide this tab for clearers -->
        <li
          v-if="!currentUser?.user_role?.includes('Payment Clearer')"
          :class="$route.query.status === 'CLEARING' ? 'active' : ''"
        >
          <router-link
            :to="{ name: 'payments', query: { status: 'CLEARING' } }"
            class="dropdown-item"
          >
            {{ $t("Order.Clearing_141") }}
            <div class="status-indicator status-indicator--clearing"></div>
          </router-link>
        </li>
        <li :class="$route.query.status === 'APPROVED' ? 'active' : ''">
          <router-link
            :to="{ name: 'payments', query: { status: 'APPROVED' } }"
            class="dropdown-item"
          >
            {{ $t("Order.Approved_91") }}
            <div class="status-indicator status-indicator--approved"></div>
          </router-link>
        </li>
        <li :class="$route.query.status === 'REJECTED' ? 'active' : ''">
          <router-link
            :to="{ name: 'payments', query: { status: 'REJECTED' } }"
            class="dropdown-item"
          >
            {{ $t("Order.Rejected_92") }}
            <div class="status-indicator status-indicator--rejected"></div>
          </router-link>
        </li>
        <li :class="$route.query.status === 'DRAFT' ? 'active' : ''">
          <router-link
            :to="{ name: 'payments', query: { status: 'DRAFT' } }"
            class="dropdown-item"
          >
            {{ $t("Order.Drafts_93") }}
            <div class="status-indicator status-indicator--draft"></div>
          </router-link>
        </li>
      </ul>
      <div>
        <PaymentTable
          :status="
            typeof $route.query.status === 'string' ? $route.query.status : ''
          "
          :customer="paymentFilter.customer ? paymentFilter.customer : -1"
          :project="paymentFilter.project ? paymentFilter.project : -1"
          :date="paymentFilter.date"
        />
      </div>
      <!-- Tabs -->
    </div>
  </div>
</template>

<script setup lang="ts">
import Multiselect from "@vueform/multiselect";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { ref, reactive, onMounted, watch, onBeforeMount } from "vue";
import { useProjectStore } from "@/stores/project.store";
import { useCustomerStore } from "@/stores/customer.store";
import PaymentTable from "@/components/shared/tables/payments-table/PaymentsTable.vue";
import { formatDate, presetRanges } from "@/_helpers/utils/date";
import { useUserStore } from "@/stores/user.store";
import { storeToRefs } from "pinia";

const { getCustomersOptions } = useCustomerStore();
const { getProjectsByCustomer } = useProjectStore();
const { currentUser } = storeToRefs(useUserStore());

let customersList: any = ref([]);

let projectsList: any = ref([]);

let paymentFilter: any = reactive({
  customer: null,
  project: null,
  date: null,
});

/* Get Project By Customer id*/
const getProjectsTableList = async (customerId: any) => {
  const response = await getProjectsByCustomer({
    customer: customerId,
    is_active: true,
  });

  projectsList.value = [...response.results];
};

watch(
  () => paymentFilter.customer,
  async (newVal) => {
    paymentFilter.project = null;
    await getProjectsTableList(newVal);
  }
);

const showPendingTab = () => {
  if (currentUser.value?.user_role.includes("Admin")) {
    return false;
  }
  if (
    currentUser.value?.user_role.includes("Payment Clearer") ||
    currentUser.value?.user_role.includes("Approver")
  ) {
    return true;
  }
  return !currentUser.value?.user_role.includes("Payer");
};

onBeforeMount(() => {
  let getStoreCustomerId = localStorage.getItem("selectedCustomerId");
  getStoreCustomerId ? (paymentFilter.customer = +getStoreCustomerId) : null;
});

onMounted(async () => {
  customersList.value = await getCustomersOptions();
});
</script>
