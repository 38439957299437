<template>
  <div>
    <div class="form-group row">
      <div class="col-sm-10 col-md-6 col-lg-6 my-1">
        <input
          type="text"
          class="form-control"
          id="order_number"
          :placeholder="$t('Payments.PaymentNo_2')"
          v-model="currentFilter.payment_number"
        />
      </div>

      <div class="col-sm-10 col-md-6 col-lg-6 my-1">
        <input
          type="text"
          class="form-control"
          id="supplier"
          :placeholder="$t('Order.Supplier_31')"
          v-model="currentFilter.supplier"
        />
      </div>

      <div class="col-sm-10 col-md-6 col-lg-6 my-1">
        <input
          type="text"
          class="form-control"
          id="invoice_number"
          :placeholder="$t('Payments.InvoiceNumber_46')"
          v-model="currentFilter.invoice_number"
        />
      </div>

      <div class="col-sm-10 col-md-6 col-lg-6 my-1">
        <input
          type="text"
          class="form-control"
          id="internal_order_number"
          :placeholder="$t('Payments.InternalOrderNo_43')"
          v-model="currentFilter.internal_order_number"
        />
      </div>

      <div class="col-sm-10 col-md-6 col-lg-6 my-1">
        <input
          type="text"
          class="form-control"
          id="released_by"
          :placeholder="$t('Order.ReleasedBy_78')"
          v-model="currentFilter.released_by"
        />
      </div>
      <div class="col-sm-10 col-md-6 col-lg-6 my-1">
        <input
          type="text"
          class="form-control"
          id="created_by"
          :placeholder="$t('Order.CreatedBy_140')"
          v-model="currentFilter.created_by"
        />
      </div>

      <div class="col-sm-10 col-md-6 col-lg-6 my-1">
        <VueDatePicker
          :placeholder="$t('Payments.PaymentDueDate_3')"
          :enable-time-picker="false"
          range
          :select-text="$t('Order.Apply_50')"
          auto-apply
          :preset-ranges="presetRanges"
          :format="formatDate"
          v-model="dueDateDateRange"
        >
          <template #yearly="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #last_year="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #last_month="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #this_month="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #last_week="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #last_30_days="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
        </VueDatePicker>
      </div>

      <div class="col-sm-10 col-md-6 col-lg-6 my-1">
        <VueDatePicker
          :placeholder="$t('Order.CreatedOn_139')"
          :enable-time-picker="false"
          range
          :select-text="$t('Order.Apply_50')"
          auto-apply
          :preset-ranges="presetRanges"
          :format="formatDate"
          v-model="createdOnRange"
        >
          <template #yearly="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #last_year="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #last_month="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #this_month="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #last_week="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
          <template #last_30_days="{ label, range, presetDateRange }">
            <span
              style="width: 120px; display: block"
              @click="presetDateRange(range)"
              >{{ label }}
            </span>
          </template>
        </VueDatePicker>
      </div>

      <div class="col-sm-10 col-md-6 col-lg-6 my-1">
        <div class="input-group mb-3">
          <input
            type="number"
            v-model.number="currentFilter.amount_min"
            class="form-control"
            :placeholder="$t('Payments.AmountMin_47')"
          />
          <span class="input-group-text">:</span>
          <input
            type="number"
            v-model.number="currentFilter.amount_max"
            class="form-control"
            :placeholder="$t('Payments.AmountMax_48')"
          />
        </div>
      </div>
      <div class="col-sm-10 col-md-6 col-lg-6 my-1">
        <LegacyMultiSelect
          v-model="currentFilter.payed"
          name="projects"
          :object="false"
          :options="payedOptions"
          :resolve-on-load="true"
          :min-chars="0"
          :placeholder="$t('Payments.PaymentPayed_6')"
          :closeOnSelect="true"
          :clearOnBlur="false"
          :searchable="true"
          valueProp="value"
          label="label"
          :multiple="false"
        />
      </div>

      <div class="col-sm-10 col-md-6 col-lg-6 my-1">
        <MazCheckbox
          v-model="currentFilter.clarification_requested_only"
          size="mini"
          color="success"
        >
          {{ $t("Order.ShowClarificationRequestedOnly_144") }}
        </MazCheckbox>
      </div>
    </div>
    <div style="float: right">
      <button class="btn btn-primary d-inline mx-2 my-2" @click="applyFilter()">
        {{ $t("Global.Apply_16") }}
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import { formatDate, presetRanges } from "@/_helpers/utils/date";
import { storeToRefs } from "pinia";
import { usePaymentStore } from "@/stores/payment.store";
import Multiselect from "@vueform/multiselect";

import moment from "moment/moment";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const emit = defineEmits(["apply"]);

const { PaymentsOptions } = storeToRefs(usePaymentStore());
const { setOptions } = usePaymentStore();
const currentFilter = ref({
  ordering: "",
  page: "",
  page_size: "",
  project: "",
  customer: "",
  created_after: "",
  created_before: "",
  status: "",
  payed: "",
  clarification_requested_only: false,
  supplier: "",
  invoice_number: "",
  internal_order_number: "",
  created_by: "",
  released_by: "",
  due_date_after: "",
  due_date_before: "",
  amount_max: "",
  amount_min: "",
  payment_number: "",
});

const payedOptions: Array<any> = [
  { label: t("Payments.Yes_49"), value: true },
  { label: t("Payments.No_50"), value: false },
];

const applyFilter = () => {
  setOptions(currentFilter.value);
  emit("apply", currentFilter.value);
};

const dueDateDateRange: any = ref(null);
const createdOnRange: any = ref(null);

onMounted(() => {
  currentFilter.value = { ...PaymentsOptions.value };
  dueDateDateRange.value = [
    currentFilter.value.due_date_after
      ? moment(currentFilter.value.due_date_after)
      : "",
    currentFilter.value.due_date_before
      ? moment(currentFilter.value.due_date_before)
      : "",
  ];
  createdOnRange.value = [
    currentFilter.value.created_after
      ? moment(currentFilter.value.created_after)
      : "",
    currentFilter.value.created_before
      ? moment(currentFilter.value.created_before)
      : "",
  ];
});

watch(dueDateDateRange, (newVal) => {
  if (newVal) {
    currentFilter.value.due_date_after = newVal[0]
      ? moment(newVal[0]).format("YYYY-MM-DD")
      : "";
    currentFilter.value.due_date_before = newVal[1]
      ? moment(newVal[1]).format("YYYY-MM-DD")
      : "";
  } else {
    currentFilter.value.due_date_after = "";
    currentFilter.value.due_date_before = "";
  }
});

watch(createdOnRange, (newVal) => {
  if (newVal) {
    currentFilter.value.created_after = newVal[0]
      ? moment(newVal[0]).format("YYYY-MM-DD")
      : "";
    currentFilter.value.created_before = newVal[1]
      ? moment(newVal[1]).format("YYYY-MM-DD")
      : "";
  } else {
    currentFilter.value.created_after = "";
    currentFilter.value.created_before = "";
  }
});
</script>
